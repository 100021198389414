export default (function (_ref) {
  var request = _ref.request;
  return {
    websiteArticleAdd: function websiteArticleAdd(data) {
      return request({
        url: '/website/website/article',
        method: 'post',
        data: data
      });
    },
    websiteArticleModify: function websiteArticleModify(data) {
      return request({
        url: "/website/website/article/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    websiteArticleDel: function websiteArticleDel(id) {
      return request({
        url: "/website/website/article/".concat(id),
        method: 'delete'
      });
    },
    websiteArticleQuery: function websiteArticleQuery(userId, data) {
      return request({
        url: "/website/website/article/".concat(userId, "/query"),
        method: 'get',
        params: data
      });
    },
    websiteArticleDetail: function websiteArticleDetail(id) {
      return request({
        url: "/website/website/article/".concat(id),
        method: 'get'
      });
    }
  };
});