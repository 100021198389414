export default (function (_ref) {
  var request = _ref.request;
  return {
    mngMenuAdd: function mngMenuAdd(data) {
      return request({
        url: '/mng/menu',
        method: 'post',
        data: data
      });
    },
    mngMenuModify: function mngMenuModify(data) {
      return request({
        url: "/mng/menu/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    mngMenuDel: function mngMenuDel(id) {
      return request({
        url: "/mng/menu/".concat(id),
        method: 'delete'
      });
    },
    mngMenuQuery: function mngMenuQuery(data) {
      return request({
        url: '/mng/menu/query',
        method: 'get',
        params: data
      });
    },
    mngMenuTree: function mngMenuTree() {
      return request({
        url: '/mng/menu/cache/lowerTree',
        method: 'get'
      });
    },
    mngMenuDetail: function mngMenuDetail(id) {
      return request({
        url: "/mng/menu/".concat(id),
        method: 'get'
      });
    },
    mngMenuClearCache: function mngMenuClearCache() {
      return request({
        url: '/menu/cache/clear',
        method: 'post'
      });
    }
  };
});