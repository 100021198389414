export default (function (_ref) {
  var request = _ref.request;
  return {
    eduLiyouCoureseClassAdd: function eduLiyouCoureseClassAdd(data) {
      return request({
        url: '/education_liyou/courseClass',
        method: 'post',
        data: data
      });
    },
    eduLiyouCoureseClassDel: function eduLiyouCoureseClassDel(id) {
      return request({
        url: "/education_liyou/courseClass/".concat(id),
        method: 'delete'
      });
    },
    eduLiyouCoureseClassModify: function eduLiyouCoureseClassModify(data) {
      return request({
        url: "/education_liyou/courseClass/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    eduLiyouCoureseClassQuery: function eduLiyouCoureseClassQuery(data) {
      return request({
        url: '/education_liyou/courseClass/query',
        method: 'get',
        params: data
      });
    },
    eduLiyouCoureseClassDetail: function eduLiyouCoureseClassDetail(id) {
      return request({
        url: "/education_liyou/courseClass/".concat(id),
        method: 'get'
      });
    }
  };
});