export default (function (_ref) {
  var request = _ref.request;
  return {
    mngAppVersionAdd: function mngAppVersionAdd(data) {
      return request({
        url: '/mng/appVersion',
        method: 'post',
        data: data
      });
    },
    mngAppVersionModify: function mngAppVersionModify(data) {
      return request({
        url: "/mng/appVersion/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    mngAppVersionQueryJoinUser: function mngAppVersionQueryJoinUser(data) {
      return request({
        url: '/mng/appVersion/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    mngAppVersionDetail: function mngAppVersionDetail(id) {
      return request({
        url: "/mng/appVersion/".concat(id),
        method: 'get'
      });
    },
    mngAppVersionSendProduction: function mngAppVersionSendProduction(id) {
      return request({
        url: "/mng/appVersion/".concat(id, "/sendProduction"),
        method: 'get'
      });
    }
  };
});