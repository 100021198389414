import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键字"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "关键字"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.getRecordDetail(scope.row.id);
            }
          }
        }, [_vm._v("详情")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "saleStoreName",
      label: "零售商名称",
      sortable: "custom",
      "show-overflow-tooltip": "",
      "min-width": "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.saleStoreName || ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "saleStoreTobaccoSaleIdentity",
      label: "销售烟草证号",
      sortable: "custom",
      "show-overflow-tooltip": "",
      "min-width": "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.saleStoreTobaccoSaleIdentity || ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "brandAmount",
      label: "品牌数量",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.brandAmount || 0))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "boxAmount",
      label: "烟盒数量",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.boxAmount || 0))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "recordTime",
      label: "记录时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.recordTime ? _vm.dateFormat2Str(scope.row.recordTime) : ""))])];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.detailDialogShow,
      title: "记录详情",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.detailDialogShow = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", {
    attrs: {
      "body-style": {
        padding: "0px"
      }
    }
  }, [_c("el-image", {
    staticStyle: {
      height: "248px"
    },
    attrs: {
      src: _vm.recordDetail.recordImage,
      "preview-src-list": [_vm.recordDetail.recordImage]
    }
  }), _c("div", {
    staticStyle: {
      padding: "14px"
    }
  }, [_c("div", {
    staticClass: "bottom clearfix"
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v("零售商户：")]), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.saleStoreDetail.name || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v("许可证号：")]), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.saleStoreDetail.tobaccoSaleIdentity || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v("记录时间：")]), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.recordDetail.recordTime ? _vm.dateFormat2Str(_vm.recordDetail.recordTime) : ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v("香烟品牌：")]), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.recordDetail.brandAmount || 0))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v("香烟数量：")]), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.recordDetail.boxAmount || 0))])], 1)], 1)])], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("h3", {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v("识别列表")]), _vm._l(_vm.recordDetailDetails, function (item) {
    return _c("el-row", {
      key: item.id,
      staticStyle: {
        padding: "12px",
        background: "#F1F1F1",
        "border-radius": "4px"
      }
    }, [_c("el-col", {
      attrs: {
        span: 21
      }
    }, [_vm._v(_vm._s(_vm.dicData.configBrandOptions[item.firstBrandCode] || "") + " · " + _vm._s(_vm.dicData.configBrandSecondOptions[item.secondBrandCode] || ""))]), _c("el-col", {
      attrs: {
        span: 3
      }
    }, [_vm._v(_vm._s(item.boxAmount || 0))])], 1);
  })], 2)], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };