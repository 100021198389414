import { render, staticRenderFns } from "./carousel_page.vue?vue&type=template&id=785edcf0&scoped=true"
import script from "./carousel_page.vue?vue&type=script&lang=js"
export * from "./carousel_page.vue?vue&type=script&lang=js"
import style0 from "./carousel_page.vue?vue&type=style&index=0&id=785edcf0&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785edcf0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/riverlei/project/java/bgyy-project/front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('785edcf0')) {
      api.createRecord('785edcf0', component.options)
    } else {
      api.reload('785edcf0', component.options)
    }
    module.hot.accept("./carousel_page.vue?vue&type=template&id=785edcf0&scoped=true", function () {
      api.rerender('785edcf0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/website/carousel_page.vue"
export default component.exports