import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    groupBuyActivityAdd: function groupBuyActivityAdd(data) {
      return request({
        url: '/group_buy/groupActivity',
        method: 'post',
        data: data
      });
    },
    groupBuyActivityModify: function groupBuyActivityModify(data) {
      return request({
        url: "/group_buy/groupActivity/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    groupBuyActivityDel: function groupBuyActivityDel(id) {
      return request({
        url: "/group_buy/groupActivity/".concat(id),
        method: 'delete'
      });
    },
    groupBuyActivityQuery: function groupBuyActivityQuery(data) {
      return request({
        url: '/group_buy/groupActivity/query',
        method: 'get',
        params: data
      });
    },
    groupBuyActivityQueryJoinCompanySta: function groupBuyActivityQueryJoinCompanySta(data) {
      return request({
        url: '/group_buy/groupActivity/queryJoinCompanySta',
        method: 'get',
        params: data
      });
    },
    groupBuyActivityDetail: function groupBuyActivityDetail(id) {
      return request({
        url: "/group_buy/groupActivity/".concat(id),
        method: 'get'
      });
    },
    groupBuyActivityOnline: function groupBuyActivityOnline(id) {
      return request({
        url: "/group_buy/groupActivity/".concat(id, "/online"),
        method: 'post'
      });
    },
    groupBuyActivityUnOnline: function groupBuyActivityUnOnline(id) {
      return request({
        url: "/group_buy/groupActivity/".concat(id, "/unOnline"),
        method: 'post'
      });
    },
    groupBuyActivityIndexPush: function groupBuyActivityIndexPush(id, sort) {
      return request({
        url: "/group_buy/groupActivity/".concat(id, "/indexPush/").concat(sort),
        method: 'post'
      });
    },
    groupBuyActivityUnIndexPush: function groupBuyActivityUnIndexPush(id) {
      return request({
        url: "/group_buy/groupActivity/".concat(id, "/unIndexPush"),
        method: 'post'
      });
    },
    groupBuyActivityCacheClear: function groupBuyActivityCacheClear(id) {
      return request({
        url: "/group_buy/groupActivity/cacheClear/".concat(id),
        method: 'delete'
      });
    }
  };
});