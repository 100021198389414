export default (function (_ref) {
  var request = _ref.request;
  return {
    cmsFeedbackAdd: function cmsFeedbackAdd(data) {
      return request({
        url: '/cms/feedback',
        method: 'post',
        data: data
      });
    },
    cmsFeedbackDel: function cmsFeedbackDel(id) {
      return request({
        url: "/cms/feedback/".concat(id),
        method: 'delete'
      });
    },
    cmsFeedbackQueryJoinUser: function cmsFeedbackQueryJoinUser(data) {
      return request({
        url: '/cms/feedback/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    cmsFeedbackDetail: function cmsFeedbackDetail(id) {
      return request({
        url: "/cms/feedback/".concat(id),
        method: 'get'
      });
    },
    cmsFeedbackRead: function cmsFeedbackRead(id) {
      return request({
        url: "/cms/feedback/read/".concat(id),
        method: 'post'
      });
    }
  };
});