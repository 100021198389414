import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeDriverAuditQuery: function logisticHongdeDriverAuditQuery(data) {
      return request({
        url: '/logistics_hongde/driverAudit/query',
        method: 'get',
        params: data
      });
    },
    logisticHongdeDriverAuditAdd: function logisticHongdeDriverAuditAdd(data) {
      return request({
        url: '/logistics_hongde/driverAudit',
        method: 'post',
        data: data
      });
    },
    logisticHongdeDriverAuditEdit: function logisticHongdeDriverAuditEdit(data) {
      return request({
        url: '/logistics_hongde/driverAudit/edit',
        method: 'post',
        data: data
      });
    },
    logisticHongdeDriverAuditSuccess: function logisticHongdeDriverAuditSuccess(_ref2) {
      var id = _ref2.id,
        carTeamId = _ref2.carTeamId,
        authMsg = _ref2.authMsg;
      return request({
        url: "/logistics_hongde/driverAudit/".concat(id, "/success/").concat(carTeamId, "?authMsg=").concat(authMsg),
        method: 'get'
      });
    },
    logisticHongdeDriverAuditFail: function logisticHongdeDriverAuditFail(_ref3) {
      var id = _ref3.id,
        authMsg = _ref3.authMsg;
      return request({
        url: "/logistics_hongde/driverAudit/".concat(id, "/fail?authMsg=").concat(authMsg),
        method: 'get'
      });
    }
  };
});