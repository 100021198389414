import { render, staticRenderFns } from "./ship_company_mng_page.vue?vue&type=template&id=189bfb90"
import script from "./ship_company_mng_page.vue?vue&type=script&lang=js"
export * from "./ship_company_mng_page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/riverlei/project/java/bgyy-project/front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('189bfb90')) {
      api.createRecord('189bfb90', component.options)
    } else {
      api.reload('189bfb90', component.options)
    }
    module.hot.accept("./ship_company_mng_page.vue?vue&type=template&id=189bfb90", function () {
      api.rerender('189bfb90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/logistics_hongde/ship_company/ship_company_mng_page.vue"
export default component.exports