var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("d2-page-cover", [_c("img", {
    staticClass: "logo",
    attrs: {
      src: _vm.sys_index_logo,
      name: "d2-admin",
      alt: ""
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };