import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import Vue from 'vue';
Vue.directive('PositiveInt', {
  bind: function bind(el) {
    var input = el.getElementsByTagName('input')[0];
    input.onkeyup = function (e) {
      if (input.value.length === 1) {
        input.value = input.value.replace(/[^0-9]/g, '');
      } else {
        input.value = input.value.replace(/[^\d]/g, '');
      }
      trigger(input, 'input');
    };
    input.onblur = function (e) {
      if (input.value.length === 1) {
        input.value = input.value.replace(/[^0-9]/g, '');
      } else {
        input.value = input.value.replace(/[^\d]/g, '');
      }
      trigger(input, 'input');
    };
  }
});
var trigger = function trigger(el, type) {
  var e = document.createEvent('HTMLEvents');
  e.initEvent(type, true, true);
  el.dispatchEvent(e);
};