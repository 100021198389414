export default (function (_ref) {
  var request = _ref.request;
  return {
    aboutCacheValues: function aboutCacheValues() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/mng/about/cache/values',
        method: 'get',
        params: data
      });
    }
  };
});