import "core-js/modules/es.array.concat.js";
import layoutHeaderAside from '@/layout/header-aside';

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

/**
 * 在主框架内显示
 */
var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [
  // /////// //
  // //首页// //
  // /////// //
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('system/index')
  }
  // /////// //
  // 系统管理 //
  // ////// //
  , {
    path: 'mng_dictionary',
    name: 'mng_dictionary',
    meta: {
      title: '字典管理',
      auth: true
    },
    component: _import('mng/dictionary_page')
  }, {
    path: 'mng_config',
    name: 'mng_config',
    meta: {
      title: '配置管理',
      auth: true
    },
    component: _import('mng/config_page')
  }, {
    path: 'mng_switch',
    name: 'mng_switch',
    meta: {
      title: '开关管理',
      auth: true
    },
    component: _import('mng/switch_page')
  }, {
    path: 'mng_about',
    name: 'mng_about',
    meta: {
      title: '关于系统',
      auth: true
    },
    component: _import('mng/about_page')
  }, {
    path: 'mng_menu',
    name: 'mng_menu',
    meta: {
      title: '菜单管理',
      auth: true
    },
    component: _import('mng/menu_page')
  }, {
    path: 'mng_role',
    name: 'mng_role',
    meta: {
      title: '角色管理',
      auth: true
    },
    component: _import('mng/role_page')
  }, {
    path: 'mng_admin',
    name: 'mng_admin',
    meta: {
      title: '管理员管理',
      auth: true
    },
    component: _import('mng/user/admin_page')
  }, {
    path: 'mng_user_mng',
    name: 'mng_user_mng',
    meta: {
      title: '用户管理',
      auth: true
    },
    component: _import('mng/user/user_mng_page')
  }, {
    path: 'mng_app_version',
    name: 'mng_app_version',
    meta: {
      title: 'APP版本',
      auth: true
    },
    component: _import('mng/app_version_page')
  }
  // /////// //
  // 内容管理 //
  // ////// //
  , {
    path: 'cms_feedback',
    name: 'cms_feedback',
    meta: {
      title: '反馈管理',
      auth: true
    },
    component: _import('cms/feedback_page')
  }, {
    path: 'cms_article_simple',
    name: 'cms_article_simple',
    meta: {
      title: '文章管理',
      auth: true
    },
    component: _import('cms/article_simple_page')
  }, {
    path: 'cms_advertising',
    name: 'cms_advertising',
    meta: {
      title: '广告管理',
      auth: true
    },
    component: _import('cms/advertising_page')
  }, {
    path: 'cms_carousel',
    name: 'cms_carousel',
    meta: {
      title: '轮播管理',
      auth: true
    },
    component: _import('cms/carousel_page')
  }, {
    path: 'cms_work_order',
    name: 'cms_work_order',
    meta: {
      title: '工单管理',
      auth: true
    },
    component: _import('cms/work_order_page')
  }
  // /////// //
  // 支付管理 //
  // ////// //
  , {
    path: 'pay_charge_project',
    name: 'pay_charge_project',
    meta: {
      title: '充值产品',
      auth: true
    },
    component: _import('pay/charge_project_page')
  }, {
    path: 'pay_charge_order',
    name: 'pay_charge_order',
    meta: {
      title: '充值订单',
      auth: true
    },
    component: _import('pay/charge_order_page')
  }, {
    path: 'pay_pay_order',
    name: 'pay_pay_order',
    meta: {
      title: '支付订单',
      auth: true
    },
    component: _import('pay/pay_order_page')
  }, {
    path: 'pay_refund_order',
    name: 'pay_refund_order',
    meta: {
      title: '退款订单',
      auth: true
    },
    component: _import('pay/refund_order_page')
  }, {
    path: 'pay_withdrawal_order',
    name: 'pay_withdrawal_order',
    meta: {
      title: '提现管理',
      auth: true
    },
    component: _import('pay/withdrawal_order_page')
  }, {
    path: 'pay_balance_record',
    name: 'pay_balance_record',
    meta: {
      title: '用户流水',
      auth: true
    },
    component: _import('pay/balance_record_page')
  }
  // /////// //
  // 拼团管理 //
  // ////// //
  , {
    path: 'groupbuy_vip_product',
    name: 'groupbuy_vip_product',
    meta: {
      title: 'VIP产品 (团)',
      auth: true
    },
    component: _import('group_buy/vip_product_page')
  }, {
    path: 'groupbuy_vip_order',
    name: 'groupbuy_vip_order',
    meta: {
      title: 'VIP订单 (团)',
      auth: true
    },
    component: _import('group_buy/vip_order_page')
  }, {
    path: 'groupbuy_company_mng',
    name: 'groupbuy_company_mng',
    meta: {
      title: '活动方 (团)',
      auth: true
    },
    component: _import('group_buy/company/groupbuy_company_mng_page')
  }, {
    path: 'groupbuy_activity',
    name: 'groupbuy_activity',
    meta: {
      title: '活动管理 (团)',
      auth: true
    },
    component: _import('group_buy/activity_page')
  }, {
    path: 'groupbuy_activity_order',
    name: 'groupbuy_activity_order',
    meta: {
      title: '报名管理 (团)',
      auth: true
    },
    component: _import('group_buy/activity_order_page')
  }, {
    path: 'groupbuy_activity_share',
    name: 'groupbuy_activity_share',
    meta: {
      title: '成交线索 (团)',
      auth: true
    },
    component: _import('group_buy/activity_share_page')
  }, {
    path: 'groupbuy_activity_browse',
    name: 'groupbuy_activity_browse',
    meta: {
      title: '传播信息 (团)',
      auth: true
    },
    component: _import('group_buy/activity_browse_page')
  }
  // /////// //
  // 官网管理 //
  // ////// //
  , {
    path: 'website_navigation_article',
    name: 'website_navigation_article',
    meta: {
      title: '导航文章',
      auth: true
    },
    component: _import('website/navigation_article/navigation_article_mng_page')
  }, {
    path: 'website_carousel',
    name: 'website_carousel',
    meta: {
      title: '官网轮播',
      auth: true
    },
    component: _import('website/carousel_page')
  }, {
    path: 'website_information',
    name: 'website_information',
    meta: {
      title: '官网信息',
      auth: true
    },
    component: _import('website/information_page')
  }, {
    path: 'website_outlink',
    name: 'website_outlink',
    meta: {
      title: '官网外链',
      auth: true
    },
    component: _import('website/outlink_page')
  }
  // //////// //
  // 鸿德集装箱 //
  // //////// //
  , {
    path: 'logistics_hongde_car_team_mng',
    name: 'logistics_hongde_car_team_mng',
    meta: {
      title: '车队管理',
      auth: true
    },
    component: _import('logistics_hongde/car_team/car_team_mng_page')
  }, {
    path: 'logistics_logs',
    name: 'logistics_logs',
    meta: {
      title: '操作日志',
      auth: true,
      cache: true
    },
    component: _import('logistics_hongde/logs/log_page')
  }, {
    path: 'logistics_hongde_ship_company',
    name: 'logistics_hongde_ship_company',
    meta: {
      title: '船公司管理',
      auth: true
    },
    component: _import('logistics_hongde/ship_company/ship_company_mng_page')
  }, {
    path: 'logistics_hongde_merchant_mng',
    name: 'logistics_hongde_merchant_mng',
    meta: {
      title: '派单方管理',
      auth: true
    },
    component: _import('logistics_hongde/merchant/merchant_mng_page')
  }, {
    path: 'logistics_hongde_driver_mng',
    name: 'logistics_hongde_driver_mng',
    meta: {
      title: '司机管理',
      auth: true
    },
    component: _import('logistics_hongde/driver/driver_mng_page')
  }, {
    path: 'logistics_waybill_mng',
    name: 'logistics_waybill_mng',
    meta: {
      title: '订单管理',
      auth: true,
      cache: true
    },
    component: _import('logistics_hongde/waybill/waybill_mng_page')
  }, {
    path: 'logistics_waybill_order',
    name: 'logistics_waybill_order',
    meta: {
      title: '运单管理',
      auth: true,
      cache: true
    },
    component: _import('logistics_hongde/waybill_order_page')
  }, {
    path: 'logistics_waybill_order_accountant',
    name: 'logistics_waybill_order_accountant',
    meta: {
      title: '财务管理',
      auth: true
    },
    component: _import('logistics_hongde/waybill_order_accountant_page')
  }, {
    path: 'logistics_sizeModel',
    name: 'logistics_sizeModel',
    meta: {
      title: '箱皮重配置',
      auth: true
    },
    component: _import('logistics_hongde/sizeModel_page')
  }, {
    path: 'logistics_driver_review',
    name: 'logistics_driver_review',
    meta: {
      title: '司机审核',
      auth: true
    },
    component: _import('logistics_hongde/driver/driver_review_page')
  }

  // //////// //
  //  即墨烟草  //
  // //////// //
  , {
    path: 'tobacco_jimo_sale_store',
    name: 'tobacco_jimo_sale_store',
    meta: {
      title: '零售商管理',
      auth: true
    },
    component: _import('tobacco_jimo/tobacco_jimo_sale_store_page')
  }, {
    path: 'tobacco_jimo_inspection_record',
    name: 'tobacco_jimo_inspection_record',
    meta: {
      title: '巡检记录',
      auth: true
    },
    component: _import('tobacco_jimo/tobacco_jimo_inspection_record_page')
  }
  // //////// //
  //  李优数学  //
  // //////// //
  , {
    path: 'edu_liyou_course',
    name: 'edu_liyou_course',
    meta: {
      title: '课程管理',
      auth: true
    },
    component: _import('edu_liyou/course/edu_liyou_course_mng_page')
  }, {
    path: 'edu_liyou_coupon',
    name: 'edu_liyou_coupon',
    meta: {
      title: '优惠券管理',
      auth: true
    },
    component: _import('edu_liyou/coupon/edu_liyou_coupon_mng_page')
  }, {
    path: 'edu_liyou_order',
    name: 'edu_liyou_order',
    meta: {
      title: '课程订单',
      auth: true
    },
    component: _import('edu_liyou/edu_liyou_order_page')
  }, {
    path: 'edu_liyou_user',
    name: 'edu_liyou_user',
    meta: {
      title: '用户管理',
      auth: true
    },
    component: _import('edu_liyou/edu_liyou_user_page')
  }, {
    path: 'edu_liyou_rakeback',
    name: 'edu_liyou_rakeback',
    meta: {
      title: '返佣管理',
      auth: true
    },
    component: _import('edu_liyou/edu_liyou_rakeback_page')
  }, {
    path: 'edu_liyou_author',
    name: 'edu_liyou_author',
    meta: {
      title: '讲师管理',
      auth: true
    },
    component: _import('edu_liyou/edu_liyou_author_page')
  }
  // /////// //
  // 其他功能 //
  // ////// //
  , {
    // 系统 前端日志
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  }, {
    // 刷新页面 必须保留
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, {
    // 页面重定向 必须保留
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}];

/**
 * 在主框架之外显示
 */
var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}, {
  path: '/login_code',
  name: 'login_code',
  component: _import('system/login/login_code')
}];

/**
 * 错误页面
 */
var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);