export default (function (_ref) {
  var request = _ref.request;
  return {
    cmsArticleSimpleAdd: function cmsArticleSimpleAdd(data) {
      return request({
        url: '/cms/articleSimple',
        method: 'post',
        data: data
      });
    },
    cmsArticleSimpleModify: function cmsArticleSimpleModify(data) {
      return request({
        url: "/cms/articleSimple/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    cmsArticleSimpleDel: function cmsArticleSimpleDel(id) {
      return request({
        url: "/cms/articleSimple/".concat(id),
        method: 'delete'
      });
    },
    cmsArticleSimpleQuery: function cmsArticleSimpleQuery(data) {
      return request({
        url: '/cms/articleSimple/query',
        method: 'get',
        params: data
      });
    },
    cmsArticleSimpleDetail: function cmsArticleSimpleDetail(id) {
      return request({
        url: "/cms/articleSimple/".concat(id),
        method: 'get'
      });
    },
    cmsArticleSimpleDetailByCode: function cmsArticleSimpleDetailByCode(code) {
      return request({
        url: "/cms/articleSimple/detailByCode/".concat(code),
        method: 'get'
      });
    },
    cmsArticleSimpleOnline: function cmsArticleSimpleOnline(id) {
      return request({
        url: "/cms/articleSimple/".concat(id, "/online/"),
        method: 'post'
      });
    },
    cmsArticleSimpleUnOnline: function cmsArticleSimpleUnOnline(id) {
      return request({
        url: "/cms/articleSimple/".concat(id, "/unOnline/"),
        method: 'post'
      });
    }
  };
});