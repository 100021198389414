import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  }), _vm._v(" 添加")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "600px",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.edit(scope.row.id);
            }
          }
        }, [_vm._v("编辑")]), _c("el-button", {
          attrs: {
            size: "mini",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.del(scope.row.id);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "size",
      label: "尺寸",
      "show-overflow-tooltip": "",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm.dicData.configSizeOptions[scope.row.size]) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "model",
      label: "箱型",
      "show-overflow-tooltip": "",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm.dicData.configShapeOptions[scope.row.model]) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "weight",
      label: "箱皮重（公斤）",
      width: "150",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.editDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initFormData(_vm.emptyFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDataVerify",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "箱型",
      "label-width": _vm.formLabelWidth,
      prop: "model"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "372px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.model,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "model", $$v);
      },
      expression: "formData.model"
    }
  }, _vm._l(_vm.dicData.configShapes, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "尺寸",
      "label-width": _vm.formLabelWidth,
      prop: "size"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "372px"
    },
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.formData.size,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "size", $$v);
      },
      expression: "formData.size"
    }
  }, _vm._l(_vm.dicData.configSizes, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "重量（公斤）",
      "label-width": _vm.formLabelWidth,
      prop: "weight"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "372px"
    },
    attrs: {
      autocomplete: "off",
      placeholder: "重量（公斤）"
    },
    model: {
      value: _vm.formData.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "weight", $$v);
      },
      expression: "formData.weight"
    }
  })], 1)], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确 定")])], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };