import { render, staticRenderFns } from "./vip_product_page.vue?vue&type=template&id=49c9fb84&scoped=true"
import script from "./vip_product_page.vue?vue&type=script&lang=js"
export * from "./vip_product_page.vue?vue&type=script&lang=js"
import style0 from "./vip_product_page.vue?vue&type=style&index=0&id=49c9fb84&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c9fb84",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/riverlei/project/java/bgyy-project/front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49c9fb84')) {
      api.createRecord('49c9fb84', component.options)
    } else {
      api.reload('49c9fb84', component.options)
    }
    module.hot.accept("./vip_product_page.vue?vue&type=template&id=49c9fb84&scoped=true", function () {
      api.rerender('49c9fb84', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/group_buy/vip_product_page.vue"
export default component.exports