import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import dayjs from 'dayjs';
export default {
  name: 'logistics_waybill_page',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '订单',
        addFunName: 'add',
        modifyFunName: 'modify',
        delFunName: 'del',
        queryFunName: 'logisticHongdeWaybillQuery',
        detailFunName: 'logisticHongdeWaybillDetail',
        dicTreeFunName: 'mngDictionaryLowerTree',
        shipCompanyDetailFunName: 'logisticHongdeShipCompanyDetail',
        totalFeeFunName: 'logisticHongdeWaybillTotalFee',
        merchantDetail: 'logisticHongdeMerchantDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time',
        dateType: 'Create',
        merchantNickname: null,
        merchantContactPhone: null,
        deliveryMode: null,
        waybillState: null,
        acceptLastTime: null,
        cradleLastTime: null,
        assembleLastTime: null,
        purposeLastTime: null,
        starDate: null,
        endDate: null,
        extractIdentity: null
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      detailDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      detailData: {
        merchantContactPhone: null,
        extractIdentity: null,
        shipCompanyId: null,
        shipRemark: null,
        containerSize: null,
        containerShape: null,
        containerTotalAmount: null,
        containerEmptyOrFull: null,
        containerWeightRange: null,
        deliveryMode: null,
        carConditionRequire: null,
        deliveryRequire: null,
        remark: null,
        deliveryOnceCost: null,
        deliveryEstimateTotalCost: null,
        provideInvoice: null,
        serviceFeeChargeCost: null,
        serviceFeeTotalCost: null
      },
      dicData: {
        upperCode: 'logistics_hd_container_size',
        configSizes: [],
        configSizeOptions: {},
        shapeUpperCode: 'logistics_hd_container_shape',
        configShapes: [],
        configShapeOptions: {},
        orientationCode: 'logistics_hd_orientation',
        orientationList: [],
        orientationOptions: {}
      },
      totalFeeFormData: {
        deliveryEstimateTotalCost: null,
        allCarFreightCirculateCost: null,
        serviceFeeTotalCost: null,
        serviceFeeChargeCost: null
      },
      currentTime: null,
      importAndExportCategory: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    Promise.all([new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.upperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configSizes = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configSizeOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.shapeUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configShapes = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configShapeOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    }),, new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.orientationCode).then(function (res) {
        if (res.data) {
          _this.dicData.orientationList = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.orientationOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName]('logistics_hd_i&e_category').then(function (res) {
        if (res.data) {
          for (var i = 0; i < res.data.length; i++) {
            _this.importAndExportCategory[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    })]).then(function (result) {
      _this.query();
    });
  },
  methods: {
    waybillGo: function waybillGo(row) {
      this.goChild(row, 'waybillOrder');
    },
    goChild: function goChild(id, tableName) {
      console.log('id', id);
      this.$emit('child-go', id, tableName);
    },
    detail: function detail(row) {
      var _this2 = this;
      this.$api[this.pageConfig.detailFunName](row.id).then(function (res) {
        _this2.detailData = res.data;
        _this2.detailData.serviceFeeChargeCost = row.serviceFeeChargeCost || 0;
        _this2.detailData.serviceFeeTotalCost = row.serviceFeeTotalCost || 0;

        // 派单方
        if (res.data.merchantId) {
          _this2.$api[_this2.pageConfig.merchantDetail](res.data.merchantId).then(function (merchantRes) {
            _this2.$set(_this2.detailData, 'merchantNickname', merchantRes.data.nickname);
          });
        }
        // 船公司
        if (res.data.shipCompanyId) {
          _this2.$api[_this2.pageConfig.shipCompanyDetailFunName](res.data.shipCompanyId).then(function (shipCompanyRes) {
            _this2.detailData.shipCompanyId = shipCompanyRes.data.code || _this2.detailData.shipCompanyId;
            _this2.detailData.shipRemark = shipCompanyRes.data.remark || '';
            _this2.detailData.shipCompanyName = shipCompanyRes.data.name || '';
          });
        }

        // 车队
        if (res.data.specifyCarTeamIds) {
          var carTeamNames = '';
          var carTeamIdJson = JSON.parse(res.data.specifyCarTeamIds);
          for (var i = 0; i < carTeamIdJson.length; i++) {
            if (i !== 0) {
              carTeamNames += '、';
            }
            carTeamNames += carTeamIdJson[i].name;
          }
          res.data.specifyCarTeamIds = carTeamNames;
        }
        _this2.detailDialogShow = true;
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.currentTime = dayjs().format('YYYYMMDDHHmmss');
      this.$api[this.pageConfig.queryFunName](this.processSearchForm()).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
      this.$api[this.pageConfig.totalFeeFunName](this.processSearchForm()).then(function (res) {
        _this3.totalFeeFormData = res.data;
      });
    },
    processSearchForm: function processSearchForm() {
      var commitSearchForm = JSON.parse(JSON.stringify(this.searchForm));
      return commitSearchForm;
    },
    search: function search() {
      this.searchForm.page = 1;
      if (this.searchForm.waybillState > 0) {
        this.searchForm.onlineState = true;
      } else {
        delete this.searchForm.onlineState;
      }
      this.query();
    },
    exportData: function exportData() {
      var requestUrl = process.env.VUE_APP_API + 'logistics_hongde/waybill/exportJoinStats';
      var searchParams = this.processSearchForm();
      searchParams.Authorization = localStorage.getItem('token');
      window.open(requestUrl + this.joinGetParam(searchParams), '_blank');
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};