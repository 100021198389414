import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import dayjs from 'dayjs';
export default {
  name: 'logistics_hongde_ship_company_carTeams',
  components: {
    carTeamSel: function carTeamSel() {
      return import('./car_team_sel');
    }
  },
  props: {
    currentShipCompany: {
      type: Object
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '车队',
        addFunName: 'logisticsHongDeShipCompanyCarTeamAdd',
        delFunName: 'logisticsHongDeShipCompanyCarTeamDel',
        queryFunName: 'logisticsHongDeShipCompanyCarTeamQueryByShipCompany'
      },
      searchForm: {
        page: 1,
        limit: 10,
        sortField: 'create_time'
      },
      formData: {
        shipCompanyId: null,
        carTeamId: null
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      dialogShow: false
    };
  },
  mounted: function mounted() {
    this.formData.shipCompanyId = this.currentShipCompany.id;
    this.query();
  },
  methods: {
    back: function back() {
      this.$emit('child-go-car-team');
    },
    del: function del(id) {
      var _this = this;
      this.$confirm('确定要删除吗').then(function () {
        _this.$api[_this.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this.$message.success('删除成功');
            _this.query();
          } else {
            _this.$message.error('删除失败');
          }
        });
      });
    },
    selClick: function selClick(carTeamId) {
      var _this2 = this;
      if (!carTeamId) {
        this.dialogShow = false;
        return;
      }
      this.formData.carTeamId = carTeamId;
      this.$api[this.pageConfig.addFunName](this.formData).then(function (res) {
        if (res.data) {
          _this2.$message.success('新增成功');
          _this2.query();
          _this2.dialogShow = false;
        } else {
          _this2.$message.error('新增失败');
        }
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.currentShipCompany.id, this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};