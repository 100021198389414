import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'website_navigation_article_mng',
  components: {
    navigationTable: function navigationTable() {
      return import('./navigation_page');
    },
    articleTable: function articleTable() {
      return import('./article_page');
    }
  },
  data: function data() {
    return {
      websiteTab: 'navigation',
      currentNavigation: null
    };
  },
  methods: {
    goArticle: function goArticle(data) {
      this.currentNavigation = data;
      this.websiteTab = 'article';
    },
    goNavigation: function goNavigation() {
      this.websiteTab = 'navigation';
    }
  }
};