export default (function (_ref) {
  var request = _ref.request;
  return {
    chargeOrderQueryJoinProjectAndUser: function chargeOrderQueryJoinProjectAndUser(data) {
      return request({
        url: '/pay/chargeOrder/queryJoinProjectAndUser',
        method: 'get',
        params: data
      });
    },
    chargeOrderDetail: function chargeOrderDetail(id) {
      return request({
        url: "/pay/chargeOrder/".concat(id),
        method: 'get'
      });
    }
  };
});