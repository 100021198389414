export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeMerchantAdd: function logisticHongdeMerchantAdd(data) {
      return request({
        url: '/logistics_hongde/merchant/add',
        method: 'post',
        data: data
      });
    },
    logisticHongdeMerchantModifyBasic: function logisticHongdeMerchantModifyBasic(data) {
      return request({
        url: "/logistics_hongde/merchant/".concat(data.id, "/basic"),
        method: 'post',
        data: data
      });
    },
    logisticHongdeMerchantModifyAuth: function logisticHongdeMerchantModifyAuth(data) {
      return request({
        url: "/logistics_hongde/merchant/".concat(data.id, "/auth"),
        method: 'post',
        data: data
      });
    },
    logisticHongdeMerchantDel: function logisticHongdeMerchantDel(id) {
      return request({
        url: "/logistics_hongde/merchant/".concat(id),
        method: 'delete'
      });
    },
    logisticHongdeMerchantQuery: function logisticHongdeMerchantQuery(data) {
      return request({
        url: '/logistics_hongde/merchant/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    logisticHongdeMerchantDetail: function logisticHongdeMerchantDetail(id) {
      return request({
        url: "/logistics_hongde/merchant/detailForAdmin/".concat(id),
        method: 'get'
      });
    },
    logisticHongdeMerchantEvalSwitch: function logisticHongdeMerchantEvalSwitch(id, flag) {
      return request({
        url: "/logistics_hongde/merchant/".concat(id, "/evalSwitch"),
        method: 'post',
        params: {
          evalSwitch: flag
        }
      });
    },
    logisticHongdeMerchantAddQuota: function logisticHongdeMerchantAddQuota(id, value) {
      return request({
        url: "/logistics_hongde/merchant/".concat(id, "/addQuotaUnused"),
        method: 'post',
        params: {
          quota: value
        }
      });
    },
    logisticHongdeMerchantSuccess: function logisticHongdeMerchantSuccess(id) {
      return request({
        url: "/logistics_hongde/merchant/".concat(id, "/success"),
        method: 'get'
      });
    },
    logisticHongdeMerchantFail: function logisticHongdeMerchantFail(id, value) {
      return request({
        url: "/logistics_hongde/merchant/".concat(id, "/fail"),
        method: 'get',
        params: {
          authMsg: value
        }
      });
    }
  };
});