import "core-js/modules/es.function.name.js";
import dayjs from 'dayjs';
export default {
  name: 'driver_sel',
  data: function data() {
    return {
      dayjs: dayjs,
      currentUser: localStorage.getItem('userId'),
      pageConfig: {
        editPageName: '司机',
        queryDriverFunName: 'logisticHongdeDriverQuery',
        carTeamFunName: 'logisticHongdeCarTeamQuery'
      },
      searchForm: {
        page: 1,
        limit: 10,
        authState: null,
        searchKey: null,
        userState: null,
        sortField: 'create_time'
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      configCarteamOptions: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$api[this.pageConfig.carTeamFunName]({
      page: 1,
      limit: -1
    }).then(function (res) {
      if (res.data) {
        _this.configCarTeams = res.data;
        for (var i = 0; i < res.data.length; i++) {
          _this.configCarteamOptions[res.data[i].id] = res.data[i].name;
        }
      }
      _this.query();
    });
  },
  methods: {
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryDriverFunName](this.searchForm).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    sel: function sel(data) {
      this.$emit('close', data);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};