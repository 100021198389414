export default (function (_ref) {
  var request = _ref.request;
  return {
    mngSwitchAdd: function mngSwitchAdd(data) {
      return request({
        url: '/mng/switchs',
        method: 'post',
        data: data
      });
    },
    mngSwitchModify: function mngSwitchModify(data) {
      return request({
        url: "/mng/switchs/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    mngSwitchDel: function mngSwitchDel(id) {
      return request({
        url: "/mng/switchs/".concat(id),
        method: 'delete'
      });
    },
    mngSwitchQuery: function mngSwitchQuery(data) {
      return request({
        url: '/mng/switchs/query',
        method: 'get',
        params: data
      });
    },
    mngSwitchDetail: function mngSwitchDetail(id) {
      return request({
        url: "/mng/switchs/".concat(id),
        method: 'get'
      });
    },
    mngSwitchCacheValue: function mngSwitchCacheValue(code) {
      return request({
        url: "/mng/switchs/cache/value/".concat(code),
        method: 'get'
      });
    },
    mngSwitchCacheValues: function mngSwitchCacheValues(codes) {
      return request({
        url: "/mng/switchs/cache/values?codes=".concat(codes),
        method: 'get'
      });
    },
    mngSwitchClearCache: function mngSwitchClearCache(code) {
      return request({
        url: "/mng/switchs/cache/clear?code=".concat(code),
        method: 'post'
      });
    }
  };
});