import dayjs from 'dayjs';
export default {
  name: 'sizeModel_page',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        queryFunName: 'carTeamVehicleLogQuery'
      },
      searchForm: {
        page: 1,
        limit: 10,
        vehicleName: '',
        sortField: 'create_time'
      },
      queryLoading: false,
      tableData: [],
      totalCount: 0
    };
  },
  mounted: function mounted() {
    this.query();
  },
  methods: {
    query: function query() {
      var _this = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this.tableData = res.data || [];
        _this.totalCount = res.count;
        _this.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.query();
    }
  }
};