import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import dayjs from 'dayjs';
export default {
  name: 'edu_liyou_order',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        queryFunName: 'eduLiyouOrderQueryJoinCourseAndUserAndIntroduce',
        totalFeeFunName: 'eduLiyouOrderFeeTotal'
      },
      searchForm: {
        page: 1,
        limit: 10,
        sortField: 'create_time',
        userNickname: null,
        courseName: null,
        starDate: null,
        endDate: null,
        orderState: null,
        introduceNickname: null,
        introducePhone: null
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      formLabelWidth: '100px',
      totalFeeFormData: {
        orderCost: null,
        finalCost: null,
        userCouponCost: null
      }
    };
  },
  mounted: function mounted() {
    this.query();
  },
  methods: {
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (index == 5 || index == 7) {
          var values = data.map(function (item) {
            return Number(item[column.property]);
          });
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index] / 100 + ' 元';
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    query: function query() {
      var _this = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.processSearchForm()).then(function (res) {
        _this.tableData = res.data || [];
        _this.totalCount = res.count;
        _this.queryLoading = false;
      });
      this.$api[this.pageConfig.totalFeeFunName](this.processSearchForm()).then(function (res) {
        _this.totalFeeFormData = res.data;
        console.log(_this.totalFeeFormData);
      });
    },
    processSearchForm: function processSearchForm() {
      var commitSearchForm = JSON.parse(JSON.stringify(this.searchForm));
      return commitSearchForm;
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};