export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeShipCompanyLogQueryJoinUser: function logisticHongdeShipCompanyLogQueryJoinUser(data) {
      return request({
        url: '/logistics_hongde/shipCompanyLog/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    logisticHongdeShipCompanyLogDetail: function logisticHongdeShipCompanyLogDetail(id) {
      return request({
        url: "/logistics_hongde/shipCompanyLog/".concat(id),
        method: 'get'
      });
    }
  };
});