import dayjs from 'dayjs';
export default {
  name: 'groupbuy_activity_share',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '成交线索',
        queryFunName: 'groupBuyActivityShareQueryJoinUserAndActivity'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        recordState: null,
        starDate: null,
        endDate: null,
        sortField: 'last_time'
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0
    };
  },
  mounted: function mounted() {
    this.query();
  },
  methods: {
    query: function query() {
      var _this = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this.tableData = res.data || [];
        _this.totalCount = res.count;
        _this.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'last_time';
      this.query();
    }
  }
};