import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    groupBuyActivityOrderaddVirtualOrder: function groupBuyActivityOrderaddVirtualOrder(data) {
      return request({
        url: '/group_buy/groupActivitysOrder/addVirtualOrder',
        method: 'post',
        data: data
      });
    },
    groupBuyActivityOrderModifySignInfo: function groupBuyActivityOrderModifySignInfo(data) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(data.id, "/signInfo"),
        method: 'post',
        data: data
      });
    },
    groupBuyActivityOrderDel: function groupBuyActivityOrderDel(id) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(id),
        method: 'delete'
      });
    },
    groupBuyActivityOrderQuery: function groupBuyActivityOrderQuery(data) {
      return request({
        url: '/group_buy/groupActivitysOrder/query',
        method: 'get',
        params: data
      });
    },
    groupBuyActivityOrderDetail: function groupBuyActivityOrderDetail(id) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(id),
        method: 'get'
      });
    },
    groupBuyActivityOrderMsgQueueClear: function groupBuyActivityOrderMsgQueueClear(id) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(id, "/msgQueueClear"),
        method: 'delete'
      });
    },
    groupBuyActivityOrderSuccess: function groupBuyActivityOrderSuccess(id) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(id, "/success"),
        method: 'post'
      });
    },
    groupBuyActivityOrderUpgrade: function groupBuyActivityOrderUpgrade(id) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(id, "/upgrade"),
        method: 'post'
      });
    },
    groupBuyActivityOrderDemotion: function groupBuyActivityOrderDemotion(id) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(id, "/demotion"),
        method: 'post'
      });
    },
    groupBuyActivityOrderAlone: function groupBuyActivityOrderAlone(id) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(id, "/alone"),
        method: 'post'
      });
    },
    groupBuyActivityOrderMove: function groupBuyActivityOrderMove(id, groupId) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(id, "/move/").concat(groupId),
        method: 'post'
      });
    },
    groupBuyActivityOrderUse: function groupBuyActivityOrderUse(id, useCode) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(id, "/use/").concat(useCode),
        method: 'post'
      });
    },
    groupBuyActivityOrderRefund: function groupBuyActivityOrderRefund(id, allowUse) {
      return request({
        url: "/group_buy/groupActivitysOrder/".concat(id, "/refund/").concat(allowUse),
        method: 'post'
      });
    }
  };
});