import _objectSpread from "/Users/riverlei/project/java/bgyy-project/front-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import { uniqueId } from 'lodash';

/**
 * @description 给菜单数据补充上 path 字段
 * @description https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */
function supplementPath(menu) {
  return menu.map(function (e) {
    return _objectSpread(_objectSpread({}, e), {}, {
      path: e.path || uniqueId('d2-menu-empty-')
    }, e.children ? {
      children: supplementPath(e.children)
    } : {});
  });
}
export var menuHeader = supplementPath([{
  path: '/index',
  title: '首页',
  icon: 'home'
}, {
  title: '页面',
  icon: 'folder-o',
  children: [{
    path: '/page1',
    title: '页面 1'
  }, {
    path: '/page2',
    title: '页面 2'
  }, {
    path: '/page3',
    title: '页面 3'
  }, {
    path: '/test1',
    title: '新页面'
  }]
}]);
export var menuAside = supplementPath([{
  path: '/index',
  title: '首页',
  icon: 'home'
}, {
  title: '页面',
  icon: 'folder-o',
  children: [{
    path: '/page1',
    title: '页面 1'
  }, {
    path: '/page2',
    title: '页面 2'
  }, {
    path: '/page3',
    title: '页面 3'
  }, {
    path: '/test1',
    title: '新页面'
  }]
}]);