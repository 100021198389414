import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键字"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "关键字"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "帐号状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.userState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "userState", $$v);
      },
      expression: "searchForm.userState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "正常",
      value: "active"
    }
  }), _c("el-option", {
    attrs: {
      label: "锁定",
      value: "lock"
    }
  }), _c("el-option", {
    attrs: {
      label: "禁用",
      value: "disable"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "认证状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.authState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "authState", $$v);
      },
      expression: "searchForm.authState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "已认证",
      value: "Success"
    }
  }), _c("el-option", {
    attrs: {
      label: "待审核",
      value: "Wait"
    }
  }), _c("el-option", {
    attrs: {
      label: "已驳回",
      value: "Fail"
    }
  })], 1)], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "90",
      fixed: ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "175",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.driverEdit(scope.row.userId);
            }
          }
        }, [_vm._v("编辑")]), _c("el-dropdown", {
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            size: "mini",
            "split-button": "",
            type: "primary"
          },
          on: {
            command: _vm.moreButton
          }
        }, [_vm._v(" 更多 "), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [scope.row.authState === "Wait" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-check",
            command: _vm.beforeCommand(scope.$index, scope.row, "success")
          }
        }, [_vm._v("认证通过")]) : _vm._e(), scope.row.authState === "Wait" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-close",
            command: _vm.beforeCommand(scope.$index, scope.row, "fail")
          }
        }, [_vm._v("认证驳回")]) : _vm._e(), scope.row.userState === "active" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-view",
            command: _vm.beforeCommand(scope.$index, scope.row, "seeEval")
          }
        }, [_vm._v("查看评价")]) : _vm._e(), scope.row.userState === "active" && scope.row.id !== "Wechat:guan409932398" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-lock",
            command: _vm.beforeCommand(scope.$index, scope.row, "disable")
          }
        }, [_vm._v("禁用司机")]) : _vm._e(), scope.row.userState === "disable" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-unlock",
            command: _vm.beforeCommand(scope.$index, scope.row, "unDisable")
          }
        }, [_vm._v("解禁司机")]) : _vm._e(), scope.row.userState === "active" && !scope.row.evalSwitch && scope.row.authState === "Success" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-lock",
            command: _vm.beforeCommand(scope.$index, scope.row, "eval")
          }
        }, [_vm._v("开启评价")]) : _vm._e(), scope.row.userState === "active" && scope.row.evalSwitch && (scope.row.authState === "Success" || scope.row.authState === "Fail") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-lock",
            command: _vm.beforeCommand(scope.$index, scope.row, "unEval")
          }
        }, [_vm._v("关闭评价")]) : _vm._e(), _vm.currentUser === "Wechat:guan409932398" && scope.row.id !== "Wechat:guan409932398" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-delete",
            command: _vm.beforeCommand(scope.$index, scope.row, "clear")
          }
        }, [_vm._v("清理用户")]) : _vm._e()], 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "userState",
      label: "账号",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "80",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.userState === "active" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("正常")]) : _vm._e(), scope.row.userState === "lock" ? _c("span", {
          staticClass: "text-interrupt"
        }, [_vm._v("锁定")]) : _vm._e(), scope.row.userState === "disable" ? _c("span", {
          staticClass: "text-collapse"
        }, [_vm._v("禁用")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "authState",
      label: "认证",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "80",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.authState === "Success" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("已认证")]) : scope.row.authState === "Wait" ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("待审核")]) : scope.row.authState === "Fail" ? _c("span", {
          staticClass: "text-collapse"
        }, [_vm._v("已驳回")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "evalSwitch",
      label: "评价",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "80",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.evalSwitch ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("开启")]) : _c("span", {
          staticClass: "text-collapse"
        }, [_vm._v("关闭")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "姓名",
      "show-overflow-tooltip": "",
      width: "120",
      fixed: ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "contactPhone",
      label: "手机",
      "show-overflow-tooltip": "",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "level",
      label: "等级",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.level ? _c("div", [_vm._v(_vm._s(_vm.dicData.configLevelOptions[scope.row.level]))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "carCategory",
      label: "车辆类型",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.dicData.configCarTypeOptions[scope.row.carCategory]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "carNumber",
      label: "车牌号",
      "show-overflow-tooltip": "",
      width: "150"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "carTeam",
      label: "所属车队",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.carTeamData.configCarteamOptions[scope.row.carTeam]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "evalScore",
      label: "平均评分",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(((scope.row.evalScore || 0) / (scope.row.evalAmount || 1)).toFixed(2)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "giveUpAmountForOneMonth",
      label: "30天内弃单",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.giveUpAmountForOneMonth || 0) + " 次")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "giveUpAmountTotal",
      label: "累计弃单",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.giveUpAmountTotal || 0) + " 次")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "注册时间",
      sortable: "custom",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.createTime ? _vm.dayjs(scope.row.createTime * 1000).format("YYYY-MM-DD") : ""))])];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.editDriverDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDriverDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initDriverFormData(_vm.emptyDriverFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDriverDataVerify",
    attrs: {
      model: _vm.driverFormData,
      rules: _vm.driversRules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 7
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "姓名 / 联系电话",
      "label-width": _vm.formLabelWidth,
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "姓名"
    },
    model: {
      value: _vm.driverFormData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.driverFormData, "name", $$v);
      },
      expression: "driverFormData.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "contactPhone"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "联系电话"
    },
    model: {
      value: _vm.driverFormData.contactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.driverFormData, "contactPhone", $$v);
      },
      expression: "driverFormData.contactPhone"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证号",
      "label-width": _vm.formLabelWidth,
      prop: "identityCard"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "身份证号"
    },
    model: {
      value: _vm.driverFormData.identityCard,
      callback: function callback($$v) {
        _vm.$set(_vm.driverFormData, "identityCard", $$v);
      },
      expression: "driverFormData.identityCard"
    }
  })], 1)], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证正面",
      "label-width": _vm.formLabelWidth,
      prop: "identityCardImageFront"
    }
  }, [!_vm.driverFormData.identityCardImageFront ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadCardFrontImage
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.driverFormData.identityCardImageFront ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.driverFormData.identityCardImageFront ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.driverFormData.identityCardImageFront
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeIdentityCardImageFrontFile();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.driverFormData.identityCardImageFront);
      }
    }
  })])])])]) : _vm._e()], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证反面",
      "label-width": _vm.formLabelWidth,
      prop: "identityCardImageBack"
    }
  }, [!_vm.driverFormData.identityCardImageBack ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadCardBackImage
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.driverFormData.identityCardImageBack ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.driverFormData.identityCardImageBack ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.driverFormData.identityCardImageBack
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeIdentityCardImageBackFile();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.driverFormData.identityCardImageBack);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "车牌号",
      "label-width": _vm.formLabelWidth,
      prop: "carNumber"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "车牌号"
    },
    model: {
      value: _vm.driverFormData.carNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.driverFormData, "carNumber", $$v);
      },
      expression: "driverFormData.carNumber"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  })], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "车辆类型",
      "label-width": _vm.formLabelWidth,
      prop: "carCategory"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.driverFormData.carCategory,
      callback: function callback($$v) {
        _vm.$set(_vm.driverFormData, "carCategory", $$v);
      },
      expression: "driverFormData.carCategory"
    }
  }, _vm._l(_vm.dicData.configCarTypes, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "所属车队",
      "label-width": _vm.formLabelWidth,
      prop: "carTeam"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.driverFormData.carTeam,
      callback: function callback($$v) {
        _vm.$set(_vm.driverFormData, "carTeam", $$v);
      },
      expression: "driverFormData.carTeam"
    }
  }, _vm._l(_vm.carTeamData.configCarTeams, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "驾驶证",
      "label-width": _vm.formLabelWidth,
      prop: "drivingLicenceImage"
    }
  }, [!_vm.driverFormData.drivingLicenceImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadDrivingLicenceImage
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.driverFormData.drivingLicenceImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.driverFormData.drivingLicenceImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.driverFormData.drivingLicenceImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeDrivingLicenceFile();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.driverFormData.drivingLicenceImage);
      }
    }
  })])])])]) : _vm._e()], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "行驶证",
      "label-width": _vm.formLabelWidth,
      prop: "vehicleLicense"
    }
  }, [!_vm.driverFormData.vehicleLicense ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadVehicleLicenseImage
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.driverFormData.vehicleLicense ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.driverFormData.vehicleLicense ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.driverFormData.vehicleLicense
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeVehicleLicenseFile();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.driverFormData.vehicleLicense);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "车辆照片",
      "label-width": _vm.formLabelWidth,
      prop: "carImageArray"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      limit: 3,
      "file-list": _vm.driverFormData.carImageArray,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadCarsImage,
      "on-preview": _vm.previewArrayImageFile,
      "on-remove": _vm.removeCarsImageFile
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })])], 1)], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "箱货保险单",
      "label-width": _vm.formLabelWidth,
      prop: "insurancePolicyImageArray"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      limit: 3,
      "file-list": _vm.driverFormData.insurancePolicyImageArray,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadPolicyImage,
      "on-preview": _vm.previewArrayImageFile,
      "on-remove": _vm.removePolicyImageFile
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })])], 1)], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDriverEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitDriverFormData
    }
  }, [_vm._v("确 定")])], 1)], 1), _c("el-dialog", {
    staticClass: "successPage",
    attrs: {
      visible: _vm.successDialogShow,
      title: "通过认证",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.successDialogShow = $event;
      },
      close: _vm.closeSuccessDialogShow
    }
  }, [_c("div", [_c("div", [_vm._v("请为司机选择所属车队")]), _c("div", {
    staticClass: "rowInfo"
  }, [_c("div", [_vm._v(_vm._s(_vm.rowCarInfo.name))]), _c("div", [_vm._v(_vm._s(_vm.rowCarInfo.contactPhone))]), _c("div", [_vm._v(_vm._s(_vm.rowCarInfo.carNumber))])])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.successData.successCarTeam,
      callback: function callback($$v) {
        _vm.$set(_vm.successData, "successCarTeam", $$v);
      },
      expression: "successData.successCarTeam"
    }
  }, _vm._l(_vm.carTeamData.configCarTeams, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeSuccessDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitSuccessFormData
    }
  }, [_vm._v("确 定")])], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };