import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'logistics_hongde_driver_mng',
  components: {
    driverTable: function driverTable() {
      return import('./driver_page');
    },
    driverEvalTable: function driverEvalTable() {
      return import('./driver_eval_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'driver',
      currentDriver: null
    };
  },
  methods: {
    goDriver: function goDriver() {
      this.tableShow = 'driver';
    },
    goChild: function goChild(data, tableName) {
      this.currentDriver = data;
      this.tableShow = tableName;
    }
  }
};