var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.tableShow === "user" ? _c("user", {
    on: {
      "child-go": _vm.goChild
    }
  }) : _vm._e(), _vm.tableShow === "userAddress" ? _c("userAddress", {
    attrs: {
      currentUser: _vm.currentUser
    },
    on: {
      "child-go-user": _vm.goUser
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };