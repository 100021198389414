var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("waybillTable", {
    staticClass: "waybill-table",
    class: _vm.tableShow === "waybill" ? "show-waybill" : "",
    on: {
      "child-go": _vm.goChild
    }
  }), _vm.tableShow === "waybillOrder" ? _c("waybillOrederTable", {
    attrs: {
      currentWaybill: _vm.currentWaybill
    },
    on: {
      "child-go-waybill": _vm.goWaybill
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };