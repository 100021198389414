import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
export default {
  name: 'groupbuy_company_store',
  props: {
    currentCompany: {
      type: Object
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '活动方门店',
        addFunName: 'groupBuyCompanyStoreAdd',
        modifyFunName: 'groupBuyCompanyStoreModify',
        delFunName: 'groupBuyCompanyStoreDel',
        queryFunName: 'groupBuyCompanyStoreQuery',
        detailFunName: 'groupBuyCompanyStoreDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time',
        companyId: this.currentCompany.id
      },
      formData: {
        id: null,
        userId: this.currentCompany.userId,
        companyId: this.currentCompany.id,
        addressName: null,
        contactName: null,
        contactPhone: null,
        detailAddress: null,
        lon: null,
        lat: null
      },
      mapConfig: {
        zoom: 12,
        center: [120.384599, 36.062687],
        activeIndex: '',
        addressList: []
      },
      rules: {
        addressName: [{
          required: true,
          message: '门店名称不能为空',
          trigger: 'blur'
        }],
        contactName: [{
          required: true,
          message: '联系人不能为空',
          trigger: 'blur'
        }],
        contactPhone: [{
          required: true,
          message: '联系电话不能为空',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    back: function back() {
      this.$emit('child-go-company');
    },
    initMapByInput: function initMapByInput(clear) {
      var that = this;

      // 自动搜索插件
      AMap.plugin('AMap.Autocomplete', function () {
        var autoOptions = {
          city: '全国'
        };

        // 创建搜索范围
        var autoComplete = new AMap.Autocomplete(autoOptions);

        // 指定入参
        autoComplete.search(that.formData.detailAddress, function (status, e) {
          if (status === 'complete' && e.info === 'OK') {
            if (e.tips && e.tips.length > 0) {
              var tips = e.tips.filter(function (i) {
                return i.location && i.address.length > 0;
              });
              var tip = tips[0];
              if (typeof tip !== 'undefined') {
                that.mapConfig.center = [tip.location.lng, tip.location.lat];
              }
              that.mapConfig.addressList = tips;
            } else {
              that.mapConfig.addressList = [];
            }
          }
        });
      });
    },
    itemChange: function itemChange(n, index) {
      this.mapConfig.activeIndex = index;

      // 输入后搜索出来的，反地理编码出来的参数有所不同
      var lng = n.lng || n.longitude || n.location.lng;
      var lat = n.lat || n.latitude || n.location.lat;
      this.formData.detailAddress = (n.district || '') + ' ' + (n.name || '');
      this.formData.lon = lng;
      this.formData.lat = lat;
      this.mapConfig.zoom = 13;
      this.mapConfig.center = [lng, lat];
    },
    initFormData: function initFormData(data) {
      // 处理 “后端” > “前端” 数据差异
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(id) {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this.initFormData(res.data);
        _this.editDialogTitle = _this.pageConfig.editPageName + '维护';
        _this.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this4 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this4.formData.id) {
            _this4.$api[_this4.pageConfig.modifyFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('编辑成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('编辑失败');
              }
            });
          } else {
            _this4.$api[_this4.pageConfig.addFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('新增成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};