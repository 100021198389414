export default (function (_ref) {
  var request = _ref.request;
  return {
    groupBuyActivityBrowseQueryJoinUserAndActivity: function groupBuyActivityBrowseQueryJoinUserAndActivity(data) {
      return request({
        url: '/group_buy/groupActivitysBrowse/queryJoinUserAndActivitySta',
        method: 'get',
        params: data
      });
    }
  };
});