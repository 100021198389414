export default (function (_ref) {
  var request = _ref.request;
  return {
    tobaccoJimoInspectionRecordDetailsQuery: function tobaccoJimoInspectionRecordDetailsQuery(id) {
      return request({
        url: "/tobacco_jimo/inspectionRecordDetail/".concat(id, "/queryAll"),
        method: 'get'
      });
    }
  };
});