export default (function (_ref) {
  var request = _ref.request;
  return {
    mngAboutAdd: function mngAboutAdd(data) {
      return request({
        url: '/mng/about',
        method: 'post',
        data: data
      });
    },
    mngAboutModify: function mngAboutModify(data) {
      return request({
        url: "/mng/about/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    mngAboutDel: function mngAboutDel(id) {
      return request({
        url: "/mng/about/".concat(id),
        method: 'delete'
      });
    },
    mngAboutQuery: function mngAboutQuery(data) {
      return request({
        url: '/mng/about/query',
        method: 'get',
        params: data
      });
    },
    mngAboutDetail: function mngAboutDetail(id) {
      return request({
        url: "/mng/about/".concat(id),
        method: 'get'
      });
    },
    mngAboutCacheValue: function mngAboutCacheValue(code) {
      return request({
        url: "/mng/about/cache/value/".concat(code),
        method: 'get'
      });
    },
    mngAboutCacheValues: function mngAboutCacheValues(codes) {
      return request({
        url: "/mng/about/cache/values?codes=".concat(codes),
        method: 'get'
      });
    },
    mngAboutClearCache: function mngAboutClearCache(code) {
      return request({
        url: "/mng/about/cache/clear?code=".concat(code),
        method: 'post'
      });
    }
  };
});