export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeWaybillOrderLogQueryJoinUser: function logisticHongdeWaybillOrderLogQueryJoinUser(data) {
      return request({
        url: '/logistics_hongde/waybillOrderLog/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    logisticHongdeWaybillOrderLogDetail: function logisticHongdeWaybillOrderLogDetail(id) {
      return request({
        url: "/logistics_hongde/waybillOrderLog/".concat(id),
        method: 'get'
      });
    }
  };
});