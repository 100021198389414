export default (function (_ref) {
  var request = _ref.request;
  return {
    tobaccoJimoInspectionRecordQuery: function tobaccoJimoInspectionRecordQuery(data) {
      return request({
        url: '/tobacco_jimo/inspectionRecord/queryJoinSaleStore',
        method: 'get',
        params: data
      });
    },
    tobaccoJimoInspectionRecordDetail: function tobaccoJimoInspectionRecordDetail(id) {
      return request({
        url: "/tobacco_jimo/inspectionRecord/".concat(id),
        method: 'get'
      });
    }
  };
});