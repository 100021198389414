import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'edu_liyou_course_mng_page',
  components: {
    courseTable: function courseTable() {
      return import('./edu_liyou_coupon_page');
    },
    receiveTable: function receiveTable() {
      return import('./edu_liyou_coupon_receive_page');
    },
    useTable: function useTable() {
      return import('./edu_liyou_coupon_use_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'coupon',
      currentCoupon: null
    };
  },
  methods: {
    goCoupon: function goCoupon() {
      this.tableShow = 'coupon';
    },
    goChild: function goChild(data, tableName) {
      this.currentCoupon = data;
      this.tableShow = tableName;
    }
  }
};