export default (function (_ref) {
  var request = _ref.request;
  return {
    groupBuyVipOrderDel: function groupBuyVipOrderDel(id) {
      return request({
        url: "/group_buy/companysVipOrder/".concat(id),
        method: 'delete'
      });
    },
    groupBuyVipOrderQueryJoinCompanyAndUser: function groupBuyVipOrderQueryJoinCompanyAndUser(data) {
      return request({
        url: '/group_buy/companysVipOrder/queryJoinCompanyAndUser',
        method: 'get',
        params: data
      });
    },
    groupBuyVipOrderDetail: function groupBuyVipOrderDetail(id) {
      return request({
        url: "/group_buy/companysVipOrder/".concat(id),
        method: 'get'
      });
    },
    groupBuyVipOrderSuccess: function groupBuyVipOrderSuccess(id) {
      return request({
        url: "/group_buy/companysVipOrder/".concat(id, "/success"),
        method: 'post'
      });
    }
  };
});