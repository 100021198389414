import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import dayjs from 'dayjs';
export default {
  name: 'logistics_hongde_ship_company',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '船公司',
        addFunName: 'logisticHongdeShipCompanyAdd',
        modifyFunName: 'logisticHongdeShipCompanyModify',
        delFunName: 'logisticHongdeShipCompanyDel',
        queryFunName: 'logisticHongdeShipCompanyQuery',
        detailFunName: 'logisticHongdeShipCompanyDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {
        id: null,
        code: null,
        remark: null,
        name: null
      },
      rules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '代码不能为空',
          trigger: 'blur'
        }]
        // , remark: [
        //   { required: true, message: '提醒内容不能为空', trigger: 'blur' }
        // ]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(id) {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this.initFormData(res.data);
        _this.editDialogTitle = _this.pageConfig.editPageName + '维护';
        _this.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    },
    carTeams: function carTeams(row) {
      this.goChild(row, 'carTeamsTable');
    },
    goChild: function goChild(id, tableName) {
      this.$emit('child-go', id, tableName);
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this4 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this4.formData.id) {
            _this4.$api[_this4.pageConfig.modifyFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('编辑成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('编辑失败');
              }
            });
          } else {
            _this4.$api[_this4.pageConfig.addFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('新增成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};