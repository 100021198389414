export default (function (_ref) {
  var request = _ref.request;
  return {
    payBalanceRecordQueryJoinUser: function payBalanceRecordQueryJoinUser(data) {
      return request({
        url: '/pay/balanceRecord/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    payBalanceRecordDetail: function payBalanceRecordDetail(id) {
      return request({
        url: "/pay/balanceRecord/".concat(id),
        method: 'get'
      });
    }
  };
});