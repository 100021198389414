export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeDriverEvalQuery: function logisticHongdeDriverEvalQuery(data) {
      return request({
        url: '/logistics_hongde/driverEval/query',
        method: 'get',
        params: data
      });
    },
    logisticHongdeDriverEvalAdd: function logisticHongdeDriverEvalAdd(data) {
      return request({
        url: '/logistics_hongde/driverEval/addByAdmin',
        method: 'post',
        data: data
      });
    },
    logisticHongdeDriverEvalDel: function logisticHongdeDriverEvalDel(id) {
      return request({
        url: "/logistics_hongde/driverEval/".concat(id),
        method: 'delete'
      });
    }
  };
});