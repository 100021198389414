import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    mngUserAdd: function mngUserAdd(data) {
      return request({
        url: '/mng/user',
        method: 'post',
        data: data
      });
    },
    mngUserAddHasRoles: function mngUserAddHasRoles(data) {
      return request({
        url: '/mng/user/hasRoles',
        method: 'post',
        data: data
      });
    },
    mngUserModify: function mngUserModify(data) {
      return request({
        url: "/mng/user/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    mngUserModifyHasRoles: function mngUserModifyHasRoles(data) {
      return request({
        url: "/mng/user/hasRoles/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    mngUserDisable: function mngUserDisable(id) {
      return request({
        url: "/mng/user/".concat(id, "/disable"),
        method: 'post'
      });
    },
    mngUserUnDisable: function mngUserUnDisable(id) {
      return request({
        url: "/mng/user/".concat(id, "/unDisable"),
        method: 'post'
      });
    },
    mngUserUnLock: function mngUserUnLock(id) {
      return request({
        url: "/mng/user/".concat(id, "/unLock"),
        method: 'post'
      });
    },
    mngUserPassword: function mngUserPassword(data) {
      return request({
        url: '/mng/user/password',
        method: 'post',
        data: data
      });
    },
    mngUserResetPassword: function mngUserResetPassword(id) {
      return request({
        url: "/mng/user/".concat(id, "/resetPassword"),
        method: 'post'
      });
    },
    mngUserClearAuth: function mngUserClearAuth(id) {
      return request({
        url: "/mng/user/".concat(id, "/clearAuthAndDel"),
        method: 'delete'
      });
    },
    mngUserQuery: function mngUserQuery(data) {
      return request({
        url: '/mng/user/query',
        method: 'get',
        params: data
      });
    },
    mngUserQueryDoNotHaveAdmin: function mngUserQueryDoNotHaveAdmin(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      paramData.category = paramData.category ? paramData.category : 'not_admin';
      return request({
        url: '/mng/user/query',
        method: 'get',
        params: paramData
      });
    },
    mngUserQueryJoinIntroduce: function mngUserQueryJoinIntroduce(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      paramData.category = paramData.category ? paramData.category : 'not_admin';
      return request({
        url: '/mng/user/queryJoinIntroduce',
        method: 'get',
        params: paramData
      });
    },
    mngUserDetail: function mngUserDetail(id) {
      return request({
        url: "/mng/user/".concat(id),
        method: 'get'
      });
    },
    mngUserDetailHasRole: function mngUserDetailHasRole(id, hasRole) {
      return request({
        url: "/mng/user/".concat(id, "?hasRole=").concat(hasRole),
        method: 'get'
      });
    },
    mngUserAddBalance: function mngUserAddBalance(id, balance, content) {
      return request({
        url: "/mng/user/".concat(id, "/addBalance?balance=").concat(balance, "&content=").concat(content),
        method: 'post'
      });
    },
    mngUserSubBalance: function mngUserSubBalance(id, balance, content) {
      return request({
        url: "/mng/user/".concat(id, "/subBalance?balance=").concat(balance, "&content=").concat(content),
        method: 'post'
      });
    },
    wxWebLogin: function wxWebLogin(category, code) {
      return request({
        url: "/mng/user/login/web/".concat(category, "/").concat(code),
        method: 'post'
      });
    }
  };
});