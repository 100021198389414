import "core-js/modules/es.math.trunc.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v("运费")]), _c("div", {
    staticStyle: {
      color: "darkgreen",
      "line-height": "40px"
    }
  }, [_vm._v("合计：" + _vm._s(!_vm.totalFeeFormData ? 0 : Math.round((_vm.totalFeeFormData.freightTotalCost || 0) * 100) / 100) + " 元")])]), _c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v("服务费")]), _c("div", {
    staticStyle: {
      color: "darkgreen",
      "line-height": "40px"
    }
  }, [_vm._v("合计：" + _vm._s(!_vm.totalFeeFormData ? 0 : Math.round(((_vm.totalFeeFormData.urgentServiceFeeCost || 0) + (_vm.totalFeeFormData.freightServiceFeeCost || 0)) * 100) / 100) + " 元")])])], 1), _c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: " 提单号"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "搜索提单号"
    },
    model: {
      value: _vm.searchForm.waybillExtractIdentity,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "waybillExtractIdentity", $$v);
      },
      expression: "searchForm.waybillExtractIdentity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "　派单方"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "搜索派单方名称"
    },
    model: {
      value: _vm.searchForm.merchantNickname,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "merchantNickname", $$v);
      },
      expression: "searchForm.merchantNickname"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "联系电话"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "派单方联系电话"
    },
    model: {
      value: _vm.searchForm.merchantContactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "merchantContactPhone", $$v);
      },
      expression: "searchForm.merchantContactPhone"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "运单地址"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "运单地址"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("br"), _c("el-form-item", {
    attrs: {
      label: "司机姓名"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "司机姓名"
    },
    model: {
      value: _vm.searchForm.driverName,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "driverName", $$v);
      },
      expression: "searchForm.driverName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "司机电话"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "司机电话"
    },
    model: {
      value: _vm.searchForm.driverContactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "driverContactPhone", $$v);
      },
      expression: "searchForm.driverContactPhone"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "搜索车队"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "车队名称"
    },
    model: {
      value: _vm.searchForm.carTeamName,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "carTeamName", $$v);
      },
      expression: "searchForm.carTeamName"
    }
  })], 1), _c("br"), _c("el-form-item", {
    attrs: {
      label: "开始时间"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      type: "date",
      placeholder: "选择开始时间",
      "value-format": "yyyyMMddHHmmss"
    },
    model: {
      value: _vm.searchForm.starDate,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "starDate", $$v);
      },
      expression: "searchForm.starDate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "结束时间"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      type: "date",
      placeholder: "选择结束时间",
      "value-format": "yyyyMMddHHmmss"
    },
    model: {
      value: _vm.searchForm.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "endDate", $$v);
      },
      expression: "searchForm.endDate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "配送形式"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.waybillDeliveryMode,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "waybillDeliveryMode", $$v);
      },
      expression: "searchForm.waybillDeliveryMode"
    }
  }, [_c("el-option", {
    attrs: {
      label: "单背",
      value: "Single"
    }
  }), _c("el-option", {
    attrs: {
      label: "双背",
      value: "Double"
    }
  }), _c("el-option", {
    attrs: {
      label: "多背",
      value: "More"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "运单状态"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "运单状态",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.orderState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "orderState", $$v);
      },
      expression: "searchForm.orderState"
    }
  }, _vm._l(_vm.orderState, function (item) {
    return _c("el-option", {
      key: item.vlaue,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.exportData
    }
  }, [_c("i", {
    staticClass: "fa el-icon-download"
  }), _vm._v(" 导出")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "waybillExtractIdentity",
      label: "提单号",
      "show-overflow-tooltip": "",
      width: "170",
      fixed: ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "105",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.rowDetail(scope.row);
            }
          }
        }, [_vm._v("查看详情")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderState",
      label: "状态",
      sortable: "custom",
      width: "130",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.orderState[scope.row.orderState].label))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "driverName",
      label: "司机姓名",
      "show-overflow-tooltip": "",
      width: "90",
      fixed: ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "driverContactPhone",
      label: "司机电话",
      "show-overflow-tooltip": "",
      width: "110",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.driverContactPhone || ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "merchantNickname",
      label: "派单方",
      "show-overflow-tooltip": "",
      width: "130"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "merchantContactPhone",
      label: "派单方电话",
      "show-overflow-tooltip": "",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.merchantContactPhone || ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "driverCarNumber",
      label: "司机车牌号",
      "show-overflow-tooltip": "",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.driverCarNumber || ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "carTeamName",
      label: "所属车队",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.carTeamName))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "waybillDeliveryMode",
      label: "形式",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "75"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.waybillDeliveryMode == "Double" ? _c("div", {
          staticClass: "text-suggest"
        }, [_vm._v("双背")]) : _vm._e(), scope.row.waybillDeliveryMode == "Single" ? _c("div", {
          staticClass: "text-style"
        }, [_vm._v("单背")]) : _vm._e(), scope.row.waybillDeliveryMode == "More" ? _c("div", [_vm._v("多背")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "freightCost",
      label: "订单总费用",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.waybillDeliveryEstimateTotalCost || 0) + " 元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "freightCost",
      label: "运费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.freightDriverCost || 0) + " 元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "urgentCost",
      label: "加急费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.urgentCost || 0) + " 元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "urgentCost",
      label: "总垫付费用",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s((scope.row.firstPlaceFee || 0) * 1 + (scope.row.secondPlaceFee || 0) * 1 + (scope.row.thirdPlaceFee || 0) * 1) + " 元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "serviceFeeCost",
      label: "总服务费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(Math.round(((scope.row.freightServiceFeeCost || 0) + (scope.row.urgentServiceFeeCost || 0)) * 100) / 100) + " 元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "cradleLbs",
      label: "提箱地",
      "show-overflow-tooltip": "",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.waybillFirstPlaceCity) + " " + _vm._s(scope.row.waybillFirstPlaceCity) + " " + _vm._s(scope.row.waybillFirstPlaceDistrict) + " " + _vm._s(scope.row.waybillFirstPlaceDetail))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "assembleLbs",
      label: "送货/装货地",
      "show-overflow-tooltip": "",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.waybillSecondPlaceProvince) + " " + _vm._s(scope.row.waybillSecondPlaceCity) + " " + _vm._s(scope.row.waybillSecondPlaceDistrict) + " " + _vm._s(scope.row.waybillSecondPlaceDetail))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "purposeLbs",
      label: "收箱地",
      "show-overflow-tooltip": "",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.waybillIncludeThirdPlace == "Yes" ? _c("div", [_vm._v(_vm._s(scope.row.waybillThirdPlaceProvince) + " " + _vm._s(scope.row.waybillThirdPlaceCity) + " " + _vm._s(scope.row.waybillThirdPlaceDistrict) + " " + _vm._s(scope.row.waybillThirdPlaceDetail))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "接单时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.createTime ? _vm.dayjs(scope.row.createTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "merchantConfirmTime",
      label: "完成时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.merchantConfirmTime ? _vm.dateFormat2Str(scope.row.merchantConfirmTime) : ""))])];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.detailDialogShow,
      title: "运单详情",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.detailDialogShow = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: _vm.waybillDetailData.waybillIncludeThirdPlace == "Yes" ? 8 : 12
    }
  }, [_c("el-row", {
    staticStyle: {
      color: "dodgerblue",
      "font-weight": "bold"
    }
  }, [_vm._v("提箱地")]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-weight": "bold",
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.firstPlaceDetail || ""))]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.firstPlaceProvince) + " " + _vm._s(_vm.waybillDetailData.firstPlaceCity) + " " + _vm._s(_vm.waybillDetailData.firstPlaceDistrict))]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v("提箱有效时间段:")]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.firstPlaceBeginTime / 100))) + " - " + _vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.firstPlaceEndTime / 100))))])], 1), _c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: _vm.waybillDetailData.waybillIncludeThirdPlace == "Yes" ? 8 : 12
    }
  }, [_c("el-row", {
    staticStyle: {
      color: "darkorange",
      "font-weight": "bold"
    }
  }, [_vm._v("送货/装货地")]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-weight": "bold",
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.secondPlaceDetail || ""))]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.secondPlaceProvince) + " " + _vm._s(_vm.waybillDetailData.secondPlaceCity) + " " + _vm._s(_vm.waybillDetailData.secondPlaceDistrict))]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v("送货/装货货有效时间段:")]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.secondPlaceBeginTime / 100))) + " - " + _vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.secondPlaceEndTime / 100))))])], 1), _vm.waybillDetailData.waybillIncludeThirdPlace == "Yes" ? _c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: 8
    }
  }, [_c("el-row", {
    staticStyle: {
      color: "forestgreen",
      "font-weight": "bold"
    }
  }, [_vm._v("收箱地")]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-weight": "bold",
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.thirdPlaceDetail || ""))]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.thirdPlaceProvince) + " " + _vm._s(_vm.waybillDetailData.thirdPlaceCity) + " " + _vm._s(_vm.waybillDetailData.thirdPlaceDistrict))]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v("到收箱地有效时间段:")]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.thirdPlaceBeginTime / 100))) + " - " + _vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.thirdPlaceEndTime / 100))))])], 1) : _vm._e()], 1), _c("el-row", {
    attrs: {
      gutter: 2
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", {
    staticClass: "box-card basicDiv"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("接单司机")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("司机姓名：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.driverName || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("司机电话：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.driverContactPhone || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("车牌号：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.driverCarNumber || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("接单时间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.createTime ? _vm.dayjs(_vm.waybillOrderDetailData.createTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])], 1)], 1), _c("el-card", {
    staticClass: "box-card basicDiv"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("基本信息")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("派单方电话：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.merchantContactPhone || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("船公司：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.shipCompanyId || "") + " " + _vm._s(_vm.waybillDetailData.shipCompanyName || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提示：")]), _c("el-col", {
    staticClass: "text-lose",
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.shipRemark || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提（运）单号：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.waybillExtractIdentity || ""))])], 1)], 1), _c("el-card", {
    staticClass: "box-card deliveryDiv",
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("配送要求")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("车况要求：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.carConditionRequire || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("配送要求：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.deliveryRequire || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("备注：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.remark || ""))])], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm.waybillOrderDetailData.containerSealNums ? _c("el-card", {
    staticClass: "box-card basicDiv"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("箱号铅封")])]), _vm._l(JSON.parse(_vm.waybillOrderDetailData.containerSealNums), function (item, index) {
    return _c("div", {
      key: index
    }, [_c("el-row", {
      attrs: {
        gutter: 5
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_vm._v("箱" + _vm._s(index + 1) + "·箱号：")]), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_vm._v(_vm._s(item.identity || ""))])], 1), item.sealIdentity ? _c("el-row", {
      attrs: {
        gutter: 5
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_vm._v("箱" + _vm._s(index + 1) + "·铅封号：")]), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_vm._v(_vm._s(item.sealIdentity || ""))])], 1) : _vm._e()], 1);
  })], 2) : _vm._e(), _c("el-card", {
    staticClass: "box-card containerDiv"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("集装箱信息")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("尺寸：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dicData.configSizeOptions[_vm.waybillDetailData.containerSize] || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("箱型：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dicData.configShapeOptions[_vm.waybillDetailData.containerShape] || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("空/重箱：")]), _vm.waybillDetailData.containerEmptyOrFull == "Empty" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("空箱")]) : _vm._e(), _vm.waybillDetailData.containerEmptyOrFull == "Full" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("重箱")]) : _vm._e()], 1), _vm.waybillDetailData.containerWeight ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("箱重区间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.containerWeight || ""))])], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("配送形式：")]), _vm.waybillDetailData.deliveryMode == "Single" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("单背")]) : _vm._e(), _vm.waybillDetailData.deliveryMode == "Double" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("双背")]) : _vm._e(), _vm.waybillDetailData.deliveryMode == "More" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("多背")]) : _vm._e()], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("集装箱数量：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.containerTotalAmount || ""))])], 1)], 1), _c("el-card", {
    staticClass: "box-card costDiv",
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("费用信息")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("每车运费：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.deliveryOnceCost || 0) + " 元/辆")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("服务费比例：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s((_vm.waybillOrderDetailData.serviceFeeScale || 0) * 100) + " %")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("发票：")]), _vm.waybillDetailData.provideInvoice == "Yes" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提供")]) : _vm._e(), _vm.waybillDetailData.provideInvoice == "No" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("不提供")]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };