export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeCarTeamAdd: function logisticHongdeCarTeamAdd(data) {
      return request({
        url: '/logistics_hongde/carTeam',
        method: 'post',
        data: data
      });
    },
    logisticHongdeCarTeamModify: function logisticHongdeCarTeamModify(data) {
      return request({
        url: "/logistics_hongde/carTeam/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    logisticHongdeCarTeamDel: function logisticHongdeCarTeamDel(id) {
      return request({
        url: "/logistics_hongde/carTeam/".concat(id),
        method: 'delete'
      });
    },
    logisticHongdeCarTeamQuery: function logisticHongdeCarTeamQuery(data) {
      return request({
        url: '/logistics_hongde/carTeam/queryJoinPersonAmount',
        method: 'get',
        params: data
      });
    },
    logisticHongdeCarTeamQueryAll: function logisticHongdeCarTeamQueryAll() {
      return request({
        url: '/logistics_hongde/carTeam/queryAll',
        method: 'get'
      });
    },
    logisticHongdeCarTeamDetail: function logisticHongdeCarTeamDetail(id) {
      return request({
        url: "/logistics_hongde/carTeam/".concat(id),
        method: 'get'
      });
    }
  };
});