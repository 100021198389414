import { render, staticRenderFns } from "./edu_liyou_author_page.vue?vue&type=template&id=ee594686&scoped=true"
import script from "./edu_liyou_author_page.vue?vue&type=script&lang=js"
export * from "./edu_liyou_author_page.vue?vue&type=script&lang=js"
import style0 from "./edu_liyou_author_page.vue?vue&type=style&index=0&id=ee594686&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee594686",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/riverlei/project/java/bgyy-project/front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ee594686')) {
      api.createRecord('ee594686', component.options)
    } else {
      api.reload('ee594686', component.options)
    }
    module.hot.accept("./edu_liyou_author_page.vue?vue&type=template&id=ee594686&scoped=true", function () {
      api.rerender('ee594686', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/edu_liyou/edu_liyou_author_page.vue"
export default component.exports