export default (function (_ref) {
  var request = _ref.request;
  return {
    eduLiyouCouponsUserQueryJoinUser: function eduLiyouCouponsUserQueryJoinUser(data) {
      return request({
        url: '/education_liyou/couponsUser/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    eduLiyouCouponsUserQueryJoinUserAndCourse: function eduLiyouCouponsUserQueryJoinUserAndCourse(data) {
      return request({
        url: '/education_liyou/couponsUser/queryJoinUserAndCourse',
        method: 'get',
        params: data
      });
    }
  };
});