import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.math.trunc.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v("合计·运费")]), _c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "darkgreen"
    }
  }, [_vm._v("当前运费：" + _vm._s(!_vm.totalFeeFormData ? 0 : Math.round((_vm.totalFeeFormData.orderValidFreightDriverCost - _vm.totalFeeFormData.orderMerchantConfirmFreightDriverCost) * 100) / 100 || 0) + " 元")])]), _c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "darkblue"
    }
  }, [_vm._v("预计运费：" + _vm._s(!_vm.totalFeeFormData ? 0 : _vm.totalFeeFormData.deliveryEstimateTotalCost || 0) + " 元")])])]), _c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v("合计·服务费")]), _c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "darkgreen"
    }
  }, [_vm._v("已收服务费：" + _vm._s(!_vm.totalFeeFormData ? 0 : Math.round((_vm.totalFeeFormData.orderMerchantConfirmFreightServiceFeeCost + _vm.totalFeeFormData.orderMerchantConfirmFreightServiceFeeManualCost) * 100) / 100 || 0) + " 元")])]), _c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticStyle: {
      color: "darkblue"
    }
  }, [_vm._v("应收服务费：" + _vm._s(!_vm.totalFeeFormData ? 0 : Math.round((_vm.totalFeeFormData.orderValidFreightServiceFeeCost + _vm.totalFeeFormData.orderValidFreightServiceFeeManualCost) * 100) / 100 || 0) + " 元")])])])], 1), _c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: " 提单号"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "提单号"
    },
    model: {
      value: _vm.searchForm.extractIdentity,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "extractIdentity", $$v);
      },
      expression: "searchForm.extractIdentity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "　派单方"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "派单方"
    },
    model: {
      value: _vm.searchForm.merchantNickname,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "merchantNickname", $$v);
      },
      expression: "searchForm.merchantNickname"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "联系电话"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "派单方联系电话"
    },
    model: {
      value: _vm.searchForm.merchantContactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "merchantContactPhone", $$v);
      },
      expression: "searchForm.merchantContactPhone"
    }
  })], 1), _c("br"), _c("el-form-item", {
    attrs: {
      label: "订单编号"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "订单编号"
    },
    model: {
      value: _vm.searchForm.id,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "id", $$v);
      },
      expression: "searchForm.id"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "订单地址"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "订单地址"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "订单状态"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "订单状态",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.waybillState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "waybillState", $$v);
      },
      expression: "searchForm.waybillState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "待接单中",
      value: "1"
    }
  }), _c("el-option", {
    attrs: {
      label: "接单中",
      value: "2"
    }
  }), _c("el-option", {
    attrs: {
      label: "全部接单结束",
      value: "3"
    }
  }), _c("el-option", {
    attrs: {
      label: "运单均完成",
      value: "4"
    }
  }), _c("el-option", {
    attrs: {
      label: "已结案",
      value: "5"
    }
  })], 1)], 1), _c("br"), _c("el-form-item", {
    attrs: {
      label: "配送形式"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.deliveryMode,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "deliveryMode", $$v);
      },
      expression: "searchForm.deliveryMode"
    }
  }, [_c("el-option", {
    attrs: {
      label: "单背",
      value: "Single"
    }
  }), _c("el-option", {
    attrs: {
      label: "双背",
      value: "Double"
    }
  }), _c("el-option", {
    attrs: {
      label: "多背",
      value: "More"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "开始时间"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      type: "date",
      placeholder: "选择发布开始时间",
      "value-format": "yyyyMMdd"
    },
    model: {
      value: _vm.searchForm.starDate,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "starDate", $$v);
      },
      expression: "searchForm.starDate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "结束时间"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      type: "date",
      placeholder: "选择发布结束时间",
      "value-format": "yyyyMMdd"
    },
    model: {
      value: _vm.searchForm.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "endDate", $$v);
      },
      expression: "searchForm.endDate"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.exportData
    }
  }, [_c("i", {
    staticClass: "fa el-icon-download"
  }), _vm._v(" 导出")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "extractIdentity",
      label: "提单号",
      "show-overflow-tooltip": "",
      width: "170",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.category == "mng_user_category_hd_merchant" ? _c("el-tag", [_vm._v("集卡")]) : _vm._e(), scope.row.category == "mng_user_category_hd_motorcade" ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("车队")]) : _vm._e(), _vm._v(" " + _vm._s(scope.row.extractIdentity) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "170",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.detail(scope.row);
            }
          }
        }, [_vm._v("详情")]), _c("el-button", {
          attrs: {
            size: "mini",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.waybillGo(scope.row);
            }
          }
        }, [_vm._v("运单列表")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "waybillState",
      label: "订单状态",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "180",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row.onlineState ? _c("div", {
          staticClass: "state"
        }, [_vm._v("已下架")]) : scope.row.waybillState == 1 ? _c("div", {
          staticClass: "state"
        }, [_vm._v("待接单中")]) : scope.row.waybillState == 2 ? _c("div", {
          staticClass: "state"
        }, [_vm._v("接单中")]) : scope.row.waybillState == 3 ? _c("div", {
          staticClass: "state"
        }, [_vm._v("全部接单结束")]) : scope.row.waybillState == 4 ? _c("div", {
          staticClass: "state"
        }, [_vm._v("运单均完成")]) : scope.row.waybillState == 5 ? _c("div", {
          staticClass: "state"
        }, [_vm._v("已结案")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "merchantNickname",
      label: "派单方",
      "show-overflow-tooltip": "",
      width: "150",
      fixed: ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "merchantContactPhone",
      label: "派单方电话",
      "show-overflow-tooltip": "",
      width: "110",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.merchantContactPhone || ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "waybillOrder/queryForAdmin",
      label: "形式",
      "show-overflow-tooltip": "",
      width: "50"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.deliveryMode == "Single" ? _c("div", {
          staticClass: "text-suggest"
        }, [_vm._v("单背")]) : _vm._e(), scope.row.deliveryMode == "Double" ? _c("div", {
          staticClass: "text-suggest"
        }, [_vm._v("双背")]) : _vm._e(), scope.row.deliveryMode == "More" ? _c("div", {
          staticClass: "text-suggest"
        }, [_vm._v("多背")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orientation",
      label: "箱门朝向",
      "show-overflow-tooltip": "",
      width: "110",
      fixed: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.orientation || ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "containerTotalAmount",
      label: "总箱量",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "orderValidAmount",
      label: "已抢运单数",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "orderValidContainerAmount",
      label: "已抢单箱量",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "orderMerchantConfirmContainerAmount",
      label: "已结案箱量",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "deliveryOnceCost",
      label: "每车运费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.deliveryOnceCost || 0) + " 元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "deliveryEstimateTotalCost",
      label: "订单总运费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.deliveryEstimateTotalCost || 0) + " 元 ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderValidFreightDriverCost",
      label: "已抢单总运费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.deliveryOnceCost * scope.row.orderValidAmount || 0) + " 元 ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderMerchantConfirmFreightTotalCost",
      label: "已结案总运费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.deliveryOnceCost * scope.row.orderMerchantConfirmAmount || 0) + " 元 ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "urgentDeliveryOnceCost",
      label: "加急费总额",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.deliveryEstimateTotalCost / scope.row.deliveryOnceCost * scope.row.urgentDeliveryOnceCost) + " 元 ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderValidUrgentCost",
      label: "已抢单加急费总额",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.urgentDeliveryOnceCost * scope.row.orderAmount || 0) + " 元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderMerchantConfirmUrgentServiceFeeCost",
      label: "已结案加急费总额",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.urgentDeliveryOnceCost * scope.row.orderMerchantConfirmAmount || 0) + " 元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderPlaceFee",
      label: "总垫付费用",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.orderPlaceFee || 0) + " 元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "",
      label: "总服务费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return undefined;
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "",
      label: "已抢单总服务费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.orderValidFreightServiceFeeCost + scope.row.orderValidUrgentServiceFeeCost) + " 元 ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "",
      label: "已结案总服务费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.orderMerchantConfirmFreightServiceFeeCost + scope.row.orderMerchantConfirmUrgentServiceFeeCost) + " 元 ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "发布时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.createTime ? _vm.dayjs(scope.row.createTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "cradleLbs",
      label: "提箱地",
      "show-overflow-tooltip": "",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s("".concat(scope.row.firstPlaceProvince, " ").concat(scope.row.firstPlaceCity, " ").concat(scope.row.firstPlaceDistrict, " ").concat(scope.row.firstPlaceDetail)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "assembleLbs",
      label: "送货/装货地",
      "show-overflow-tooltip": "",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s("".concat(scope.row.secondPlaceProvince, " ").concat(scope.row.secondPlaceCity, " ").concat(scope.row.secondPlaceDistrict, " ").concat(scope.row.secondPlaceDetail)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "purposeLbs",
      label: "收箱地",
      "show-overflow-tooltip": "",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s("".concat(scope.row.thirdPlaceProvince, " ").concat(scope.row.thirdPlaceCity, " ").concat(scope.row.thirdPlaceDistrict, " ").concat(scope.row.thirdPlaceDetail)))])];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.detailDialogShow,
      title: "订单详情",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.detailDialogShow = $event;
      }
    }
  }, [_c("div", {
    staticClass: "addressDiv"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: _vm.detailData.hasDestination ? 8 : 12
    }
  }, [_c("el-row", {
    staticStyle: {
      color: "dodgerblue",
      "font-weight": "bold"
    }
  }, [_vm._v("提箱地")]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-weight": "bold",
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.detailData.firstPlaceDetail || ""))]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s("".concat(_vm.detailData.firstPlaceProvince, " ").concat(_vm.detailData.firstPlaceCity, " ").concat(_vm.detailData.firstPlaceDistrict)))]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v("提箱有效时间段:")]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.detailData.firstPlaceBeginTime / 100))) + " - " + _vm._s(_vm.dateFormat2Str(Math.trunc(_vm.detailData.firstPlaceEndTime / 100))))])], 1), _c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: _vm.detailData.hasDestination ? 8 : 12
    }
  }, [_c("el-row", {
    staticStyle: {
      color: "darkorange",
      "font-weight": "bold"
    }
  }, [_vm._v("送货/装货地")]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-weight": "bold",
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.detailData.secondPlaceDetail || ""))]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s("".concat(_vm.detailData.secondPlaceProvince, " ").concat(_vm.detailData.secondPlaceCity, " ").concat(_vm.detailData.secondPlaceDistrict)))]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v("送货/装货有效时间段:")]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.detailData.secondPlaceBeginTime / 100))) + " - " + _vm._s(_vm.dateFormat2Str(Math.trunc(_vm.detailData.secondPlaceEndTime / 100))))])], 1), _vm.detailData.hasDestination ? _c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: 8
    }
  }, [_c("el-row", {
    staticStyle: {
      color: "forestgreen",
      "font-weight": "bold"
    }
  }, [_vm._v("收箱地")]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-weight": "bold",
      "font-size": "18px"
    }
  }, [_vm._v(_vm._s(_vm.detailData.thirdPlaceDetail || ""))]), _c("el-row", {
    staticStyle: {
      color: "black",
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s("".concat(_vm.detailData.thirdPlaceProvince, " ").concat(_vm.detailData.thirdPlaceCity, " ").concat(_vm.detailData.thirdPlaceDistrict)))]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v("到收箱地有效时间段:")]), _c("el-row", {
    staticStyle: {
      color: "dimgrey",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.detailData.thirdPlaceBeginTime / 100))) + " - " + _vm._s(_vm.dateFormat2Str(Math.trunc(_vm.detailData.thirdPlaceEndTime / 100))))])], 1) : _vm._e()], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 2
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", {
    staticClass: "box-card basicDiv"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("基本信息")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("派单方：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.merchantNickname || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("联系方式：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.merchantContactPhone || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("船公司：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.shipCompanyId || "") + " " + _vm._s(_vm.detailData.shipCompanyName || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提示：")]), _c("el-col", {
    staticClass: "text-lose",
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.shipRemark || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提（运）单号：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.extractIdentity || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("类型：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.importAndExport == "Include" ? "进出口运输" : _vm.detailData.importAndExport == "None" ? "无进出口运输" : "") + "，" + _vm._s(_vm.detailData.goodsLoadingAndUnloading == "Include" ? "有卸/装货要求" : _vm.detailData.goodsLoadingAndUnloading == "None" ? "无卸/装货要求" : ""))])], 1), _vm.detailData.importAndExport == "Include" ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("进出口类别：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.importAndExportCategory[_vm.detailData.importAndExportCategory]))])], 1) : _vm._e()], 1), _c("el-card", {
    staticClass: "box-card deliveryDiv",
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("配送要求")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("车队要求：")]), _vm.detailData.waybillCarTeams ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, _vm._l(_vm.detailData.waybillCarTeams, function (item) {
    return _c("div", {
      key: item.id
    }, [_vm._v(_vm._s(item.carTeamName) + "， ")]);
  }), 0) : _vm._e()], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("车辆要求：")]), _vm.detailData.waybillCars ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, _vm._l(_vm.detailData.waybillCars, function (item) {
    return _c("div", {
      key: item.id
    }, [_vm._v(_vm._s(item.carNumber) + "， ")]);
  }), 0) : _vm._e()], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("车况要求：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.carConditionDemand || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("配送要求：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.carDeliveryDemand || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("备注：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.remark || ""))])], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", {
    staticClass: "box-card containerDiv"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("集装箱信息")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("尺寸：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dicData.configSizeOptions[_vm.detailData.containerSize] || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("箱型：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dicData.configShapeOptions[_vm.detailData.containerShape] || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("空/重箱：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.containerEmptyOrFull == "Full" ? "重箱" : "空箱"))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("箱重：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.goodsWeightMin || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("配送形式：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.deliveryMode == "Double" ? "双背" : _vm.detailData.deliveryMode == "Single" ? "单背" : "More"))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("总箱量：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(" " + _vm._s(_vm.detailData.containerTotalAmount || 0) + " ")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("箱门朝向：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(" " + _vm._s(_vm.dicData.orientationOptions[_vm.detailData.orientation] || "") + " ")])], 1)], 1), _c("el-card", {
    staticClass: "box-card costDiv",
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("费用信息")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("每车运费：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.deliveryOnceCost || 0) + " 元/辆")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("运费总额：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.deliveryEstimateTotalCost || 0) + " 元")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("已抢单服务费：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.orderValidFreightServiceFeeCost + _vm.detailData.orderValidUrgentServiceFeeCost || 0) + " 元")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("发票：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.detailData.provideInvoice == "Yes" ? "提供" : "不提供"))])], 1)], 1)], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };