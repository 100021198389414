export default (function (_ref) {
  var request = _ref.request;
  return {
    refundOrderQuery: function refundOrderQuery(data) {
      return request({
        url: '/pay/refundOrder/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    refundOrderDetail: function refundOrderDetail(id) {
      return request({
        url: "/pay/refundOrder/".concat(id),
        method: 'get'
      });
    },
    refundOrderAgainSendNotice: function refundOrderAgainSendNotice(id) {
      return request({
        url: "/pay/refundOrder/".concat(id, "/againSendNotice"),
        method: 'post'
      });
    }
  };
});