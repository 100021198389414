export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeDriverLogQueryJoinUser: function logisticHongdeDriverLogQueryJoinUser(data) {
      return request({
        url: '/logistics_hongde/driverLog/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    logisticHongdeDriverLogDetail: function logisticHongdeDriverLogDetail(id) {
      return request({
        url: "/logistics_hongde/driverLog/".concat(id),
        method: 'get'
      });
    }
  };
});