import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import dayjs from 'dayjs';
export default {
  name: 'logistics_waybill_order_page',
  props: {
    currentWaybill: {
      type: Object
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '订单',
        modifyFunName: 'logisticHongdeWaybillOrderModify',
        queryFunName: 'logisticHongdeWaybillOrderQuery',
        detailOrderFunName: 'logisticHongdeWaybillOrderDetail',
        waybillOrderConfirmFunName: 'logisticHongdeWaybillOrderConfirm',
        detailFunName: 'logisticHongdeWaybillDetail',
        dicTreeFunName: 'mngDictionaryLowerTree',
        shipCompanyDetailFunName: 'logisticHongdeShipCompanyDetail',
        driverDetailFunName: 'logisticHongdeDriverDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time',
        waybillId: this.currentWaybill.id,
        waybillMerchantNickname: null,
        driverContactPhone: null,
        waybillDeliveryMode: null,
        starDate: null,
        endDate: null,
        orderState: null
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      detailDialogShow: false,
      formLabelWidth: '100px',
      waybillDetailData: {
        id: null,
        cradleAddress: null,
        cradleLbs: null,
        cradleBeginTime: null,
        cradleLastTime: null,
        assembleAddress: null,
        assembleLbs: null,
        assembleBeginTime: null,
        assembleLastTime: null,
        purposeAddress: null,
        purposeLbs: null,
        purposeBeginTime: null,
        purposeLastTime: null,
        shipCompanyId: null,
        specifyCarTeamIds: null
      },
      waybillOrderDetailData: {
        driverName: null,
        driverContactPhone: null,
        driverCarNumber: null,
        firstContainerIdentity: null,
        firstContainerSealIdentity: null,
        secondContainerIdentity: null,
        secondContainerSealIdentity: null,
        waybillContactPhone: null,
        waybillContainerSize: null,
        waybillContainerShape: null,
        waybillContainerFullRequire: null,
        waybillContainerWeightRange: null,
        waybillDeliveryMode: null
      },
      dicData: {
        sizeUpperCode: 'logistics_hd_container_size',
        configSizes: [],
        configSizeOptions: {},
        shapeUpperCode: 'logistics_hd_container_shape',
        configShapes: [],
        configShapeOptions: {},
        orientationCode: 'logistics_hd_orientation',
        orientationList: [],
        orientationOptions: {}
      },
      driverDetailData: {
        carNumber: null,
        name: null,
        contactPhone: null
      },
      now_time: '',
      orderState: {
        Wait: {
          label: '待接单',
          value: 'Wait'
        },
        Accept: {
          label: '已接单',
          value: 'Accept'
        },
        First_Place_Confirm: {
          label: '提箱确认',
          value: 'First_Place_Confirm'
        },
        Second_Place_Arrive: {
          label: '抵达卸货地',
          value: 'Second_Place_Arrive'
        },
        Second_Place_Confirm: {
          label: '卸货/送货确认',
          value: 'Second_Place_Confirm'
        },
        Third_Place_Confirm: {
          label: '收箱确认',
          value: 'Third_Place_Confirm'
        },
        Merchant_Confirm: {
          label: '已结案',
          value: 'Merchant_Confirm'
        },
        Undo: {
          label: '已撤单',
          value: 'Undo'
        },
        Refuse: {
          label: '转单拒绝',
          value: 'Refuse'
        },
        back_address: {
          label: '返箱回提箱地确认',
          value: 'back_address'
        },
        driver_finish: {
          label: '返箱回提箱地完成',
          value: 'driver_finish'
        }
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getDetail();
    Promise.all([new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.sizeUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configSizes = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configSizeOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.orientationCode).then(function (res) {
        if (res.data) {
          _this.dicData.orientationList = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.orientationOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.shapeUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configShapes = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configShapeOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    })]).then(function (result) {
      _this.query();
    });
  },
  methods: {
    back: function back() {
      this.$emit('child-go-waybill');
    },
    getNowTime: function getNowTime() {
      // 获取现在时间
      var now = new Date();
      var year = now.getFullYear(); //获取完整的年份(4位,1970-????)
      var month = now.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      var today = now.getDate(); //获取当前日(1-31)
      var hour = now.getHours(); //获取当前小时数(0-23)
      var minute = now.getMinutes(); //获取当前分钟数(0-59)
      var second = now.getSeconds(); //获取当前秒数(0-59)
      this.now_time = year + this.fillZero(month) + this.fillZero(today) + this.fillZero(hour) + this.fillZero(minute) + this.fillZero(second);
    },
    fillZero: function fillZero(str) {
      var realNum;
      if (str < 10) {
        realNum = '0' + str;
      } else {
        realNum = str;
      }
      return realNum;
    },
    feeConfirm: function feeConfirm(id) {
      var _this2 = this;
      this.$confirm('确定服务费已收取吗').then(function () {
        _this2.$api[_this2.pageConfig.waybillOrderConfirmFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('收取成功');
            _this2.query();
          } else {
            _this2.$message.error('收取失败');
          }
        });
      });
    },
    rowDetail: function rowDetail(data) {
      var _this3 = this;
      this.waybillOrderDetailData = data;
      console.log(this.waybillOrderDetailData, "this.waybillOrderDetailDatathis.waybillOrderDetailDatathis.waybillOrderDetailData");
      // 司机
      if (data.driverId) {
        this.$api[this.pageConfig.driverDetailFunName](data.driverId).then(function (driverRes) {
          _this3.driverDetailData = driverRes.data;
        });
      }
      this.detailDialogShow = true;
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        console.log(_this4.tableData, "tableDatatableData");
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    getDetail: function getDetail() {
      var _this5 = this;
      this.$api[this.pageConfig.detailFunName](this.currentWaybill.id).then(function (res) {
        _this5.waybillDetailData = res.data;
        _this5.waybillDetailData.serviceFeeChargeCost = res.serviceFeeChargeCost || 0;
        _this5.waybillDetailData.serviceFeeTotalCost = res.serviceFeeTotalCost || 0;
        _this5.queryLoading = true;

        // 船公司
        if (_this5.waybillDetailData.shipCompanyId) {
          _this5.$api[_this5.pageConfig.shipCompanyDetailFunName](_this5.waybillDetailData.shipCompanyId).then(function (shipCompanyRes) {
            _this5.$set(_this5.waybillDetailData, 'shipCompanyId', shipCompanyRes.data.code || _this5.waybillDetailData.shipCompanyId);
            _this5.$set(_this5.waybillDetailData, 'shipCompanyName', shipCompanyRes.data.name || '');
            _this5.$set(_this5.waybillDetailData, 'shipRemark', shipCompanyRes.data.remark || '');
          });
        }

        // 车队
        if (_this5.waybillDetailData.specifyCarTeamIds) {
          var carTeamNames = '';
          var carTeamIdJson = JSON.parse(_this5.waybillDetailData.specifyCarTeamIds);
          for (var i = 0; i < carTeamIdJson.length; i++) {
            if (i !== 0) {
              carTeamNames += '、';
            }
            carTeamNames += carTeamIdJson[i].name;
          }
          _this5.waybillDetailData.specifyCarTeamIds = carTeamNames;
        }
        _this5.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    processSearchForm: function processSearchForm() {
      var commitSearchForm = JSON.parse(JSON.stringify(this.searchForm));
      return commitSearchForm;
    },
    exportData: function exportData() {
      var requestUrl = process.env.VUE_APP_API + 'logistics_hongde/waybillOrder/basisExportJoinWaybillAndDriver';
      var searchParams = this.processSearchForm();
      searchParams.Authorization = localStorage.getItem('token');
      window.open(requestUrl + this.joinGetParam(searchParams), '_blank');
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};