import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键字"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "关键字"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "操作",
      width: "145"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.detail(scope.row);
            }
          }
        }, [_vm._v("明细")]), _vm.currentUser === "Wechat:guan409932398" ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.del(scope.row.id);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "id",
      label: "订单号",
      width: "180",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "companyName",
      label: "活动方",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userNickname",
      label: "用户",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "productName",
      label: "产品",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "state",
      label: "状态",
      sortable: "custom",
      width: "85"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.state === "wait_pay" ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("待支付")]) : _vm._e(), scope.row.state === "end" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("已支付")]) : _vm._e(), scope.row.state === "refund" ? _c("span", {
          staticClass: "text-pause"
        }, [_vm._v("已退款")]) : _vm._e(), scope.row.state === "cancel" ? _c("span", {
          staticClass: "text-pause"
        }, [_vm._v("已取消")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "vipDuration",
      label: "VIP时间",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.productVipDuration) + " " + _vm._s(scope.row.productVipDurationUnit === "Day" ? "天" : scope.row.productVipDurationUnit === "Month" ? "月" : scope.row.productVipDurationUnit === "Year" ? "年" : "未知") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "payCategory",
      label: "支付类别",
      sortable: "custom",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.payCategory === "aliPay" ? _c("span", {
          staticClass: "text-style"
        }, [_vm._v("支付宝")]) : _vm._e(), scope.row.payCategory === "wxPay" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("微信支付")]) : _vm._e(), scope.row.payCategory === "wxSpPay" ? _c("span", {
          staticClass: "text-interrupt"
        }, [_vm._v("微信服务商支付")]) : _vm._e(), scope.row.payCategory === "balance" ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("余额支付")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "payType",
      label: "支付类型",
      sortable: "custom",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.payType === "pos" ? _c("span", {
          staticClass: "text-pause"
        }, [_vm._v("POS机")]) : _vm._e(), scope.row.payType === "jsapi" ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("JS-API")]) : _vm._e(), scope.row.payType === "app" ? _c("span", {
          staticClass: "text-style"
        }, [_vm._v("App")]) : _vm._e(), scope.row.payType === "Native" ? _c("span", {
          staticClass: "text-interrupt"
        }, [_vm._v("Native")]) : _vm._e(), scope.row.payType === "h5" ? _c("span", {
          staticClass: "text-lose"
        }, [_vm._v("H5")]) : _vm._e(), scope.row.payType === "applet" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("小程序")]) : _vm._e(), scope.row.payType === "face" ? _c("span", {
          staticClass: "text-collapse"
        }, [_vm._v("刷脸")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "currentCost",
      label: "购买价 / 原价 (元)",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          staticClass: "text-style"
        }, [_vm._v(_vm._s((scope.row.orderFinalCost || 0) / 100))]), _c("span", {
          staticClass: "text-pause"
        }, [_vm._v(" / " + _vm._s((scope.row.productOriginalCost || 0) / 100))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "支付时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.payTime ? _vm.dayjs(scope.row.payTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.createTime ? _vm.dayjs(scope.row.createTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.editDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initFormData(_vm.emptyFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDataVerify",
    attrs: {
      model: _vm.formData
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "订单号 / 状态",
      "label-width": _vm.formLabelWidth,
      prop: "id"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.id) + " 【 "), _vm.formData.state === "wait_pay" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("未支付")]) : _vm._e(), _vm.formData.state === "end" ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("已支付")]) : _vm._e(), _vm.formData.state === "refund" ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("已退款")]) : _vm._e(), _vm.formData.state === "cancel" ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("已取消")]) : _vm._e(), _vm._v(" 】 ")])]), _c("el-form-item", {
    attrs: {
      label: "用户",
      "label-width": _vm.formLabelWidth,
      prop: "companyName"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.userNickname) + " 【" + _vm._s(_vm.formData.userId) + "】 ")])]), _c("el-form-item", {
    attrs: {
      label: "活动方",
      "label-width": _vm.formLabelWidth,
      prop: "companyName"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.companyName) + " 【" + _vm._s(_vm.formData.companyId) + "】 ")])]), _c("el-form-item", {
    attrs: {
      label: "订单金额",
      "label-width": _vm.formLabelWidth,
      prop: "orderFinalCost"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v(" " + _vm._s((_vm.formData.orderFinalCost || 0) / 100) + " ")])]), _c("el-form-item", {
    attrs: {
      label: "支付方式",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm.formData.payCategory === "aliPay" ? _c("span", {
    staticClass: "text-style"
  }, [_vm._v("支付宝")]) : _vm._e(), _vm.formData.payCategory === "wxPay" ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("微信支付")]) : _vm._e(), _vm.formData.payCategory === "wxSpPay" ? _c("span", {
    staticClass: "text-interrupt"
  }, [_vm._v("微信服务商支付")]) : _vm._e(), _vm.formData.payCategory === "balance" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("余额支付")]) : _vm._e(), _vm._v(" 【 "), _vm.formData.payType === "pos" ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("POS机")]) : _vm._e(), _vm.formData.payType === "jsapi" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("JS-API")]) : _vm._e(), _vm.formData.payType === "app" ? _c("span", {
    staticClass: "text-style"
  }, [_vm._v("App")]) : _vm._e(), _vm.formData.payType === "Native" ? _c("span", {
    staticClass: "text-interrupt"
  }, [_vm._v("Native")]) : _vm._e(), _vm.formData.payType === "h5" ? _c("span", {
    staticClass: "text-lose"
  }, [_vm._v("H5")]) : _vm._e(), _vm.formData.payType === "applet" ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("小程序")]) : _vm._e(), _vm.formData.payType === "face" ? _c("span", {
    staticClass: "text-collapse"
  }, [_vm._v("刷脸")]) : _vm._e(), _vm._v(" 】 ")])]), _c("el-form-item", {
    attrs: {
      label: "支付时间",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.payTime ? _vm.dayjs(_vm.formData.payTime * 1000).format("YYYY-MM-DD HH:mm:ss") : "") + " ")])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "产品",
      "label-width": _vm.formLabelWidth,
      prop: "productName"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.productName) + " 【" + _vm._s(_vm.formData.productId) + "】 ")])]), _c("el-form-item", {
    attrs: {
      label: "原价",
      "label-width": _vm.formLabelWidth,
      prop: "productCurrentCost"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v(" " + _vm._s((_vm.formData.productOriginalCost || 0) / 100) + " ")])]), _c("el-form-item", {
    attrs: {
      label: "购买价",
      "label-width": _vm.formLabelWidth,
      prop: "productCurrentCost"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v(" " + _vm._s((_vm.formData.productCurrentCost || 0) / 100) + " ")])]), _c("el-form-item", {
    attrs: {
      label: "VIP时效",
      "label-width": _vm.formLabelWidth,
      prop: "productVipDuration"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.productVipDuration) + " " + _vm._s(_vm.formData.productVipDurationUnit === "Day" ? "天" : _vm.formData.productVipDurationUnit === "Month" ? "月" : _vm.formData.productVipDurationUnit === "Year" ? "年" : "未知") + " ")])])], 1)], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };