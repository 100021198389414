export default (function (_ref) {
  var request = _ref.request;
  return {
    cmsWorkOrderQuery: function cmsWorkOrderQuery(data) {
      return request({
        url: '/cms/workorder/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    cmsWorkOrderActive: function cmsWorkOrderActive(id) {
      return request({
        url: "/cms/workorder/".concat(id, "/active"),
        method: 'post'
      });
    }
  };
});