import "core-js/modules/es.math.trunc.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-page-header", {
    attrs: {
      content: "《 " + _vm.currentWaybill.merchantNickname + " 》 运单列表"
    },
    on: {
      back: _vm.back
    }
  }), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: _vm.waybillDetailData.waybillIncludeThirdPlace == "Yes" ? 8 : 12
    }
  }, [_c("div", {
    staticClass: "top-info"
  }, [_c("div", {
    staticClass: "info-title"
  }, [_vm._v("提箱地")]), _c("div", {
    staticClass: "info-address"
  }, [_vm._v(_vm._s(_vm.waybillDetailData.firstPlaceDetail || ""))]), _c("div", {
    staticClass: "info-lbs"
  }, [_vm._v(_vm._s(_vm.waybillDetailData.firstPlaceProvince) + " " + _vm._s(_vm.waybillDetailData.firstPlaceCity) + " " + _vm._s(_vm.waybillDetailData.firstPlaceDistrict))]), _c("div", {
    staticClass: "info-tip"
  }, [_vm._v("提箱有效时间段：")]), _c("div", {
    staticClass: "info-time"
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.firstPlaceBeginTime / 100))) + " - " + _vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.firstPlaceEndTime / 100))))])])]), _c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: _vm.waybillDetailData.waybillIncludeThirdPlace == "Yes" ? 8 : 12
    }
  }, [_c("div", {
    staticClass: "top-info"
  }, [_c("div", {
    staticClass: "info-title orange"
  }, [_vm._v("送货/装货地")]), _c("div", {
    staticClass: "info-address"
  }, [_vm._v(_vm._s(_vm.waybillDetailData.secondPlaceDetail || ""))]), _c("div", {
    staticClass: "info-lbs"
  }, [_vm._v(_vm._s(_vm.waybillDetailData.secondPlaceProvince) + " " + _vm._s(_vm.waybillDetailData.secondPlaceCity) + " " + _vm._s(_vm.waybillDetailData.secondPlaceDistrict))]), _c("div", {
    staticClass: "info-tip"
  }, [_vm._v("送货/装货有效时间段：")]), _c("div", {
    staticClass: "info-time"
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.secondPlaceBeginTime / 100))) + " - " + _vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.secondPlaceEndTime / 100))))])])]), _vm.waybillDetailData.waybillIncludeThirdPlace == "Yes" ? _c("el-col", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      padding: "5px",
      "padding-left": "20px"
    },
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "top-info"
  }, [_c("div", {
    staticClass: "info-title green"
  }, [_vm._v("收箱地")]), _c("div", {
    staticClass: "info-address"
  }, [_vm._v(_vm._s(_vm.waybillDetailData.thirdPlaceDetail || ""))]), _c("div", {
    staticClass: "info-lbs"
  }, [_vm._v(_vm._s(_vm.waybillDetailData.thirdPlaceProvince) + " " + _vm._s(_vm.waybillDetailData.thirdPlaceCity) + " " + _vm._s(_vm.waybillDetailData.thirdPlaceDistrict))]), _c("div", {
    staticClass: "info-tip"
  }, [_vm._v("收箱有效时间段：")]), _c("div", {
    staticClass: "info-time"
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.thirdPlaceBeginTime / 100))) + " - " + _vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillDetailData.thirdPlaceEndTime / 100))))])])]) : _vm._e()], 1), _c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "运单地址"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "运单地址"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "司机电话"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "司机联系电话"
    },
    model: {
      value: _vm.searchForm.driverContactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "driverContactPhone", $$v);
      },
      expression: "searchForm.driverContactPhone"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "配送形式"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.waybillDeliveryMode,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "waybillDeliveryMode", $$v);
      },
      expression: "searchForm.waybillDeliveryMode"
    }
  }, [_c("el-option", {
    attrs: {
      label: "单背",
      value: "Single"
    }
  }), _c("el-option", {
    attrs: {
      label: "双背",
      value: "Double"
    }
  }), _c("el-option", {
    attrs: {
      label: "多背",
      value: "More"
    }
  })], 1)], 1), _c("br"), _c("el-form-item", {
    attrs: {
      label: "运单状态"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      placeholder: "运单状态",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.orderState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "orderState", $$v);
      },
      expression: "searchForm.orderState"
    }
  }, _vm._l(_vm.orderState, function (item) {
    return _c("el-option", {
      key: item.vlaue,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "开始时间"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      type: "date",
      placeholder: "选择接单开始时间",
      "value-format": "yyyyMMdd"
    },
    model: {
      value: _vm.searchForm.starDate,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "starDate", $$v);
      },
      expression: "searchForm.starDate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "结束时间"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "178px"
    },
    attrs: {
      type: "date",
      placeholder: "选择接单结束时间",
      "value-format": "yyyyMMdd"
    },
    model: {
      value: _vm.searchForm.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "endDate", $$v);
      },
      expression: "searchForm.endDate"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.exportData
    }
  }, [_c("i", {
    staticClass: "fa el-icon-download"
  }), _vm._v(" 导出")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "id",
      label: "运单编号",
      "show-overflow-tooltip": "",
      width: "170"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.rowDetail(scope.row);
            }
          }
        }, [_vm._v("详情")]), scope.row.orderState === "Merchant_Confirm" && scope.row.serviceFeeChargeState == "End" ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "info",
            disabled: ""
          }
        }, [_vm._v("服务费已收")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderState",
      label: "运单状态",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "125"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.orderState[scope.row.orderState].label))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "driverName",
      label: "司机姓名",
      "show-overflow-tooltip": "",
      width: "115"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "driverContactPhone",
      label: "司机电话",
      "show-overflow-tooltip": "",
      width: "105"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.driverContactPhone || ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "driverCarNumber",
      label: "车牌号",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.driverCarNumber || ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "containerAmount",
      label: "箱量",
      "show-overflow-tooltip": "",
      width: "50"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.containerAmount || 0))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "waybillDeliveryMode",
      label: "形式",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "75"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.waybillDeliveryMode == "Double" ? _c("div", {
          staticClass: "text-suggest"
        }, [_vm._v("双背")]) : _vm._e(), scope.row.waybillDeliveryMode == "Single" ? _c("div", {
          staticClass: "text-style"
        }, [_vm._v("单背")]) : _vm._e(), scope.row.waybillDeliveryMode == "More" ? _c("div", [_vm._v("多背")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "outStock",
      label: "是否无货回空",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.outStock == "Yes" ? "是" : scope.row.outStock == "No" ? "否" : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "otherMoneyCost",
      label: "其他的费用",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.otherMoneyCost || 0) + "元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "remark",
      label: "备注",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.remark))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "freightDriverCost",
      label: "运费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.orderState == "Undo" ? 0 : scope.row.freightDriverCost || 0) + "元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "freightServiceFeeCost",
      label: "服务费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.orderState == "Undo" ? 0 : (scope.row.freightServiceFeeCost || 0) + (scope.row.urgentServiceFeeCost || 0)) + "元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "urgentCost",
      label: "加急费",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.orderState == "Undo" ? 0 : scope.row.urgentCost - scope.row.urgentServiceFeeCost || 0) + "元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "freightDriverCost",
      label: "运单应收",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.orderState == "Undo" ? 0 : scope.row.freightDriverCost || 0) + "元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "urgentCost",
      label: "加急费应收",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.orderState == "Undo" ? 0 : scope.row.urgentCost - scope.row.urgentServiceFeeCost || 0) + "元")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "freightDriverManualCost",
      label: "运单实收",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "urgentDriverManualCost",
      label: "加急费实收",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "接单时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.createTime ? _vm.dayjs(scope.row.createTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.detailDialogShow,
      title: "运单详情",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.detailDialogShow = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 2
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", {
    staticClass: "box-card basicDiv"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("接单司机")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("司机姓名：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.driverName || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("司机电话：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.driverContactPhone || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("车牌号：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.driverCarNumber || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("接单时间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.createTime ? _vm.dayjs(_vm.waybillOrderDetailData.createTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])], 1)], 1), _c("el-card", {
    staticClass: "box-card basicDiv"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("基本信息")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("派单方电话：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.merchantContactPhone || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("船公司：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.shipCompanyId || "") + " " + _vm._s(_vm.waybillDetailData.shipCompanyName || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提示：")]), _c("el-col", {
    staticClass: "text-lose",
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.shipRemark || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提（运）单号：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.waybillExtractIdentity || ""))])], 1)], 1), _c("el-card", {
    staticClass: "box-card deliveryDiv",
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("配送要求")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("车况要求：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.carConditionDemand || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("配送要求：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.carDeliveryDemand || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("备注：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.remark || ""))])], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm.waybillOrderDetailData.containerSealNums ? _c("el-card", {
    staticClass: "box-card basicDiv"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("箱号铅封")])]), _vm._l(JSON.parse(_vm.waybillOrderDetailData.containerSealNums), function (item, index) {
    return _c("div", {
      key: index
    }, [_c("el-row", {
      attrs: {
        gutter: 5
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_vm._v("箱" + _vm._s(index + 1) + "·箱号：")]), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_vm._v(_vm._s(item.identity || ""))])], 1), item.sealIdentity ? _c("el-row", {
      attrs: {
        gutter: 5
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_vm._v("箱" + _vm._s(index + 1) + "·铅封号：")]), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_vm._v(_vm._s(item.sealIdentity || ""))])], 1) : _vm._e()], 1);
  })], 2) : _vm._e(), _c("el-card", {
    staticClass: "box-card containerDiv"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("集装箱信息")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("尺寸：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dicData.configSizeOptions[_vm.waybillDetailData.containerSize] || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("箱型：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dicData.configShapeOptions[_vm.waybillDetailData.containerShape] || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("空/重箱：")]), _vm.waybillDetailData.containerEmptyOrFull == "Empty" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("空箱")]) : _vm._e(), _vm.waybillDetailData.containerEmptyOrFull == "Full" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("重箱")]) : _vm._e()], 1), _vm.waybillDetailData.containerWeight ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("箱重区间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.containerWeight || ""))])], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("配送形式：")]), _vm.waybillDetailData.deliveryMode == "Single" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("单背")]) : _vm._e(), _vm.waybillDetailData.deliveryMode == "Double" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("双背")]) : _vm._e(), _vm.waybillDetailData.deliveryMode == "More" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("多背")]) : _vm._e()], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("集装箱数量：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.containerAmount || ""))])], 1)], 1), _c("el-card", {
    staticClass: "box-card costDiv",
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("费用信息")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("每车运费：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillDetailData.deliveryOnceCost || 0) + " 元/辆")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("服务费金额：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.urgentServiceFeeCost + _vm.waybillOrderDetailData.freightServiceFeeCost) + "元")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("发票：")]), _vm.waybillDetailData.provideInvoice == "Yes" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提供")]) : _vm._e(), _vm.waybillDetailData.provideInvoice == "No" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("不提供")]) : _vm._e()], 1)], 1), _c("el-card", {
    staticClass: "box-card costDiv",
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "16px"
    }
  }, [_vm._v("节点信息")])]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("接单时间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dayjs(_vm.waybillOrderDetailData.createTime * 1000).format("YYYY-MM-DD HH:mm:ss")))])], 1), _vm.waybillOrderDetailData.undoTime ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("撤单时间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillOrderDetailData.undoTime / 100))))])], 1) : _vm._e(), _vm.waybillOrderDetailData.firstPlaceConfirmTime ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提箱时间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillOrderDetailData.firstPlaceConfirmTime / 100))))])], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提箱垫付费：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.firstPlaceFee || 0) + "元")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("提箱照片：")]), _vm.waybillOrderDetailData.firstPlaceImage ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, _vm._l(JSON.parse(_vm.waybillOrderDetailData.firstPlaceImage), function (img, index) {
    return _c("div", {
      key: index
    }, [_c("el-image", {
      staticClass: "point-img",
      attrs: {
        src: img,
        alt: "",
        "preview-src-list": JSON.parse(_vm.waybillOrderDetailData.firstPlaceImage)
      }
    })], 1);
  }), 0) : _vm._e()], 1), _vm.waybillOrderDetailData.transferApplyTime ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("申请转单时间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillOrderDetailData.transferApplyTime / 100))))])], 1) : _vm._e(), _vm.waybillOrderDetailData.transferApplyTime ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("申请转单时间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillOrderDetailData.transferApplyTime / 100))))])], 1) : _vm._e(), _vm.waybillOrderDetailData.transferAcceptTime ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_vm.waybillOrderDetailData.transferState == "Accept" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("转单成功：")]) : _vm._e(), _vm.waybillOrderDetailData.transferState == "Reject" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("转单失败：")]) : _vm._e(), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillOrderDetailData.transferAcceptTime / 100))))])], 1) : _vm._e(), _vm.waybillOrderDetailData.secondPlaceArriveTime ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("到达装卸地时间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillOrderDetailData.secondPlaceArriveTime / 100))))])], 1) : _vm._e(), _vm.waybillOrderDetailData.secondPlaceConfirmTime ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("送货/装货时间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillOrderDetailData.secondPlaceConfirmTime / 100))))])], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("送货/装货垫付费：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.secondPlaceFee || 0) + "元")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("送货/装货照片：")]), _vm.waybillOrderDetailData.secondPlaceImage ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, _vm._l(JSON.parse(_vm.waybillOrderDetailData.secondPlaceImage), function (img, index) {
    return _c("div", {
      key: index
    }, [_c("el-image", {
      staticClass: "point-img",
      attrs: {
        src: img,
        alt: "",
        "preview-src-list": JSON.parse(_vm.waybillOrderDetailData.secondPlaceImage)
      }
    })], 1);
  }), 0) : _vm._e()], 1), _vm.waybillOrderDetailData.thirdPlaceConfirmTime ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("到达收箱地时间：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(Math.trunc(_vm.waybillOrderDetailData.thirdPlaceConfirmTime / 100))))])], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("收箱垫付费：")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm.waybillOrderDetailData.thirdPlaceFee || 0) + "元")])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("收箱货照片：")]), _vm.waybillOrderDetailData.thirdPlaceImage ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, _vm._l(JSON.parse(_vm.waybillOrderDetailData.thirdPlaceImage), function (img, index) {
    return _c("div", {
      key: index
    }, [_c("el-image", {
      staticClass: "point-img",
      attrs: {
        src: img,
        alt: "",
        "preview-src-list": JSON.parse(_vm.waybillOrderDetailData.thirdPlaceImage)
      }
    })], 1);
  }), 0) : _vm._e()], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("磅单照片：")]), _vm.waybillOrderDetailData.weighImage ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, _vm._l(JSON.parse(_vm.waybillOrderDetailData.weighImage), function (img, index) {
    return _c("div", {
      key: index
    }, [_c("el-image", {
      staticClass: "point-img",
      attrs: {
        src: img,
        alt: "",
        "preview-src-list": JSON.parse(_vm.waybillOrderDetailData.weighImage)
      }
    })], 1);
  }), 0) : _vm._e()], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("单据照片：")]), _vm.waybillOrderDetailData.billsImage ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, _vm._l(JSON.parse(_vm.waybillOrderDetailData.billsImage), function (img, index) {
    return _c("div", {
      key: index
    }, [_c("el-image", {
      staticClass: "point-img",
      attrs: {
        src: img,
        alt: "",
        "preview-src-list": JSON.parse(_vm.waybillOrderDetailData.billsImage)
      }
    })], 1);
  }), 0) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };