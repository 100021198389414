export default (function (_ref) {
  var request = _ref.request;
  return {
    cmsAdvertisingAdd: function cmsAdvertisingAdd(data) {
      return request({
        url: '/cms/advertising',
        method: 'post',
        data: data
      });
    },
    cmsAdvertisingModify: function cmsAdvertisingModify(data) {
      return request({
        url: "/cms/advertising/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    cmsAdvertisingDel: function cmsAdvertisingDel(id) {
      return request({
        url: "/cms/advertising/".concat(id),
        method: 'delete'
      });
    },
    cmsAdvertisingQuery: function cmsAdvertisingQuery(data) {
      return request({
        url: '/cms/advertising/query',
        method: 'get',
        params: data
      });
    },
    cmsAdvertisingDetail: function cmsAdvertisingDetail(id) {
      return request({
        url: "/cms/advertising/".concat(id),
        method: 'get'
      });
    },
    cmsAdvertisingOnline: function cmsAdvertisingOnline(id) {
      return request({
        url: "/cms/advertising/".concat(id, "/online/"),
        method: 'post'
      });
    },
    cmsAdvertisingUnOnline: function cmsAdvertisingUnOnline(id) {
      return request({
        url: "/cms/advertising/".concat(id, "/unOnline/"),
        method: 'post'
      });
    }
  };
});