export default (function (_ref) {
  var request = _ref.request;
  return {
    mngUserAddressAdd: function mngUserAddressAdd(data) {
      return request({
        url: '/mng/address',
        method: 'post',
        data: data
      });
    },
    mngUserAddressModify: function mngUserAddressModify(data) {
      return request({
        url: "/mng/address/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    mngUserAddressDel: function mngUserAddressDel(id) {
      return request({
        url: "/mng/address/".concat(id),
        method: 'delete'
      });
    },
    mngUserAddressQuery: function mngUserAddressQuery(data) {
      return request({
        url: '/mng/address/query',
        method: 'get',
        params: data
      });
    },
    mngUserAddressDetail: function mngUserAddressDetail(id) {
      return request({
        url: "/mng/address/".concat(id),
        method: 'get'
      });
    },
    mngUserAddressDefault: function mngUserAddressDefault(id) {
      return request({
        url: "/mng/address/default/".concat(id),
        method: 'post'
      });
    }
  };
});