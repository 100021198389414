var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-radio-group", {
    model: {
      value: _vm.radio,
      callback: function callback($$v) {
        _vm.radio = $$v;
      },
      expression: "radio"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "订单日志"
    }
  }), _c("el-radio-button", {
    attrs: {
      label: "运单日志"
    }
  }), _c("el-radio-button", {
    attrs: {
      label: "接单方日志"
    }
  }), _c("el-radio-button", {
    attrs: {
      label: "派单方日志"
    }
  }), _c("el-radio-button", {
    attrs: {
      label: "船公司日志"
    }
  }), _c("el-radio-button", {
    attrs: {
      label: "车队车变更日志"
    }
  })], 1), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }), _vm.radio == "订单日志" ? _c("orderPage") : _vm._e(), _vm.radio == "运单日志" ? _c("waybillorderPage") : _vm._e(), _vm.radio == "接单方日志" ? _c("driverPage") : _vm._e(), _vm.radio == "派单方日志" ? _c("merchantPage") : _vm._e(), _vm.radio == "船公司日志" ? _c("shipCompanyPage") : _vm._e(), _vm.radio == "车队车变更日志" ? _c("teamcarPage") : _vm._e()], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };