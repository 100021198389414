import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'logistics_hongde_ship_company_mng',
  components: {
    shipCompanyTable: function shipCompanyTable() {
      return import('./ship_company_page');
    },
    carTeamsTable: function carTeamsTable() {
      return import('./ship_company_carTeams_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'shipCompany',
      currentShipCompany: null
    };
  },
  methods: {
    goShipCompany: function goShipCompany() {
      this.tableShow = 'shipCompany';
    },
    goChild: function goChild(data, tableName) {
      this.currentShipCompany = data;
      this.tableShow = tableName;
    }
  }
};