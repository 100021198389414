export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeWaybillQuery: function logisticHongdeWaybillQuery(data) {
      return request({
        url: '/logistics_hongde/waybill/queryForAdmin',
        method: 'get',
        params: data
      });
    },
    logisticHongdeWaybillDetail: function logisticHongdeWaybillDetail(id) {
      return request({
        url: "/logistics_hongde/waybill/".concat(id),
        method: 'get'
      });
    },
    logisticHongdeWaybillTotalFee: function logisticHongdeWaybillTotalFee(data) {
      return request({
        url: '/logistics_hongde/waybill/statsForAdmin',
        method: 'get',
        params: data
      });
    }
  };
});