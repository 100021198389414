import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.trim.js";
import dayjs from 'dayjs';
export default {
  name: 'pay_withdrawal_order',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '提现订单',
        queryFunName: 'payWithdrawalQueryJoinUser',
        detailFunName: 'payWithdrawalDetail',
        transgerFunName: 'payWithdrawalTransfer',
        rejectedFunName: 'payWithdrawalRejected'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        withdrawalState: null,
        starDate: null,
        endDate: null,
        sortField: 'create_time'
      },
      formData: {
        id: null,
        userId: null,
        category: null,
        qrcode: null,
        name: null,
        account: null,
        contact: null,
        contactPhone: null,
        withdrawalCost: null,
        poundageScale: null,
        poundageCost: null,
        finalCost: null,
        withdrawalState: null,
        withdrawalTime: null,
        msg: null,
        createTime: null,
        userAccount: null,
        userNickname: null
      },
      rules: {
        withdrawalTime: [{
          required: true,
          message: '请选择转账时间',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    detail: function detail(rowData) {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](rowData.id).then(function (res) {
        _this.initFormData(res.data);
        _this.formData.userAccount = rowData.userAccount;
        _this.formData.userNickname = rowData.userNickname;
        _this.editDialogTitle = _this.pageConfig.editPageName + '明细';
        _this.editDialogShow = true;
      });
    },
    rejected: function rejected() {
      var _this2 = this;
      this.$confirm('确定要驳回申请吗').then(function () {
        _this2.$prompt('请输入驳回原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValidator: function inputValidator(value) {
            if (!value || value.trim() === '') {
              return false;
            }
          },
          inputErrorMessage: '请输入驳回原因'
        }).then(function (_ref) {
          var value = _ref.value;
          _this2.$api[_this2.pageConfig.rejectedFunName](_this2.formData.id, value).then(function (res) {
            if (res.data) {
              _this2.$message.success('驳回成功');
              _this2.closeEditDialogShow();
            } else {
              _this2.$message.error('驳回失败');
              _this2.closeEditDialogShow();
            }
          });
        }).catch(function () {});
      });
    },
    transfer: function transfer() {
      var _this3 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          _this3.$api[_this3.pageConfig.transgerFunName](_this3.formData.id, Math.round(_this3.formData.withdrawalTime / 1000)).then(function (res) {
            if (res.data) {
              _this3.$message.success('转账成功');
              _this3.initFormData(_this3.emptyFormData);
              _this3.editDialogShow = false;
              _this3.query();
            } else {
              _this3.$message.error('转账失败');
            }
          });
        }
      });
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};