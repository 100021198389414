export default (function (_ref) {
  var request = _ref.request;
  return {
    payChargeProjectQuery: function payChargeProjectQuery(data) {
      return request({
        url: '/pay/chargeProject/query',
        method: 'get',
        params: data
      });
    },
    payChargeProjectDetail: function payChargeProjectDetail(id) {
      return request({
        url: "/pay/chargeProject/".concat(id),
        method: 'get'
      });
    },
    payChargeProjectAdd: function payChargeProjectAdd(data) {
      return request({
        url: "/pay/chargeProject",
        method: 'post',
        data: data
      });
    },
    payChargeProjectModify: function payChargeProjectModify(data) {
      return request({
        url: "/pay/chargeProject/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    payChargeProjectDel: function payChargeProjectDel(id) {
      return request({
        url: "/pay/chargeProject/".concat(id),
        method: 'delete'
      });
    },
    payChargeProjectOnline: function payChargeProjectOnline(id) {
      return request({
        url: "/pay/chargeProject/".concat(id, "/online"),
        method: 'post'
      });
    },
    payChargeProjectUnOnline: function payChargeProjectUnOnline(id) {
      return request({
        url: "/pay/chargeProject/".concat(id, "/unOnline"),
        method: 'post'
      });
    }
  };
});