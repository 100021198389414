import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'logistics_hongde_merchant_mng',
  components: {
    merchantTable: function merchantTable() {
      return import('./merchant_page');
    },
    merchantEvalTable: function merchantEvalTable() {
      return import('./merchant_eval_page');
    },
    merchantCarTeamTable: function merchantCarTeamTable() {
      return import('./merchant_car_team_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'merchant',
      currentMerchant: null
    };
  },
  methods: {
    goMerchant: function goMerchant() {
      this.tableShow = 'merchant';
    },
    goChild: function goChild(data, tableName) {
      this.currentMerchant = data;
      this.tableShow = tableName;
    }
  }
};