export default (function (_ref) {
  var request = _ref.request;
  return {
    mngConfigAdd: function mngConfigAdd(data) {
      return request({
        url: '/mng/config',
        method: 'post',
        data: data
      });
    },
    mngConfigModify: function mngConfigModify(data) {
      return request({
        url: "/mng/config/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    mngConfigDel: function mngConfigDel(id) {
      return request({
        url: "/mng/config/".concat(id),
        method: 'delete'
      });
    },
    mngConfigQuery: function mngConfigQuery(data) {
      return request({
        url: '/mng/config/query',
        method: 'get',
        params: data
      });
    },
    mngConfigDetail: function mngConfigDetail(id) {
      return request({
        url: "/mng/config/".concat(id),
        method: 'get'
      });
    },
    mngConfigCacheValue: function mngConfigCacheValue(code) {
      return request({
        url: "/mng/config/cache/value/".concat(code),
        method: 'get'
      });
    },
    mngConfigCacheValues: function mngConfigCacheValues(codes) {
      return request({
        url: "/mng/config/cache/values?codes=".concat(codes),
        method: 'get'
      });
    },
    mngConfigClearCache: function mngConfigClearCache(code) {
      return request({
        url: "/mng/config/cache/clear?code=".concat(code),
        method: 'post'
      });
    }
  };
});