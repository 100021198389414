export default (function (_ref) {
  var request = _ref.request;
  return {
    websiteNavigationAdd: function websiteNavigationAdd(data) {
      return request({
        url: '/website/website/navigation',
        method: 'post',
        data: data
      });
    },
    websiteNavigationModify: function websiteNavigationModify(data) {
      return request({
        url: "/website/website/navigation/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    websiteNavigationDel: function websiteNavigationDel(id) {
      return request({
        url: "/website/website/navigation/".concat(id),
        method: 'delete'
      });
    },
    websiteNavigationQuery: function websiteNavigationQuery(userId, data) {
      return request({
        url: "/website/website/navigation/".concat(userId, "/query"),
        method: 'get',
        params: data
      });
    },
    websiteNavigationQueryAll: function websiteNavigationQueryAll(userId, data) {
      return request({
        url: "/website/website/navigation/".concat(userId, "/queryAll"),
        method: 'get',
        params: data
      });
    },
    websiteNavigationTree: function websiteNavigationTree(userId, data) {
      return request({
        url: "/website/website/navigation/".concat(userId, "/cacheTree"),
        method: 'get',
        params: data
      });
    },
    websiteNavigationDetail: function websiteNavigationDetail(id) {
      return request({
        url: "/website/website/navigation/".concat(id),
        method: 'get'
      });
    },
    websiteNavigationClearCache: function websiteNavigationClearCache() {
      return request({
        url: '/website/website/navigation/cacheClear',
        method: 'post'
      });
    }
  };
});