export default (function (_ref) {
  var request = _ref.request;
  return {
    eduLiyouOrderQuery: function eduLiyouOrderQuery(data) {
      return request({
        url: '/education_liyou/courseOrder/queryJoinCourseAndUser',
        method: 'get',
        params: data
      });
    },
    eduLiyouOrderQueryJoinCourseAndUserAndIntroduce: function eduLiyouOrderQueryJoinCourseAndUserAndIntroduce(data) {
      return request({
        url: '/education_liyou/courseOrder/queryJoinCourseAndUserAndIntroduce',
        method: 'get',
        params: data
      });
    },
    eduLiyouOrderFeeTotal: function eduLiyouOrderFeeTotal(data) {
      return request({
        url: '/education_liyou/courseOrder/statsCost',
        method: 'get',
        params: data
      });
    }
  };
});