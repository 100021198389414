export default (function (_ref) {
  var request = _ref.request;
  return {
    cmsCarouselAdd: function cmsCarouselAdd(data) {
      return request({
        url: '/cms/carousel',
        method: 'post',
        data: data
      });
    },
    cmsCarouselModify: function cmsCarouselModify(data) {
      return request({
        url: "/cms/carousel/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    cmsCarouselDel: function cmsCarouselDel(id) {
      return request({
        url: "/cms/carousel/".concat(id),
        method: 'delete'
      });
    },
    cmsCarouselQuery: function cmsCarouselQuery(data) {
      return request({
        url: '/cms/carousel/query',
        method: 'get',
        params: data
      });
    },
    cmsCarouselDetail: function cmsCarouselDetail(id) {
      return request({
        url: "/cms/carousel/".concat(id),
        method: 'get'
      });
    }
  };
});