import _objectSpread from "/Users/riverlei/project/java/bgyy-project/front-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import dayjs from 'dayjs';
export default {
  name: 'logistics_hongde_driver_page',
  props: {
    currentCarTeam: {
      type: Object
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '车牌',
        queryDriverFunName: 'logisticHongdeDriverQuery',
        dicTreeFunName: 'mngDictionaryLowerTree'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        userState: 'active',
        sortField: 'create_time',
        carTeam: this.currentCarTeam.id
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      dicData: {
        upperCode: 'logistics_hd_car_type',
        configCarTypes: [],
        configCarTypeOptions: {},
        levelUpperCode: 'logistics_hd_driver_level',
        configLevels: [],
        configLevelOptions: {}
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    Promise.all([new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.upperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configCarTypes = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configCarTypeOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.levelUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configLevels = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configLevelOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    })]).then(function (result) {
      _this.query();
    });
  },
  methods: {
    back: function back() {
      this.$emit('child-go-car-team');
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryDriverFunName](_objectSpread(_objectSpread({}, this.searchForm), {}, {
        carTeam: this.currentCarTeam.id
      })).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};