import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'logistics_hongde_car_team_mng',
  components: {
    carTeamTable: function carTeamTable() {
      return import('./car_team_page');
    },
    driverTable: function driverTable() {
      return import('./driver_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'carTeam',
      currentCarTeam: null
    };
  },
  methods: {
    goCarTeam: function goCarTeam() {
      this.tableShow = 'carTeam';
    },
    goChild: function goChild(data, tableName) {
      this.currentCarTeam = data;
      this.tableShow = tableName;
    }
  }
};