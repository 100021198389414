export default (function (_ref) {
  var request = _ref.request;
  return {
    mngRoleAdd: function mngRoleAdd(data) {
      return request({
        url: '/mng/role',
        method: 'post',
        data: data
      });
    },
    mngRoleModify: function mngRoleModify(data) {
      return request({
        url: "/mng/role/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    mngRoleDel: function mngRoleDel(id) {
      return request({
        url: "/mng/role/".concat(id),
        method: 'delete'
      });
    },
    mngRoleQuery: function mngRoleQuery(data) {
      return request({
        url: '/mng/role/query',
        method: 'get',
        params: data
      });
    },
    mngRoleQueryAll: function mngRoleQueryAll(data) {
      return request({
        url: '/mng/role/queryAll',
        method: 'get',
        params: data
      });
    },
    mngRoleDetail: function mngRoleDetail(id) {
      return request({
        url: "/mng/role/".concat(id),
        method: 'get'
      });
    },
    mngRoleDisable: function mngRoleDisable(id) {
      return request({
        url: "/mng/role/".concat(id, "/disable"),
        method: 'post'
      });
    },
    mngRoleUnDisable: function mngRoleUnDisable(id) {
      return request({
        url: "/mng/role/".concat(id, "/unDisable"),
        method: 'post'
      });
    },
    mngRolesMenusTree: function mngRolesMenusTree(roleId) {
      return request({
        url: "/mng/rolesMenus/".concat(roleId, "/cache/lowerTree"),
        method: 'get'
      });
    }
  };
});