export default (function (_ref) {
  var request = _ref.request;
  return {
    groupBuyCompanyStoreAdd: function groupBuyCompanyStoreAdd(data) {
      return request({
        url: '/group_buy/companysStores',
        method: 'post',
        data: data
      });
    },
    groupBuyCompanyStoreModify: function groupBuyCompanyStoreModify(data) {
      return request({
        url: "/group_buy/companysStores/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    groupBuyCompanyStoreDel: function groupBuyCompanyStoreDel(id) {
      return request({
        url: "/group_buy/companysStores/".concat(id),
        method: 'delete'
      });
    },
    groupBuyCompanyStoreQuery: function groupBuyCompanyStoreQuery(data) {
      return request({
        url: '/group_buy/companysStores/query',
        method: 'get',
        params: data
      });
    },
    groupBuyCompanyStoreDetail: function groupBuyCompanyStoreDetail(id) {
      return request({
        url: "/group_buy/companysStores/".concat(id),
        method: 'get'
      });
    }
  };
});