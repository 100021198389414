import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
export default {
  name: 'website_outlink',
  data: function data() {
    return {
      pageConfig: {
        editPageName: '外链',
        addFunName: 'websiteOutlinkAdd',
        modifyFunName: 'websiteOutlinkModify',
        delFunName: 'websiteOutlinkDel',
        queryFunName: 'websiteOutlinkQuery',
        detailFunName: 'websiteOutlinkDetail',
        clearCacheFunName: 'websiteOutlinkClearCache'
      },
      searchForm: {
        page: 1,
        limit: 10,
        name: '',
        sortField: 'create_time'
      },
      formData: {
        id: null,
        userId: localStorage.getItem('userId'),
        name: null,
        link: null,
        sort: null
      },
      rules: {
        name: [{
          required: true,
          message: '标题不能为空',
          trigger: 'blur'
        }],
        link: [{
          required: true,
          message: '外链不能为空',
          trigger: 'blur'
        }],
        sort: [{
          pattern: /^[1-9]\d*$/,
          message: '排序号必须为正整数',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    refresh: function refresh() {
      var _this = this;
      this.$confirm('确定刷新缓存吗？').then(function () {
        _this.$api[_this.pageConfig.clearCacheFunName]().then(function (res) {
          _this.$message.success('操作成功');
        });
      });
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(id) {
      var _this2 = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this2.initFormData(res.data);
        _this2.editDialogTitle = _this2.pageConfig.editPageName + '维护';
        _this2.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this3 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this3.$api[_this3.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this3.$message.success('删除成功');
            _this3.query();
          } else {
            _this3.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](localStorage.getItem('userId'), this.searchForm).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this5 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this5.formData.id) {
            _this5.$api[_this5.pageConfig.modifyFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('编辑成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('编辑失败');
              }
            });
          } else {
            _this5.$api[_this5.pageConfig.addFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('新增成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};