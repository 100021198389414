export default (function (_ref) {
  var request = _ref.request;
  return {
    websiteCarouselAdd: function websiteCarouselAdd(data) {
      return request({
        url: '/website/website/carousel',
        method: 'post',
        data: data
      });
    },
    websiteCarouselModify: function websiteCarouselModify(data) {
      return request({
        url: "/website/website/carousel/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    websiteCarouselDel: function websiteCarouselDel(id) {
      return request({
        url: "/website/website/carousel/".concat(id),
        method: 'delete'
      });
    },
    websiteCarouselQuery: function websiteCarouselQuery(userId, data) {
      return request({
        url: "/website/website/carousel/".concat(userId, "/query"),
        method: 'get',
        params: data
      });
    },
    websiteCarouselDetail: function websiteCarouselDetail(id) {
      return request({
        url: "/website/website/carousel/".concat(id),
        method: 'get'
      });
    }
  };
});