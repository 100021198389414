import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'edu_liyou_course_mng_page',
  components: {
    courseTable: function courseTable() {
      return import('./edu_liyou_course_page');
    },
    courseClassTable: function courseClassTable() {
      return import('./edu_liyou_course_class_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'course',
      currentCourse: null
    };
  },
  methods: {
    goCourse: function goCourse() {
      this.tableShow = 'course';
    },
    goChild: function goChild(data, tableName) {
      this.currentCourse = data;
      this.tableShow = tableName;
    }
  }
};