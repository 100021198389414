import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
export default {
  name: 'pay_refund_order',
  data: function data() {
    return {
      currentUser: localStorage.getItem('userId'),
      pageConfig: {
        editPageName: '退款订单',
        queryFunName: 'refundOrderQuery',
        detailFunName: 'refundOrderDetail',
        againSendNoticeFunName: 'refundOrderAgainSendNotice',
        dicTreeFunName: 'mngDictionaryLowerTree'
      },
      dicData: {
        upperCode: 'pay_product_type',
        configProductTypes: [],
        configProductTypeOptions: {}
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        category: null,
        type: null,
        productType: null,
        refundState: null,
        starDate: null,
        endDate: null,
        sortField: 'create_time'
      },
      formData: {
        id: null,
        userId: null,
        category: null,
        type: null,
        productType: null,
        content: null,
        attach: null,
        amount: null,
        requestTime: null,
        requestMsg: null,
        resultTime: null,
        resultMsg: null,
        callbackTime: null,
        orderId: null,
        refundState: null,
        thirdpartyOrder: null,
        userName: null,
        userImage: null,
        userPhone: null,
        returnUrl: null,
        openId: null,
        ip: null
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.$api[this.pageConfig.dicTreeFunName](this.dicData.upperCode).then(function (res) {
      if (res.data) {
        _this.dicData.configProductTypes = res.data;
        for (var i = 0; i < res.data.length; i++) {
          _this.dicData.configProductTypeOptions[res.data[i].code] = res.data[i].name;
        }
      }
      _this.query();
    });
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));

      // 产品类型
      if (paramData.productType) {
        paramData.productType = this.dicData.configProductTypeOptions[paramData.productType] || paramData.productType;
      }
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    again: function again(id) {
      var _this2 = this;
      this.$confirm('确定补录数据吗？补录后将不能恢复').then(function () {
        _this2.$confirm('请再次确认，是否补录数据？').then(function () {
          _this2.$confirm('请谨慎操作！！！最后确认补录数据？').then(function () {
            _this2.$api[_this2.pageConfig.againSendNoticeFunName](id).then(function (res) {
              if (res.data) {
                _this2.$message.success('清除成功');
                _this2.query();
              } else {
                _this2.$message.error('清除失败');
              }
            });
          });
        });
      });
    },
    detail: function detail(order) {
      var _this3 = this;
      this.$api[this.pageConfig.detailFunName](order.id).then(function (res) {
        res.data.userName = order.userName;
        res.data.userImage = order.userImage;
        res.data.userPhone = order.userPhone;
        _this3.initFormData(res.data);
        _this3.editDialogTitle = _this3.pageConfig.editPageName + '明细';
        _this3.editDialogShow = true;
      });
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};