import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键字"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "关键字"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "帐号状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.userState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "userState", $$v);
      },
      expression: "searchForm.userState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "正常",
      value: "active"
    }
  }), _c("el-option", {
    attrs: {
      label: "锁定",
      value: "lock"
    }
  }), _c("el-option", {
    attrs: {
      label: "禁用",
      value: "disable"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "认证状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.authState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "authState", $$v);
      },
      expression: "searchForm.authState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "已认证",
      value: "Success"
    }
  }), _c("el-option", {
    attrs: {
      label: "待审核",
      value: "Wait"
    }
  }), _c("el-option", {
    attrs: {
      label: "已驳回",
      value: "Fail"
    }
  })], 1)], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  }), _vm._v(" 添加")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.merchantEdit(scope.row);
            }
          }
        }, [_vm._v("派单方维护")]), _c("el-dropdown", {
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            size: "mini",
            "split-button": "",
            type: "primary"
          },
          on: {
            command: _vm.moreButton
          }
        }, [_vm._v(" 更多 "), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-truck",
            command: _vm.beforeCommand(scope.$index, scope.row, "carTeam")
          }
        }, [_vm._v("车队管理")]), scope.row.authState === "Wait" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-check",
            command: _vm.beforeCommand(scope.$index, scope.row, "success")
          }
        }, [_vm._v("认证通过")]) : _vm._e(), scope.row.authState === "Wait" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-close",
            command: _vm.beforeCommand(scope.$index, scope.row, "fail")
          }
        }, [_vm._v("认证驳回")]) : _vm._e(), scope.row.userState === "active" && scope.row.authState === "Success" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-s-ticket",
            command: _vm.beforeCommand(scope.$index, scope.row, "addQuota")
          }
        }, [_vm._v("增加授信额度")]) : _vm._e(), scope.row.userState === "active" && scope.row.evalSwitch && (scope.row.authState === "Success" || scope.row.authState === "Fail") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-view",
            command: _vm.beforeCommand(scope.$index, scope.row, "seeEval")
          }
        }, [_vm._v("查看评价")]) : _vm._e(), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-user-solid",
            command: _vm.beforeCommand(scope.$index, scope.row, "edit")
          }
        }, [_vm._v("帐号维护")]), scope.row.userState === "lock" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-key",
            command: _vm.beforeCommand(scope.$index, scope.row, "unLock")
          }
        }, [_vm._v("解锁派单方")]) : _vm._e(), scope.row.userState === "active" && scope.row.id !== "Wechat:guan409932398" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-lock",
            command: _vm.beforeCommand(scope.$index, scope.row, "disable")
          }
        }, [_vm._v("禁用派单方")]) : _vm._e(), scope.row.userState === "disable" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-unlock",
            command: _vm.beforeCommand(scope.$index, scope.row, "unDisable")
          }
        }, [_vm._v("解禁派单方")]) : _vm._e(), scope.row.userState === "active" && !scope.row.evalSwitch && scope.row.authState === "Success" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-chat-dot-round",
            command: _vm.beforeCommand(scope.$index, scope.row, "Eval")
          }
        }, [_vm._v("开启评价")]) : _vm._e(), scope.row.userState === "active" && scope.row.evalSwitch && (scope.row.authState === "Success" || scope.row.authState === "Fail") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-chat-round",
            command: _vm.beforeCommand(scope.$index, scope.row, "unEval")
          }
        }, [_vm._v("关闭评价")]) : _vm._e(), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-refresh",
            command: _vm.beforeCommand(scope.$index, scope.row, "resetPassword")
          }
        }, [_vm._v("重置密码")]), _vm.currentUser === "Wechat:guan409932398" && scope.row.id !== "Wechat:guan409932398" ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-delete",
            command: _vm.beforeCommand(scope.$index, scope.row, "clear")
          }
        }, [_vm._v("清理用户")]) : _vm._e()], 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "userState",
      label: "账号",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.userState === "active" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("正常")]) : _vm._e(), scope.row.userState === "lock" ? _c("span", {
          staticClass: "text-interrupt"
        }, [_vm._v("锁定")]) : _vm._e(), scope.row.userState === "disable" ? _c("span", {
          staticClass: "text-collapse"
        }, [_vm._v("禁用")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "authState",
      label: "认证",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.authState === "Success" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("已认证")]) : scope.row.authState === "Wait" ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("待审核")]) : scope.row.authState === "Fail" ? _c("span", {
          staticClass: "text-collapse"
        }, [_vm._v("已驳回")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "evalSwitch",
      label: "评价",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.evalSwitch && (scope.row.authState === "Success" || scope.row.authState === "Fail") ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("开启")]) : !scope.row.evalSwitch && (scope.row.authState === "Success" || scope.row.authState === "Fail") ? _c("span", {
          staticClass: "text-collapse"
        }, [_vm._v("关闭")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "userPhone",
      label: "登录手机号",
      "show-overflow-tooltip": "",
      width: "180"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "category",
      label: "身份"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.category === "mng_user_category_hd_motorcade" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("鸿德集装箱-车队")]) : scope.row.category === "mng_user_category_hd_merchant" ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("鸿德集装箱 - 商户（派单方）")]) : scope.row.category === "mng_user_category_hd_driver" ? _c("span", {
          staticClass: "text-collapse"
        }, [_vm._v("鸿德集装箱 - 司机（接单方）")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "power",
      label: "权限"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.power && scope.row.power.indexOf("sendOrder") !== -1 ? _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v("派单方")]) : _vm._e(), scope.row.power && scope.row.power.indexOf("takOrder") !== -1 ? _c("el-tag", {
          attrs: {
            type: "warning"
          }
        }, [_vm._v("接单方")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "nickname",
      label: "昵称",
      "show-overflow-tooltip": "",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "businessLicenseName",
      label: "公司名称",
      "show-overflow-tooltip": "",
      "min-width": "200"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "level",
      label: "等级",
      sortable: "custom",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm.dicData.configLevelOptions[scope.row.level]) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "legal",
      label: "法人",
      "show-overflow-tooltip": "",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "contactPhone",
      label: "联系方式",
      "show-overflow-tooltip": "",
      width: "120"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "quotaUnused",
      label: "授信额度",
      sortable: "custom",
      "show-overflow-tooltip": "",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          staticStyle: {
            color: "green"
          }
        }, [_vm._v(_vm._s((scope.row.quotaUnused || 0) / 100))]), _c("span", [_vm._v(" / " + _vm._s(((scope.row.quotaUnused || 0) + (scope.row.quotaUse || 0)) / 100))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "evalScore",
      label: "平均评分",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(((scope.row.evalScore || 0) / (scope.row.evalAmount || 1)).toFixed(2)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      sortable: "custom",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.createTime ? _vm.dayjs(scope.row.createTime * 1000).format("YYYY-MM-DD") : ""))])];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    staticClass: "accountPage",
    attrs: {
      visible: _vm.editDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initFormData(_vm.emptyFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDataVerify",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "手机号",
      "label-width": _vm.formLabelWidth,
      prop: "phone"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "登录手机号"
    },
    model: {
      value: _vm.formData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "状态",
      "label-width": _vm.formLabelWidth,
      prop: "state"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "active"
    },
    model: {
      value: _vm.formData.state,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "state", $$v);
      },
      expression: "formData.state"
    }
  }, [_vm._v("正常")]), _c("el-radio", {
    attrs: {
      label: "lock"
    },
    model: {
      value: _vm.formData.state,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "state", $$v);
      },
      expression: "formData.state"
    }
  }, [_vm._v("锁定")]), _c("el-radio", {
    attrs: {
      label: "disable"
    },
    model: {
      value: _vm.formData.state,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "state", $$v);
      },
      expression: "formData.state"
    }
  }, [_vm._v("禁用")])], 1)])], 1)], 1), _c("el-row", [_c("el-col", [_c("el-form-item", {
    attrs: {
      label: "备注",
      "label-width": _vm.formLabelWidth,
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 5
    },
    model: {
      value: _vm.formData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remark", $$v);
      },
      expression: "formData.remark"
    }
  })], 1)], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确 定")])], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.editMerchantDialogShow,
      title: "派单方信息维护",
      width: "80%",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editMerchantDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initMerchantFormData(_vm.emptyMerchantFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formMerchantDataVerify",
    attrs: {
      model: _vm.formMerchantData,
      rules: _vm.merchantRules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "营业执照名称",
      "label-width": _vm.formCardLabelWidth,
      prop: "businessLicenseName"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "营业执照名称"
    },
    model: {
      value: _vm.formMerchantData.businessLicenseName,
      callback: function callback($$v) {
        _vm.$set(_vm.formMerchantData, "businessLicenseName", $$v);
      },
      expression: "formMerchantData.businessLicenseName"
    }
  })], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "统一信用代码",
      "label-width": _vm.formCardLabelWidth,
      prop: "socialUniformCreditCode"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "统一信用代码"
    },
    model: {
      value: _vm.formMerchantData.socialUniformCreditCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formMerchantData, "socialUniformCreditCode", $$v);
      },
      expression: "formMerchantData.socialUniformCreditCode"
    }
  })], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "等级",
      "label-width": _vm.formCardLabelWidth,
      prop: "level"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择派单方等级",
      clearable: ""
    },
    model: {
      value: _vm.formMerchantData.level,
      callback: function callback($$v) {
        _vm.$set(_vm.formMerchantData, "level", $$v);
      },
      expression: "formMerchantData.level"
    }
  }, _vm._l(_vm.dicData.configLevels, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "状态",
      "label-width": _vm.formCardLabelWidth,
      prop: "state"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Success"
    },
    model: {
      value: _vm.formMerchantData.authState,
      callback: function callback($$v) {
        _vm.$set(_vm.formMerchantData, "authState", $$v);
      },
      expression: "formMerchantData.authState"
    }
  }, [_vm._v("通过")]), _c("el-radio", {
    attrs: {
      label: "Wait"
    },
    model: {
      value: _vm.formMerchantData.authState,
      callback: function callback($$v) {
        _vm.$set(_vm.formMerchantData, "authState", $$v);
      },
      expression: "formMerchantData.authState"
    }
  }, [_vm._v("等待")]), _c("el-radio", {
    attrs: {
      label: "Fail"
    },
    model: {
      value: _vm.formMerchantData.authState,
      callback: function callback($$v) {
        _vm.$set(_vm.formMerchantData, "authState", $$v);
      },
      expression: "formMerchantData.authState"
    }
  }, [_vm._v("驳回")])], 1)])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "营业执照",
      "label-width": _vm.formCardLabelWidth,
      prop: "businessLicenseImage"
    }
  }, [!_vm.formMerchantData.businessLicenseImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadBusinessLicenseImage
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formMerchantData.businessLicenseImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formMerchantData.businessLicenseImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formMerchantData.businessLicenseImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeMerchantFile();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.formMerchantData.businessLicenseImage);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "法人姓名",
      "label-width": _vm.formCardLabelWidth,
      prop: "legal"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "公司法人"
    },
    model: {
      value: _vm.formMerchantData.legal,
      callback: function callback($$v) {
        _vm.$set(_vm.formMerchantData, "legal", $$v);
      },
      expression: "formMerchantData.legal"
    }
  })], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系方式",
      "label-width": _vm.formCardLabelWidth,
      prop: "contactPhone"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "联系方式"
    },
    model: {
      value: _vm.formMerchantData.contactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.formMerchantData, "contactPhone", $$v);
      },
      expression: "formMerchantData.contactPhone"
    }
  })], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证号",
      "label-width": _vm.formCardLabelWidth,
      prop: "legalIdentityCard"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "法人身份证号",
      maxlength: "18"
    },
    model: {
      value: _vm.formMerchantData.legalIdentityCard,
      callback: function callback($$v) {
        _vm.$set(_vm.formMerchantData, "legalIdentityCard", $$v);
      },
      expression: "formMerchantData.legalIdentityCard"
    }
  })], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证正面",
      "label-width": _vm.formCardLabelWidth,
      prop: "legalIdentityCardImageFront"
    }
  }, [!_vm.formMerchantData.legalIdentityCardImageFront ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadCardFrontImage
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formMerchantData.legalIdentityCardImageFront ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formMerchantData.legalIdentityCardImageFront ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formMerchantData.legalIdentityCardImageFront
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeIdentityCardImageFrontFile();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.formMerchantData.legalIdentityCardImageFront);
      }
    }
  })])])])]) : _vm._e()], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份证反面",
      "label-width": _vm.formCardLabelWidth,
      prop: "legalIdentityCardImageBack"
    }
  }, [!_vm.formMerchantData.legalIdentityCardImageBack ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadCardBackImage
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formMerchantData.legalIdentityCardImageBack ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formMerchantData.legalIdentityCardImageBack ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formMerchantData.legalIdentityCardImageBack
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeIdentityCardImageBackFile();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.formMerchantData.legalIdentityCardImageBack);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "身份:"
    }
  }, [_vm.formMerchantData.category === "mng_user_category_hd_motorcade" ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("鸿德集装箱-车队")]) : _vm.formMerchantData.category === "mng_user_category_hd_merchant" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("鸿德集装箱 - 商户（派单方）")]) : _vm.formMerchantData.category === "mng_user_category_hd_driver" ? _c("span", {
    staticClass: "text-collapse"
  }, [_vm._v("鸿德集装箱 - 司机（接单方）")]) : _vm._e()])], 1), _vm.formMerchantData.category === "mng_user_category_hd_motorcade" ? _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "power",
      label: "权限:"
    }
  }, [_vm.formMerchantData.power && _vm.formMerchantData.power.indexOf("sendOrder") !== -1 ? _c("el-tag", {
    attrs: {
      type: "success"
    }
  }, [_vm._v("派单方")]) : _vm._e(), _vm.formMerchantData.power && _vm.formMerchantData.power.indexOf("takOrder") !== -1 ? _c("el-tag", {
    attrs: {
      type: "warning"
    }
  }, [_vm._v("接单方")]) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.formMerchantData.power && _vm.formMerchantData.power.indexOf("takOrder") !== -1 ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "车队名称："
    }
  }, [_vm._v(" " + _vm._s(_vm.formMerchantData.carTeamName) + " ")])], 1), _c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "车队领导："
    }
  }, [_vm._v(" " + _vm._s(_vm.formMerchantData.carTeamLeaderName) + " ")])], 1), _c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "车队联系方式："
    }
  }, [_vm._v(" " + _vm._s(_vm.formMerchantData.carTeamLeaderPhone) + " ")])], 1)], 1) : _vm._e()], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeMerchantEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitMerchantFormData
    }
  }, [_vm._v("确 定")])], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };