import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键字"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "关键字"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "APP类型"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.appType,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "appType", $$v);
      },
      expression: "searchForm.appType"
    }
  }, _vm._l(_vm.dicData.configCategorys, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "版本类型"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.versionType,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "versionType", $$v);
      },
      expression: "searchForm.versionType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "开发",
      value: "Development"
    }
  }), _c("el-option", {
    attrs: {
      label: "测试",
      value: "Test"
    }
  }), _c("el-option", {
    attrs: {
      label: "生产",
      value: "Production"
    }
  })], 1)], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  }), _vm._v(" 添加")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange,
      "cell-dblclick": _vm.copyLink
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "230"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.qrcode(scope.row);
            }
          }
        }, [_vm._v("二维码")]), _c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.edit(scope.row.id);
            }
          }
        }, [_vm._v("编辑")]), scope.row.versionType != "Production" ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.send(scope.row.id);
            }
          }
        }, [_vm._v("发布")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "versionType",
      label: "版本类型",
      width: "110",
      sortable: "custom",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.versionType === "Development" ? _c("span", {
          staticClass: "text-style"
        }, [_vm._v("开发")]) : _vm._e(), scope.row.versionType === "Test" ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("测试")]) : _vm._e(), scope.row.versionType === "Production" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("生产")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "appType",
      label: "App类型",
      width: "150",
      sortable: "custom",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.dicData.configCategoryOptions[scope.row.appType]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "版本名称",
      width: "200",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "version",
      label: "版本号",
      width: "150",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "userNickname",
      label: "创建人",
      width: "150",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.createTime ? _vm.dayjs(scope.row.createTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "downloadUrl",
      label: "下载地址(双击复制)",
      "show-overflow-tooltip": ""
    }
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.editDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initFormData(_vm.emptyFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDataVerify",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "APP类型",
      "label-width": _vm.formLabelWidth,
      prop: "appType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      disabled: !!_vm.formData.id,
      clearable: ""
    },
    model: {
      value: _vm.formData.appType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "appType", $$v);
      },
      expression: "formData.appType"
    }
  }, _vm._l(_vm.dicData.configCategorys, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "版本类型",
      "label-width": _vm.formLabelWidth,
      prop: "versionType"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Development",
      disabled: !!_vm.formData.id
    },
    model: {
      value: _vm.formData.versionType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "versionType", $$v);
      },
      expression: "formData.versionType"
    }
  }, [_vm._v("开发")]), _c("el-radio", {
    attrs: {
      label: "Test",
      disabled: !!_vm.formData.id
    },
    model: {
      value: _vm.formData.versionType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "versionType", $$v);
      },
      expression: "formData.versionType"
    }
  }, [_vm._v("测试")]), !!_vm.formData.id ? _c("el-radio", {
    attrs: {
      label: "Production",
      disabled: !!_vm.formData.id
    },
    model: {
      value: _vm.formData.versionType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "versionType", $$v);
      },
      expression: "formData.versionType"
    }
  }, [_vm._v("生产")]) : _vm._e()], 1)])], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "版本名称",
      "label-width": _vm.formLabelWidth,
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "版本名称",
      disabled: !!_vm.formData.id
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "版本号",
      "label-width": _vm.formLabelWidth,
      prop: "version"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "版本号",
      disabled: !!_vm.formData.id
    },
    model: {
      value: _vm.formData.version,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "version", $$v);
      },
      expression: "formData.version"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-form-item", {
    staticClass: "upload-item",
    attrs: {
      label: "版本文件",
      "label-width": _vm.formLabelWidth,
      prop: "downloadUrl"
    }
  }, [!_vm.formData.downloadUrl ? _c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      action: "",
      accept: "application/vnd.android.package-archive",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.upload
    }
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    }
  }, [_vm._v("点击上传")])], 1) : _vm._e(), !!_vm.formData.downloadUrl ? _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.previewFile
    }
  }, [_vm._v("下载")]) : _vm._e(), !!_vm.formData.downloadUrl && !_vm.formData.id ? _c("el-button", {
    attrs: {
      size: "small",
      type: "danger"
    },
    on: {
      click: _vm.removeFile
    }
  }, [_vm._v("删除")]) : _vm._e(), _vm.formData.downloadUrl ? _c("span", {
    staticStyle: {
      height: "40px",
      "line-height": "40px",
      "margin-left": "10px",
      width: "calc(100% - 180px)",
      "white-space": "nowrap",
      overflow: "hidden",
      "text-overflow": "ellipsis",
      display: "inline-block"
    }
  }, [_c("a", {
    attrs: {
      href: _vm.formData.downloadUrl,
      title: _vm.formData.downloadUrl,
      target: "_blank"
    }
  }, [_vm._v(_vm._s(_vm.formData.downloadUrl))])]) : _vm._e()], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "备注",
      "label-width": _vm.formLabelWidth,
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 5
    },
    model: {
      value: _vm.formData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remark", $$v);
      },
      expression: "formData.remark"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确 定")])], 1)], 1), _c("el-dialog", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      visible: _vm.qrCodeDialogShow,
      title: _vm.qrCodeDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.qrCodeDialogShow = $event;
      }
    }
  }, [_c("vue-qr", {
    attrs: {
      text: _vm.qrCodeDialogDownloadUrl,
      size: 200
    }
  })], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };