import BScroll from 'better-scroll';
export default {
  props: {
    // 滚动优化的选项
    betterScrollOptions: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      BS: null
    };
  },
  mounted: function mounted() {
    this.scrollInit();
  },
  beforeDestroy: function beforeDestroy() {
    this.scrollDestroy();
  },
  methods: {
    scrollInit: function scrollInit() {
      var _this = this;
      // 初始化 bs
      this.BS = new BScroll(this.$refs.wrapper, Object.assign({
        mouseWheel: true,
        click: true,
        scrollbar: {
          fade: true,
          interactive: false
        }
      }, this.betterScrollOptions));
      // 滚动时发出事件 并且统一返回的数据格式
      this.BS.on('scroll', function (_ref) {
        var x = _ref.x,
          y = _ref.y;
        return _this.$emit('scroll', {
          x: -x,
          y: -y
        });
      });
    },
    scrollDestroy: function scrollDestroy() {
      try {
        this.BS.destroy();
      } catch (e) {
        delete this.BS;
        this.BS = null;
      }
    } // 外部调用的方法 返回顶部
    ,
    scrollToTop: function scrollToTop() {
      if (this.BS) this.BS.scrollTo(0, 0, 300);
    } // 手动发出滚动事件
    ,
    scroll: function scroll() {
      if (this.BS) {
        this.$emit('scroll', {
          x: -this.BS.x,
          y: -this.BS.y
        });
      }
    }
  }
};