export default (function (_ref) {
  var request = _ref.request;
  return {
    cmsWorkOrderReplayAdd: function cmsWorkOrderReplayAdd(data) {
      return request({
        url: '/cms/workorderReply',
        method: 'post',
        data: data
      });
    },
    cmsWorkOrderReplayQuery: function cmsWorkOrderReplayQuery(data) {
      return request({
        url: '/cms/workorderReply/queryJoinUser',
        method: 'get',
        params: data
      });
    }
  };
});