import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import userIcons from '@/assets/aliyun-icons/halo6_user/iconfont.json';
import menuIcons from '@/assets/aliyun-icons/halo6_menu/iconfont.json';
import operationIcons from '@/assets/aliyun-icons/halo6_operation/iconfont.json';
import systemIcons from '@/assets/aliyun-icons/halo6_system/iconfont.json';
import websiteIcons from '@/assets/aliyun-icons/halo6_website/iconfont.json';
import staIcons from '@/assets/aliyun-icons/halo6_sta/iconfont.json';
import companyIcons from '@/assets/aliyun-icons/halo6_company/iconfont.json';
import shopIcons from '@/assets/aliyun-icons/halo6_shop/iconfont.json';
import projectIcons from '@/assets/aliyun-icons/halo6_project/iconfont.json';
import educationIcons from '@/assets/aliyun-icons/halo6_education/iconfont.json';
import medicalIcons from '@/assets/aliyun-icons/halo6_medical/iconfont.json';
import recruitIcons from '@/assets/aliyun-icons/halo6_recruit/iconfont.json';
import osIcons from '@/assets/aliyun-icons/halo6_os/iconfont.json';
export default {
  name: 'mng_menu',
  data: function data() {
    return {
      icons: {
        userIcons: userIcons,
        menuIcons: menuIcons,
        operationIcons: operationIcons,
        websiteIcons: websiteIcons,
        systemIcons: systemIcons,
        staIcons: staIcons,
        companyIcons: companyIcons,
        medicalIcons: medicalIcons,
        educationIcons: educationIcons,
        shopIcons: shopIcons,
        recruitIcons: recruitIcons,
        projectIcons: projectIcons,
        osIcons: osIcons
      },
      pageConfig: {
        editPageName: '菜单',
        addFunName: 'mngMenuAdd',
        modifyFunName: 'mngMenuModify',
        delFunName: 'mngMenuDel',
        treeFunName: 'mngMenuTree',
        detailFunName: 'mngMenuDetail'
      },
      formData: {
        id: null,
        upperId: null,
        upperName: null,
        name: null,
        icon: null,
        perms: null,
        href: null,
        sort: 0,
        menuState: true
      },
      rules: {
        name: [{
          required: true,
          message: '菜单名不能为空',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    selIcons: function selIcons(fontFamily, fontClass) {
      this.formData.icon = fontFamily + ' ' + fontClass;
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add(upperId, upperName) {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
      this.formData.upperId = upperId || 'Wechat: guan409932398';
      this.formData.upperName = upperName || '顶级菜单';
    },
    edit: function edit(id) {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this.initFormData(res.data);
        _this.formData.upperName = _this.formData.upperName || '顶级菜单';
        _this.editDialogTitle = _this.pageConfig.editPageName + '维护';
        _this.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.treeFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this4 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this4.formData.id) {
            _this4.$api[_this4.pageConfig.modifyFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('编辑成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('编辑失败');
              }
            });
          } else {
            _this4.$api[_this4.pageConfig.addFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('新增成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    }
  }
};