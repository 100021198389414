export default (function (_ref) {
  var request = _ref.request;
  return {
    groupBuyVipProductAdd: function groupBuyVipProductAdd(data) {
      return request({
        url: '/group_buy/vipProduct',
        method: 'post',
        data: data
      });
    },
    groupBuyVipProductModify: function groupBuyVipProductModify(data) {
      return request({
        url: "/group_buy/vipProduct/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    groupBuyVipProductDel: function groupBuyVipProductDel(id) {
      return request({
        url: "/group_buy/vipProduct/".concat(id),
        method: 'delete'
      });
    },
    groupBuyVipProductQuery: function groupBuyVipProductQuery(data) {
      return request({
        url: '/group_buy/vipProduct/query',
        method: 'get',
        params: data
      });
    },
    groupBuyVipProductDetail: function groupBuyVipProductDetail(id) {
      return request({
        url: "/group_buy/vipProduct/".concat(id),
        method: 'get'
      });
    },
    groupBuyVipProductOnline: function groupBuyVipProductOnline(id) {
      return request({
        url: "/group_buy/vipProduct/".concat(id, "/online"),
        method: 'post'
      });
    },
    groupBuyVipProductUnOnline: function groupBuyVipProductUnOnline(id) {
      return request({
        url: "/group_buy/vipProduct/".concat(id, "/unOnline"),
        method: 'post'
      });
    }
  };
});