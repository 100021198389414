import _objectSpread from "/Users/riverlei/project/java/bgyy-project/front-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapActions } from 'vuex';
export default {
  name: 'app',
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  created: function created() {
    this.i18nHandle(this.$i18n.locale);
  },
  mounted: function mounted() {
    // 获取Token
    this.refreshToken();
  },
  methods: _objectSpread({
    i18nHandle: function i18nHandle(val) {
      document.querySelector('html').setAttribute('lang', val);
    }
  }, mapActions('d2admin/account', ['refreshToken']))
};