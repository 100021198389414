export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeMerchantLogQueryJoinUser: function logisticHongdeMerchantLogQueryJoinUser(data) {
      return request({
        url: '/logistics_hongde/merchantLog/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    logisticHongdeMerchantLogDetail: function logisticHongdeMerchantLogDetail(id) {
      return request({
        url: "/logistics_hongde/merchantLog/".concat(id),
        method: 'get'
      });
    },
    carTeamVehicleLogQuery: function carTeamVehicleLogQuery(data) {
      return request({
        url: '/logistics_hongde/carTeamVehicleLog/query',
        method: 'get',
        params: data
      });
    }
  };
});