import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    eduLiyouCoureseAdd: function eduLiyouCoureseAdd(data) {
      return request({
        url: '/education_liyou/course',
        method: 'post',
        data: data
      });
    },
    eduLiyouCoureseDel: function eduLiyouCoureseDel(id) {
      return request({
        url: "/education_liyou/course/".concat(id),
        method: 'delete'
      });
    },
    eduLiyouCoureseModify: function eduLiyouCoureseModify(data) {
      return request({
        url: "/education_liyou/course/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    eduLiyouCoureseQuery: function eduLiyouCoureseQuery(data) {
      return request({
        url: '/education_liyou/course/queryForAdmin',
        method: 'get',
        params: data
      });
    },
    eduLiyouCoureseDetail: function eduLiyouCoureseDetail(id) {
      return request({
        url: "/education_liyou/course/".concat(id),
        method: 'get'
      });
    },
    eduLiyouCoureseUnOnline: function eduLiyouCoureseUnOnline(id) {
      return request({
        url: "/education_liyou/course/".concat(id, "/unOnline"),
        method: 'post'
      });
    },
    eduLiyouCoureseOnline: function eduLiyouCoureseOnline(id) {
      return request({
        url: "/education_liyou/course/".concat(id, "/online"),
        method: 'post'
      });
    },
    eduLiyouCoureseRecommend: function eduLiyouCoureseRecommend(id, value) {
      return request({
        url: "/education_liyou/course/".concat(id, "/recommend/").concat(value),
        method: 'post'
      });
    },
    eduLiyouCoureseUnRecommend: function eduLiyouCoureseUnRecommend(id) {
      return request({
        url: "/education_liyou/course/".concat(id, "/unRecommend"),
        method: 'post'
      });
    }
  };
});