export default (function (_ref) {
  var request = _ref.request;
  return {
    websiteInformationAdd: function websiteInformationAdd(data) {
      return request({
        url: '/website/website/information',
        method: 'post',
        data: data
      });
    },
    websiteInformationModify: function websiteInformationModify(data) {
      return request({
        url: "/website/website/information/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    websiteInformationDel: function websiteInformationDel(id) {
      return request({
        url: "/website/website/information/".concat(id),
        method: 'delete'
      });
    },
    websiteInformationQuery: function websiteInformationQuery(userId, data) {
      return request({
        url: "/website/website/information/".concat(userId, "/query"),
        method: 'get',
        params: data
      });
    },
    websiteInformationDetail: function websiteInformationDetail(id) {
      return request({
        url: "/website/website/information/".concat(id),
        method: 'get'
      });
    },
    websiteInformationCacheContents: function websiteInformationCacheContents(codes) {
      return request({
        url: "/website/website/information/cacheContents?codes=".concat(codes),
        method: 'get'
      });
    },
    websiteInformationClearCache: function websiteInformationClearCache(code) {
      return request({
        url: "/website/website/information/cacheClear/".concat(code),
        method: 'post'
      });
    }
  };
});