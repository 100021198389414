var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.tableShow === "company" ? _c("company", {
    on: {
      "child-go": _vm.goChild
    }
  }) : _vm._e(), _vm.tableShow === "companyStores" ? _c("companyStores", {
    attrs: {
      currentCompany: _vm.currentCompany
    },
    on: {
      "child-go-company": _vm.goCompany
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };