import _objectSpread from "/Users/riverlei/project/java/bgyy-project/front-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import dayjs from 'dayjs';
export default {
  name: 'car_team',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '车队',
        addFunName: 'logisticHongdeCarTeamAdd',
        modifyFunName: 'logisticHongdeCarTeamModify',
        delFunName: 'logisticHongdeCarTeamDel',
        queryFunName: 'logisticHongdeCarTeamQuery',
        detailFunName: 'logisticHongdeCarTeamDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {
        id: null,
        name: null,
        leaderPhone: null,
        remark: null,
        leader: null
      },
      rules: {
        name: [{
          required: true,
          message: '车队名称不能为空',
          trigger: 'blur'
        }],
        leader: [{
          required: true,
          message: '联系人姓名不能为空',
          trigger: 'blur'
        }],
        leaderPhone: [{
          required: true,
          message: '联系人电话不能为空',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    beforeCommand: function beforeCommand(index, row, command) {
      return {
        index: index,
        row: row,
        command: command
      };
    },
    inputChange: function inputChange(value, index) {
      var _this = this;
      var flag = new RegExp("^[1-9]([0-9])*$").test(value);
      if (!flag) {
        this.$message.error('车辆数量请输入正整数');
        this.query();
        return;
      }
      this.$api[this.pageConfig.modifyFunName](_objectSpread(_objectSpread({}, this.tableData[index]), {}, {
        carLicenseAmount: value
      })).then(function (res) {
        if (res.data) {
          _this.$message.success('修改车辆数量成功');
          _this.query();
        } else {
          _this.$message.error('删除失败');
          _this.query();
        }
      });
    },
    driverGo: function driverGo(row) {
      this.goChild(row, 'driverTable');
    },
    goChild: function goChild(id, tableName) {
      this.$emit('child-go', id, tableName);
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(id) {
      var _this2 = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this2.initFormData(res.data);
        _this2.editDialogTitle = _this2.pageConfig.editPageName + '维护';
        _this2.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this3 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this3.$api[_this3.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this3.$message.success('删除成功');
            _this3.query();
          } else {
            _this3.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this5 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this5.formData.id) {
            _this5.$api[_this5.pageConfig.modifyFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('编辑成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('编辑失败');
              }
            });
          } else {
            _this5.$api[_this5.pageConfig.addFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('新增成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};