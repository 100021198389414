import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'logistics_waybill_mng',
  components: {
    waybillTable: function waybillTable() {
      return import('./waybill_page');
    },
    waybillOrederTable: function waybillOrederTable() {
      return import('./waybill_order_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'waybill',
      currentWaybill: null
    };
  },
  methods: {
    goWaybill: function goWaybill() {
      this.tableShow = 'waybill';
      // this.$router.back()
    },
    goChild: function goChild(data, tableName) {
      this.currentWaybill = data;
      this.tableShow = tableName;
    }
  }
};