// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1646201777286");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1646201777286");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1646201777286");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\r\n  font-family: \"iconeducation\"; /* Project id 3208510 */\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n.iconeducation {\r\n  font-family: \"iconeducation\" !important;\r\n  font-size: 16px;\r\n  font-style: normal;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\n}\n.icon-education-tushu:before {\r\n  content: \"\\e600\";\n}\n.icon-education-zhangjiekecheng:before {\r\n  content: \"\\e631\";\n}\n.icon-education-icon:before {\r\n  content: \"\\e606\";\n}\n.icon-education-tiku:before {\r\n  content: \"\\e69b\";\n}\n.icon-education-kaoshi:before {\r\n  content: \"\\e62d\";\n}\n.icon-education-jiaoshiguanli:before {\r\n  content: \"\\e660\";\n}\n.icon-education-xuexiao_jiaoshi:before {\r\n  content: \"\\e65f\";\n}\n.icon-education-cz-khdf:before {\r\n  content: \"\\e81f\";\n}\n.icon-education-nianjiguanli-:before {\r\n  content: \"\\e61c\";\n}\n.icon-education-kemuguanli-:before {\r\n  content: \"\\e63c\";\n}\n.icon-education-xuexiao:before {\r\n  content: \"\\e6e5\";\n}\n.icon-education-xuesheng:before {\r\n  content: \"\\e651\";\n}\n.icon-education-rank:before {\r\n  content: \"\\e99d\";\n}\n.icon-education-shangkelaoshiwo:before {\r\n  content: \"\\e6e1\";\n}\n.icon-education-kaoshi2:before {\r\n  content: \"\\e622\";\n}\r\n\r\n", ""]);
// Exports
module.exports = exports;
