import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'mng_user_mng',
  components: {
    user: function user() {
      return import('./user_page');
    },
    userAddress: function userAddress() {
      return import('./user_address_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'user',
      currentUser: null
    };
  },
  methods: {
    goUser: function goUser() {
      this.tableShow = 'user';
    },
    goChild: function goChild(data, tableName) {
      this.currentUser = data;
      this.tableShow = tableName;
    }
  }
};