export default (function (_ref) {
  var request = _ref.request;
  return {
    tobaccoJimoSaleStoreAdd: function tobaccoJimoSaleStoreAdd(data) {
      return request({
        url: '/tobacco_jimo/saleStore',
        method: 'post',
        data: data
      });
    },
    tobaccoJimoSaleStoreModify: function tobaccoJimoSaleStoreModify(data) {
      return request({
        url: "/tobacco_jimo/saleStore/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    tobaccoJimoSaleStoreDetail: function tobaccoJimoSaleStoreDetail(id) {
      return request({
        url: "/tobacco_jimo/saleStore/".concat(id),
        method: 'get'
      });
    },
    tobaccoJimoSaleStoreDel: function tobaccoJimoSaleStoreDel(id) {
      return request({
        url: "/tobacco_jimo/saleStore/".concat(id),
        method: 'delete'
      });
    },
    tobaccoJimoSaleStoreQuery: function tobaccoJimoSaleStoreQuery(data) {
      return request({
        url: '/tobacco_jimo/saleStore/query',
        method: 'get',
        params: data
      });
    }
  };
});