export default (function (_ref) {
  var request = _ref.request;
  return {
    eduLiyouLecturerAdd: function eduLiyouLecturerAdd(data) {
      return request({
        url: '/education_liyou/lecturer',
        method: 'post',
        data: data
      });
    },
    eduLiyouLecturerDel: function eduLiyouLecturerDel(id) {
      return request({
        url: "/education_liyou/lecturer/".concat(id),
        method: 'delete'
      });
    },
    eduLiyouLecturerModify: function eduLiyouLecturerModify(data) {
      return request({
        url: "/education_liyou/lecturer/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    eduLiyouLecturerQuery: function eduLiyouLecturerQuery(data) {
      return request({
        url: '/education_liyou/lecturer/query',
        method: 'get',
        params: data
      });
    },
    eduLiyouLecturerDetail: function eduLiyouLecturerDetail(id) {
      return request({
        url: "/education_liyou/lecturer/".concat(id),
        method: 'get'
      });
    }
  };
});