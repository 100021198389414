export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeMerchantCarTeamQuery: function logisticHongdeMerchantCarTeamQuery(data) {
      return request({
        url: '/logistics_hongde/merchantCarTeam/' + data.merchantId + '/queryByMerchant',
        method: 'get',
        params: data
      });
    },
    logisticHongdeMerchantCarTeamAdd: function logisticHongdeMerchantCarTeamAdd(data) {
      return request({
        url: '/logistics_hongde/merchantCarTeam',
        method: 'post',
        data: data
      });
    },
    logisticHongdeMerchantCarTeamDel: function logisticHongdeMerchantCarTeamDel(id) {
      return request({
        url: "/logistics_hongde/merchantCarTeam/".concat(id),
        method: 'delete'
      });
    }
  };
});