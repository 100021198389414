export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeShipCompanyAdd: function logisticHongdeShipCompanyAdd(data) {
      return request({
        url: '/logistics_hongde/shipCompany',
        method: 'post',
        data: data
      });
    },
    logisticHongdeShipCompanyModify: function logisticHongdeShipCompanyModify(data) {
      return request({
        url: "/logistics_hongde/shipCompany/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    logisticHongdeShipCompanyDel: function logisticHongdeShipCompanyDel(id) {
      return request({
        url: "/logistics_hongde/shipCompany/".concat(id),
        method: 'delete'
      });
    },
    logisticHongdeShipCompanyQuery: function logisticHongdeShipCompanyQuery(data) {
      return request({
        url: '/logistics_hongde/shipCompany/query',
        method: 'get',
        params: data
      });
    },
    logisticHongdeShipCompanyDetail: function logisticHongdeShipCompanyDetail(id) {
      return request({
        url: "/logistics_hongde/shipCompany/".concat(id),
        method: 'get'
      });
    }
  };
});