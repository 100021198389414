var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.tableShow === "coupon" ? _c("courseTable", {
    on: {
      "child-go": _vm.goChild
    }
  }) : _vm._e(), _vm.tableShow === "receive" ? _c("receiveTable", {
    attrs: {
      currentCoupon: _vm.currentCoupon
    },
    on: {
      "child-go-coupon": _vm.goCoupon
    }
  }) : _vm._e(), _vm.tableShow === "use" ? _c("useTable", {
    attrs: {
      currentCoupon: _vm.currentCoupon
    },
    on: {
      "child-go-coupon": _vm.goCoupon
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };