export default (function (_ref) {
  var request = _ref.request;
  return {
    eduLiyouCouponAdd: function eduLiyouCouponAdd(data) {
      return request({
        url: '/education_liyou/coupons',
        method: 'post',
        data: data
      });
    },
    eduLiyouCouponModify: function eduLiyouCouponModify(data) {
      return request({
        url: "/education_liyou/coupons/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    eduLiyouCouponDel: function eduLiyouCouponDel(id) {
      return request({
        url: "/education_liyou/coupons/".concat(id),
        method: 'delete'
      });
    },
    eduLiyouCouponQuery: function eduLiyouCouponQuery(data) {
      return request({
        url: '/education_liyou/coupons/query',
        method: 'get',
        params: data
      });
    },
    eduLiyouCouponQueryJoinAmount: function eduLiyouCouponQueryJoinAmount(data) {
      return request({
        url: '/education_liyou/coupons/queryJoinAmount',
        method: 'get',
        params: data
      });
    },
    eduLiyouCouponDetail: function eduLiyouCouponDetail(id) {
      return request({
        url: "/education_liyou/coupons/".concat(id),
        method: 'get'
      });
    },
    eduLiyouCouponOnline: function eduLiyouCouponOnline(id) {
      return request({
        url: "/education_liyou/coupons/".concat(id, "/online"),
        method: 'post'
      });
    },
    eduLiyouCouponUnOnline: function eduLiyouCouponUnOnline(id) {
      return request({
        url: "/education_liyou/coupons/".concat(id, "/unOnline"),
        method: 'post'
      });
    }
  };
});