import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import dayjs from 'dayjs';
export default {
  name: 'tobacco_jimo_inspection_record',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        queryFunName: 'tobaccoJimoInspectionRecordQuery',
        detailFunName: 'tobaccoJimoInspectionRecordDetail',
        saleStoreDetailFunName: 'tobaccoJimoSaleStoreDetail',
        recordDetailDetailsFunName: 'tobaccoJimoInspectionRecordDetailsQuery',
        dicTreeFunName: 'mngDictionaryLowerTree'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      detailDialogShow: false,
      formLabelWidth: '100px',
      dicData: {
        brandUpperCode: 'tobacco_jimo_tobacco_brand',
        configBrands: [],
        configBrandOptions: {},
        configBrandSecondOptions: {}
      },
      recordDetail: {
        recordTime: null,
        brandAmount: null,
        boxAmount: null
      },
      saleStoreDetail: {
        name: null,
        tobaccoSaleIdentity: null
      },
      recordDetailDetails: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    Promise.all([new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.brandUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configBrands = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configBrandOptions[res.data[i].code] = res.data[i].name;
            if (typeof res.data[i].lowerDics !== 'undefined' && res.data[i].lowerDics.length > 0) {
              for (var j = 0; j < res.data[i].lowerDics.length; j++) {
                _this.dicData.configBrandSecondOptions[res.data[i].lowerDics[j].code] = res.data[i].lowerDics[j].name;
              }
            }
          }
        }
        resolve(res);
      });
    })]).then(function (result) {
      _this.query();
    });
  },
  methods: {
    getRecordDetail: function getRecordDetail(id) {
      var _this2 = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this2.recordDetail = res.data;
        _this2.$api[_this2.pageConfig.saleStoreDetailFunName](res.data.saleStoreId).then(function (saleStoreRes) {
          _this2.saleStoreDetail = saleStoreRes.data;
        });
        _this2.$api[_this2.pageConfig.recordDetailDetailsFunName](res.data.id).then(function (recordDetailDetailsRes) {
          _this2.recordDetailDetails = recordDetailDetailsRes.data;
        });
        _this2.detailDialogShow = true;
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};