export default (function (_ref) {
  var request = _ref.request;
  return {
    groupBuyActivityShareQueryJoinUserAndActivity: function groupBuyActivityShareQueryJoinUserAndActivity(data) {
      return request({
        url: '/group_buy/groupActivitysShare/queryJoinUserAndActivity',
        method: 'get',
        params: data
      });
    }
  };
});