import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeWaybillOrderQuery: function logisticHongdeWaybillOrderQuery(data) {
      return request({
        url: '/logistics_hongde/waybillOrder/queryForAdmin',
        method: 'get',
        params: data
      });
    },
    logisticHongdeWaybillOrderDetail: function logisticHongdeWaybillOrderDetail(id) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id),
        method: 'get'
      });
    },
    logisticHongdeWaybillOrderConfirm: function logisticHongdeWaybillOrderConfirm(id) {
      return request({
        url: "/logistics_hongde/waybillOrder/serviceFeeChargeConfirm/".concat(id),
        method: 'post'
      });
    },
    logisticHongdeWaybillOrderModify: function logisticHongdeWaybillOrderModify(id, data) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/containerSealNums"),
        method: 'post',
        data: data
      });
    },
    logisticHongdeWaybillOrderFeeTotal: function logisticHongdeWaybillOrderFeeTotal(data) {
      return request({
        url: '/logistics_hongde/waybillOrder/statsForAdmin',
        method: 'get',
        params: data
      });
    },
    logisticHongdeWaybillOrderUndo: function logisticHongdeWaybillOrderUndo(id, data) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/undo"),
        method: 'POST',
        params: data
      });
    },
    logisticHongdeWaybillOrderFirstPlace: function logisticHongdeWaybillOrderFirstPlace(id, data) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/firstPlace/confim"),
        method: 'POST',
        data: data
      });
    },
    logisticHongdeWaybillOrderSecondArrive: function logisticHongdeWaybillOrderSecondArrive(id, data) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/secondPlace/arrive"),
        method: 'POST',
        data: data
      });
    },
    logisticHongdeWaybillOrderSecondPlace: function logisticHongdeWaybillOrderSecondPlace(id, data) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/secondPlace/confim"),
        method: 'POST',
        data: data
      });
    },
    logisticHongdeWaybillOrderThirdPlace: function logisticHongdeWaybillOrderThirdPlace(id, data) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/thirdPlace/confim"),
        method: 'POST',
        data: data
      });
    },
    logisticHongdeWaybillOrderPlatform: function logisticHongdeWaybillOrderPlatform(id, data) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/platform/confim"),
        method: 'POST',
        data: data
      });
    },
    logisticHongdeWaybillOrderTransfer: function logisticHongdeWaybillOrderTransfer(id, driverId) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/transfer/").concat(driverId),
        method: 'POST'
      });
    },
    logisticHongdeWaybillOrderTransferCancel: function logisticHongdeWaybillOrderTransferCancel(id) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/transferCancel"),
        method: 'POST'
      });
    },
    logisticHongdeWaybillOrderPlaceFee: function logisticHongdeWaybillOrderPlaceFee(id, data) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/placeFee"),
        method: 'POST',
        data: data
      });
    },
    logisticHongdeWaybillOrderBillsAndImage: function logisticHongdeWaybillOrderBillsAndImage(id, data) {
      return request({
        url: "/logistics_hongde/waybillOrder/".concat(id, "/billsAndImage"),
        method: 'POST',
        data: data
      });
    }
  };
});