import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import dayjs from 'dayjs';
export default {
  name: 'sizeModel_page',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '箱皮重',
        addFunName: 'logisticHongdeSizeModelAdd',
        modifyFunName: 'logisticHongdeSizeModelModify',
        delFunName: 'logisticHongdeSizeModelDel',
        queryFunName: 'logisticHongdeWaybillOrderLogQueryJoinUser',
        detailFunName: 'logisticHongdeWaybillOrderLogDetail',
        dicTreeFunName: 'mngDictionaryLowerTree'
      },
      searchForm: {
        waybillExtractIdentity: '',
        sortField: 'create_time'
      },
      formData: {
        model: null,
        size: null,
        weight: null
      },
      rules: {
        model: [{
          required: true,
          message: '箱型不能为空',
          trigger: 'blur'
        }],
        size: [{
          required: true,
          message: '尺寸不能为空',
          trigger: 'blur'
        }],
        weight: [{
          required: true,
          message: '重量不能为空',
          trigger: 'blur'
        }]
      },
      userType: {
        'admin': '后台管理员',
        'mng_user_category_hd_merchant': '派单方',
        'mng_user_category_hd_driver': '接单方'
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '130px',
      emptyFormData: {},
      dicData: {
        sizeUpperCode: 'logistics_hd_container_size',
        configSizes: [],
        configSizeOptions: {},
        shapeUpperCode: 'logistics_hd_container_shape',
        configShapes: [],
        configShapeOptions: {}
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    Promise.all([new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.sizeUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configSizes = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configSizeOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.shapeUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configShapes = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configShapeOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    })]).then(function (result) {
      _this.query();
    });
  },
  methods: {
    jsonToContent: function jsonToContent(content) {
      if (!content) {
        return '';
      }
      var arr = JSON.parse(content);
      if (!arr) {
        return '';
      }
      var str = '';
      if (arr.containerSealNums) {
        var nums = JSON.parse(arr.containerSealNums);
        for (var i = 0; i < nums.length; i++) {
          if (i > 0) {
            str += "\r\n";
          }
          str += '箱' + (i + 1);
          if (nums[i].identity) {
            str += ' 箱号：' + nums[i].identity;
          }
          if (nums[i].sealIdentity) {
            str += ' 铅封号：' + nums[i].sealIdentity;
          }
        }
      } else if (arr.firstPlaceFee) {
        str += arr.firstPlaceFee;
      } else if (arr.driverCarNumber) {
        str += '车牌号：' + arr.driverCarNumber;
      } else if (arr.urgentCost) {
        str += '加急费：' + arr.urgentCost;
      } else if (arr.freightDriverCost) {
        str += '司机运费：' + arr.freightDriverCost;
      } else if (arr.remark) {
        str += '备注：' + arr.remark;
      } else if (arr.replenish) {
        str += '补充费用：' + arr.replenish;
      } else if (arr.outStock) {
        str += '无货回空：' + arr.outStock;
      } else if (arr.containerAmount) {
        str += '撤单箱量:' + arr.containerAmount;
      }
      return str;
    },
    keyToText: function keyToText(key) {
      var arr = {
        'containerTotalAmount': '剩余箱量',
        'deliveryEstimateTotalCost': '预计总配送费',
        'acceptEndTime': '接单截止时间',
        'driverCarNumber': '车牌号',
        'urgentCost': '加急费',
        'freightDriverCost': '司机运费',
        'remark': '备注',
        'replenish': "补充费用",
        "containerAmount": "撤单箱量"
      };
      if (arr[key]) {
        return arr[key];
      } else {
        return key;
      }
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(id) {
      var _this2 = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this2.initFormData(res.data);
        _this2.editDialogTitle = _this2.formData.handle;
        _this2.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this3 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this3.$api[_this3.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this3.$message.success('删除成功');
            _this3.query();
          } else {
            _this3.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this5 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this5.formData.id) {
            _this5.$api[_this5.pageConfig.modifyFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('编辑成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('编辑失败');
              }
            });
          } else {
            _this5.$api[_this5.pageConfig.addFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('新增成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};