import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Teamcar_page from './teamcar_page.vue';
export default {
  name: 'logistics_logs',
  components: {
    orderPage: function orderPage() {
      return import('@/views/logistics_hongde/logs/order_page.vue');
    },
    waybillorderPage: function waybillorderPage() {
      return import('@/views/logistics_hongde/logs/waybill_page.vue');
    },
    driverPage: function driverPage() {
      return import('@/views/logistics_hongde/logs/driver_page.vue');
    },
    merchantPage: function merchantPage() {
      return import('@/views/logistics_hongde/logs/merchant_page.vue');
    },
    shipCompanyPage: function shipCompanyPage() {
      return import('@/views/logistics_hongde/logs/ship_company_page.vue');
    },
    teamcarPage: function teamcarPage() {
      return import('@/views/logistics_hongde/logs/teamcar_page.vue');
    }
  },
  data: function data() {
    return {
      radio: ''
    };
  },
  mounted: function mounted() {},
  methods: {}
};