export default (function (_ref) {
  var request = _ref.request;
  return {
    websiteOutlinkAdd: function websiteOutlinkAdd(data) {
      return request({
        url: '/website/website/outlink',
        method: 'post',
        data: data
      });
    },
    websiteOutlinkModify: function websiteOutlinkModify(data) {
      return request({
        url: "/website/website/outlink/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    websiteOutlinkDel: function websiteOutlinkDel(id) {
      return request({
        url: "/website/website/outlink/".concat(id),
        method: 'delete'
      });
    },
    websiteOutlinkQuery: function websiteOutlinkQuery(userId, data) {
      return request({
        url: "/website/website/outlink/".concat(userId, "/query"),
        method: 'get',
        params: data
      });
    },
    websiteOutlinkDetail: function websiteOutlinkDetail(id) {
      return request({
        url: "/website/website/outlink/".concat(id),
        method: 'get'
      });
    },
    websiteOutlinkClearCache: function websiteOutlinkClearCache() {
      return request({
        url: '/website/website/outlink/cacheClear',
        method: 'post'
      });
    }
  };
});