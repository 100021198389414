var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.tableShow === "driver" ? _c("driverTable", {
    on: {
      "child-go": _vm.goChild
    }
  }) : _vm._e(), _vm.tableShow === "driverEval" ? _c("driverEvalTable", {
    attrs: {
      currentDriver: _vm.currentDriver
    },
    on: {
      "child-go-driver": _vm.goDriver
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };