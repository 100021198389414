import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import dayjs from 'dayjs';
export default {
  name: 'groupbuy_vip_order',
  data: function data() {
    return {
      dayjs: dayjs,
      currentUser: localStorage.getItem('userId'),
      pageConfig: {
        editPageName: '拼团VIP订单',
        delFunName: 'groupBuyVipOrderDel',
        queryFunName: 'groupBuyVipOrderQueryJoinCompanyAndUser',
        detailFunName: 'groupBuyVipOrderDetail',
        successFunName: 'groupBuyVipOrderSuccess'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {
        id: null,
        userId: null,
        companyId: null,
        productId: null,
        productName: null,
        productLogo: null,
        productOriginalCost: null,
        productCurrentCost: null,
        productVipDuration: null,
        productVipDurationUnit: null,
        orderFinalCost: null,
        userOpenid: null,
        payCategory: null,
        payType: null,
        state: null,
        payTime: null,
        cancelType: null,
        cancelTime: null,
        createTime: null,
        companyName: null,
        companyLogo: null,
        userNickname: null,
        userImage: null
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    detail: function detail(rowData) {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](rowData.id).then(function (res) {
        _this.initFormData(res.data);
        _this.formData.companyName = rowData.companyName;
        _this.formData.companyLogo = rowData.companyLogo;
        _this.formData.userNickname = rowData.userNickname;
        _this.formData.userImage = rowData.userImage;
        _this.editDialogTitle = _this.pageConfig.editPageName + '明细';
        _this.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};