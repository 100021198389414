import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键字"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "关键字"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "分类"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.firstCategory,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "firstCategory", $$v);
      },
      expression: "searchForm.firstCategory"
    }
  }, _vm._l(_vm.dicData.activityCategorys, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  }), _vm._v(" 添加")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      fixed: "",
      label: "序号",
      type: "index",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      fixed: "",
      label: "操作",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.edit(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c("el-dropdown", {
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            size: "mini",
            "split-button": "",
            type: "primary"
          },
          on: {
            command: _vm.moreButton
          }
        }, [_vm._v(" 更多 "), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [!scope.row.onlineState ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-s-promotion",
            command: _vm.beforeCommand(scope.$index, scope.row, "online")
          }
        }, [_vm._v("上架活动")]) : _vm._e(), scope.row.onlineState ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-s-release",
            command: _vm.beforeCommand(scope.$index, scope.row, "unOnline")
          }
        }, [_vm._v("下架活动")]) : _vm._e(), scope.row.onlineState && !scope.row.indexPushState ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-star-on",
            command: _vm.beforeCommand(scope.$index, scope.row, "push")
          }
        }, [_vm._v("首页推荐")]) : _vm._e(), scope.row.indexPushState ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-s-operation",
            command: _vm.beforeCommand(scope.$index, scope.row, "sort")
          }
        }, [_vm._v("首推排序")]) : _vm._e(), scope.row.indexPushState ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-star-off",
            command: _vm.beforeCommand(scope.$index, scope.row, "unPush")
          }
        }, [_vm._v("撤销首推")]) : _vm._e(), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-refresh",
            command: _vm.beforeCommand(scope.$index, scope.row, "clear")
          }
        }, [_vm._v("刷新活动")]), _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-delete",
            command: _vm.beforeCommand(scope.$index, scope.row, "del")
          }
        }, [_vm._v("删除活动")])], 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      fixed: "",
      prop: "name",
      label: "名称",
      "min-width": "200",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "活动信息",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "indexPushState,^indexPushSort",
      label: "首推",
      sortable: "custom",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.indexPushState ? _c("span", {
          staticClass: "text-style"
        }, [_vm._v("⭐ (" + _vm._s(scope.row.indexPushSort) + ")")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "onlineState",
      label: "状态",
      sortable: "custom",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.onlineState ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("上架")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "firstCategory,secondCategory",
      label: "分类",
      sortable: "custom",
      width: "130",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.firstCategory ? _c("span", [_vm._v(_vm._s(_vm.dicData.activityCategoryOptions[scope.row.firstCategory]))]) : _vm._e(), scope.row.secondCategory ? _c("span", [_vm._v(" / " + _vm._s(_vm.dicData.activitySecondCategoryOptions[scope.row.secondCategory]))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "companyName",
      label: "活动方",
      "min-width": "150",
      sortable: "custom",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "库存",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "circulateInventory",
      label: "剩余",
      sortable: "custom",
      width: "80",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.circulateInventory ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v(_vm._s(scope.row.circulateInventory))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "frozenInventory",
      label: "冻结",
      sortable: "custom",
      width: "80",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.frozenInventory ? _c("span", {
          staticClass: "text-pause"
        }, [_vm._v(_vm._s(scope.row.frozenInventory))]) : _vm._e()];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "价格",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "advanceCost",
      label: "预付价",
      sortable: "custom",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.advanceCost ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v(_vm._s((scope.row.advanceCost / 100).toFixed(2)))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "originalCost",
      label: "原价",
      sortable: "custom",
      width: "80",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.originalCost ? _c("span", {
          staticClass: "text-pause"
        }, [_vm._v(_vm._s((scope.row.originalCost / 100).toFixed(2)))]) : _vm._e()];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "浏览量",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "fakeBrowseAmount",
      label: "虚拟",
      sortable: "custom",
      width: "80",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.fakeBrowseAmount ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v(_vm._s(scope.row.fakeBrowseAmount))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "browseAmount",
      label: "真实",
      sortable: "custom",
      width: "80",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.browseAmount ? _c("span", {
          staticClass: "text-pause"
        }, [_vm._v(_vm._s(scope.row.browseAmount))]) : _vm._e()];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "活动时间",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "beginTime",
      label: "开始时间",
      sortable: "custom",
      width: "155"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.dateFormat2Str(scope.row.beginTime)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "endTime",
      label: "结束时间",
      sortable: "custom",
      width: "155"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.dateFormat2Str(scope.row.endTime)))])];
      }
    }])
  })], 1)], 1), _c("el-table-column", {
    attrs: {
      label: "订单信息",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "订单数",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "总量",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          staticClass: "text-interrupt"
        }, [_vm._v(_vm._s(scope.row.orderEndAmount + scope.row.orderRefundAmount))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderEndAmount",
      label: "未退款",
      sortable: "custom",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderEndAmount ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v(_vm._s(scope.row.orderEndAmount))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderRefundAmount",
      label: "已退款",
      sortable: "custom",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderRefundAmount ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v(_vm._s(scope.row.orderRefundAmount))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderRefundUseAmount",
      label: "退核",
      sortable: "custom",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderRefundUseAmount ? _c("span", {
          staticClass: "text-style"
        }, [_vm._v(_vm._s(scope.row.orderRefundUseAmount))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderRefundUnUseAmount",
      label: "退不核",
      sortable: "custom",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderRefundUnUseAmount ? _c("span", {
          staticClass: "text-pause"
        }, [_vm._v(_vm._s(scope.row.orderRefundUnUseAmount))]) : _vm._e()];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "订单费用",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "总费用",
      width: "110",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          staticClass: "text-interrupt"
        }, [_vm._v(_vm._s(((scope.row.orderEndCost + scope.row.orderRefundUseCost + scope.row.orderRefundUnUseCost) / 100).toFixed(2)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderEndCost",
      label: "未退款",
      sortable: "custom",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderEndCost ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v(_vm._s((scope.row.orderEndCost / 100).toFixed(2)))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderRefundUseCost",
      label: "退核",
      sortable: "custom",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderRefundUseCost ? _c("span", {
          staticClass: "text-style"
        }, [_vm._v(_vm._s((scope.row.orderRefundUseCost / 100).toFixed(2)))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderRefundUnUseCost",
      label: "退不核",
      sortable: "custom",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderRefundUnUseCost ? _c("span", {
          staticClass: "text-pause"
        }, [_vm._v(_vm._s((scope.row.orderRefundUnUseCost / 100).toFixed(2)))]) : _vm._e()];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "订单结算",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "orderWaitSettlementCost",
      label: "待结算",
      sortable: "custom",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderWaitSettlementCost ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v(_vm._s((scope.row.orderWaitSettlementCost / 100).toFixed(2)))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "orderEndSettlementCost",
      label: "已结算",
      sortable: "custom",
      width: "90",
      "header-align": "left",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderEndSettlementCost ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v(_vm._s((scope.row.orderEndSettlementCost / 100).toFixed(2)))]) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.editDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initFormData(_vm.emptyFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDataVerify",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-collapse", {
    attrs: {
      value: _vm.formData.defaultCollapseOpen
    }
  }, [_c("el-collapse-item", {
    attrs: {
      name: "activity_info"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    staticClass: "header-icon el-icon-star-on",
    staticStyle: {
      "margin-right": "10px"
    }
  }), _vm._v(" 活动信息 ")]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "标题",
      "label-width": _vm.formLabelWidth,
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "标题"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "子标题",
      "label-width": _vm.formLabelWidth,
      prop: "subName"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "子标题"
    },
    model: {
      value: _vm.formData.subName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "subName", $$v);
      },
      expression: "formData.subName"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "活动方",
      "label-width": _vm.formLabelWidth,
      prop: "companyId"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请选择活动方",
      readonly: ""
    },
    model: {
      value: _vm.formData.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "companyName", $$v);
      },
      expression: "formData.companyName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-button", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      icon: "el-icon-search",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.companySelData.isShow = true;
      }
    }
  }, [_vm._v("选择")])], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "上架状态",
      "label-width": _vm.formLabelWidth,
      prop: "onlineState"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: true
    },
    model: {
      value: _vm.formData.onlineState,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "onlineState", $$v);
      },
      expression: "formData.onlineState"
    }
  }, [_vm._v("上架")]), _c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: false
    },
    model: {
      value: _vm.formData.onlineState,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "onlineState", $$v);
      },
      expression: "formData.onlineState"
    }
  }, [_vm._v("下架")])], 1)])], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "活动分类",
      "label-width": _vm.formLabelWidth,
      prop: "firstCategory"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择一级分类",
      filterable: "",
      clearable: ""
    },
    on: {
      change: _vm.changeFormFirstCategory
    },
    model: {
      value: _vm.formData.firstCategory,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "firstCategory", $$v);
      },
      expression: "formData.firstCategory"
    }
  }, _vm._l(_vm.dicData.activityCategorys, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "secondCategory"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择二级分类",
      filterable: "",
      clearable: ""
    },
    model: {
      value: _vm.formData.secondCategory,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "secondCategory", $$v);
      },
      expression: "formData.secondCategory"
    }
  }, _vm._l(_vm.formData.activitySecondeCategorys, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "浏览量",
      "label-width": _vm.formLabelWidth,
      prop: "fakeBrowseAmount"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "虚拟浏览量"
    },
    model: {
      value: _vm.formData.fakeBrowseAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "fakeBrowseAmount", $$v);
      },
      expression: "formData.fakeBrowseAmount"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "browseAmount"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "真实浏览量",
      disabled: ""
    },
    model: {
      value: _vm.formData.browseAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "browseAmount", $$v);
      },
      expression: "formData.browseAmount"
    }
  })], 1)], 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "原价/预付",
      "label-width": _vm.formLabelWidth,
      prop: "originalCost"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "原价"
    },
    model: {
      value: _vm.formData.originalCost,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "originalCost", $$v);
      },
      expression: "formData.originalCost"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "advanceCost"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "预付价"
    },
    model: {
      value: _vm.formData.advanceCost,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "advanceCost", $$v);
      },
      expression: "formData.advanceCost"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "活动库存",
      "label-width": _vm.formLabelWidth,
      prop: "circulateInventory"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "剩余库存"
    },
    model: {
      value: _vm.formData.circulateInventory,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "circulateInventory", $$v);
      },
      expression: "formData.circulateInventory"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "frozenInventory"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "冻结库存",
      disabled: ""
    },
    model: {
      value: _vm.formData.frozenInventory,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "frozenInventory", $$v);
      },
      expression: "formData.frozenInventory"
    }
  })], 1)], 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开始时间",
      "label-width": _vm.formLabelWidth,
      prop: "beginTime"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "datetime",
      placeholder: "请选择开始时间",
      "value-format": "yyyyMMddHHmmss"
    },
    model: {
      value: _vm.formData.beginTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "beginTime", $$v);
      },
      expression: "formData.beginTime"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "结束时间",
      "label-width": _vm.formLabelWidth,
      prop: "endTime"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "datetime",
      placeholder: "请选择结束时间",
      "value-format": "yyyyMMddHHmmss"
    },
    model: {
      value: _vm.formData.endTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "endTime", $$v);
      },
      expression: "formData.endTime"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "列表图片",
      "label-width": _vm.formLabelWidth,
      prop: "listImage"
    }
  }, [!_vm.formData.listImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadListImage
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.listImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.listImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.listImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeListImage();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.formData.listImage);
      }
    }
  })])])])]) : _vm._e()], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "下架图片",
      "label-width": _vm.formLabelWidth,
      prop: "unonlineImg"
    }
  }, [!_vm.formData.unonlineImg ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadUnonlineImg
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.unonlineImg ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.unonlineImg ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.unonlineImg
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeUnonlineImg();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.formData.unonlineImg);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "分享图片",
      "label-width": _vm.formLabelWidth,
      prop: "shareImg"
    }
  }, [!_vm.formData.shareImg ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadShareImg
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.shareImg ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.shareImg ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.shareImg
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeShareImg();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.formData.shareImg);
      }
    }
  })])])])]) : _vm._e()], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "海报图片",
      "label-width": _vm.formLabelWidth,
      prop: "poster"
    }
  }, [!_vm.formData.poster ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadPoster
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.poster ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.poster ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.poster
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removePoster();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.formData.poster);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _c("el-row", [_c("el-form-item", {
    attrs: {
      label: "顶部轮播",
      "label-width": _vm.formLabelWidth,
      prop: "carousel"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      limit: 9,
      "file-list": _vm.formData.carouselArray,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadCarousel,
      "on-preview": _vm.previewCarouselFile,
      "on-remove": _vm.removeCarouselFile
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })])], 1)], 1), _c("el-row", [_c("el-form-item", {
    attrs: {
      label: "活动详情",
      "label-width": _vm.formLabelWidth,
      prop: "details"
    }
  }, [_c("d2-ueditor", {
    staticStyle: {
      width: "98%"
    },
    model: {
      value: _vm.formData.details,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "details", $$v);
      },
      expression: "formData.details"
    }
  })], 1)], 1)], 2), _c("el-collapse-item", {
    staticClass: "activity-option",
    attrs: {
      name: "option_info"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    staticClass: "header-icon el-icon-menu",
    staticStyle: {
      "margin-right": "10px"
    }
  }), _vm._v(" 活动选项 ")]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目说明",
      "label-width": _vm.formLabelWidth,
      prop: "courseExplain"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "项目说明"
    },
    model: {
      value: _vm.formData.courseExplain,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "courseExplain", $$v);
      },
      expression: "formData.courseExplain"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目三选项",
      "label-width": _vm.formLabelWidth,
      prop: "courseThird"
    }
  }, [_vm._l(_vm.formData.courseThirdArray, function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        size: "small",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.delThirdCourse(tag);
        }
      }
    }, [_vm._v(_vm._s(tag))]);
  }), _vm.thirdCourseInputShow ? _c("el-input", {
    ref: "saveThirdCourseInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      blur: _vm.handleThirdCourseInputConfirm
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleThirdCourseInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.tagTemp,
      callback: function callback($$v) {
        _vm.tagTemp = $$v;
      },
      expression: "tagTemp"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.showThirdCourseInput
    }
  }, [_vm._v("+ New")])], 2)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目一选项",
      "label-width": _vm.formLabelWidth,
      prop: "courseFirst"
    }
  }, [_vm._l(_vm.formData.courseFirstArray, function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        size: "small",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.delFirstCourse(tag);
        }
      }
    }, [_vm._v(_vm._s(tag))]);
  }), _vm.firstCourseInputShow ? _c("el-input", {
    ref: "saveFirstCourseInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      blur: _vm.handleFirstCourseInputConfirm
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFirstCourseInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.tagTemp,
      callback: function callback($$v) {
        _vm.tagTemp = $$v;
      },
      expression: "tagTemp"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.showFirstCourseInput
    }
  }, [_vm._v("+ New")])], 2)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目四选项",
      "label-width": _vm.formLabelWidth,
      prop: "courseFourth"
    }
  }, [_vm._l(_vm.formData.courseFourthArray, function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        size: "small",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.delFourthCourse(tag);
        }
      }
    }, [_vm._v(_vm._s(tag))]);
  }), _vm.fourthCourseInputShow ? _c("el-input", {
    ref: "saveFourthCourseInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      blur: _vm.handleFourthCourseInputConfirm
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFourthCourseInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.tagTemp,
      callback: function callback($$v) {
        _vm.tagTemp = $$v;
      },
      expression: "tagTemp"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.showFourthCourseInput
    }
  }, [_vm._v("+ New")])], 2)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目二选项",
      "label-width": _vm.formLabelWidth,
      prop: "courseSecond"
    }
  }, [_vm._l(_vm.formData.courseSecondArray, function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        size: "small",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.delSecondCourse(tag);
        }
      }
    }, [_vm._v(_vm._s(tag))]);
  }), _vm.secondCourseInputShow ? _c("el-input", {
    ref: "saveSecondCourseInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      blur: _vm.handleSecondCourseInputConfirm
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSecondCourseInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.tagTemp,
      callback: function callback($$v) {
        _vm.tagTemp = $$v;
      },
      expression: "tagTemp"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.showSecondCourseInput
    }
  }, [_vm._v("+ New")])], 2)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "项目五选项",
      "label-width": _vm.formLabelWidth,
      prop: "courseFifth"
    }
  }, [_vm._l(_vm.formData.courseFifthArray, function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        size: "small",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.delFifthCourse(tag);
        }
      }
    }, [_vm._v(_vm._s(tag))]);
  }), _vm.fifthCourseInputShow ? _c("el-input", {
    ref: "saveFifthCourseInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      blur: _vm.handleFifthCourseInputConfirm
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleFifthCourseInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.tagTemp,
      callback: function callback($$v) {
        _vm.tagTemp = $$v;
      },
      expression: "tagTemp"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.showFifthCourseInput
    }
  }, [_vm._v("+ New")])], 2)], 1)], 1)], 2), _c("el-collapse-item", {
    attrs: {
      name: "ladder_info"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    staticClass: "header-icon el-icon-s-unfold",
    staticStyle: {
      "margin-right": "10px"
    }
  }), _vm._v(" 阶梯价格 ")]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "第一阶梯价",
      "label-width": _vm.formLabelWidth,
      prop: "levelFirstCost"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "第一阶梯价格"
    },
    model: {
      value: _vm.formData.levelFirstCost,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "levelFirstCost", $$v);
      },
      expression: "formData.levelFirstCost"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "levelFirstAmount"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "第一阶梯数量"
    },
    model: {
      value: _vm.formData.levelFirstAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "levelFirstAmount", $$v);
      },
      expression: "formData.levelFirstAmount"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "第四阶梯价",
      "label-width": _vm.formLabelWidth,
      prop: "levelFourthCost"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "第四阶梯价格"
    },
    model: {
      value: _vm.formData.levelFourthCost,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "levelFourthCost", $$v);
      },
      expression: "formData.levelFourthCost"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "levelFourthAmount"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "第五阶梯数量"
    },
    model: {
      value: _vm.formData.levelFourthAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "levelFourthAmount", $$v);
      },
      expression: "formData.levelFourthAmount"
    }
  })], 1)], 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "第二阶梯价",
      "label-width": _vm.formLabelWidth,
      prop: "levelSecondCost"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "第二阶梯价格"
    },
    model: {
      value: _vm.formData.levelSecondCost,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "levelSecondCost", $$v);
      },
      expression: "formData.levelSecondCost"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "levelSecondAmount"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "第二阶梯数量"
    },
    model: {
      value: _vm.formData.levelSecondAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "levelSecondAmount", $$v);
      },
      expression: "formData.levelSecondAmount"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "第五阶梯价",
      "label-width": _vm.formLabelWidth,
      prop: "levelFifthCost"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "第五阶梯价格"
    },
    model: {
      value: _vm.formData.levelFifthCost,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "levelFifthCost", $$v);
      },
      expression: "formData.levelFifthCost"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "levelFifthAmount"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "第五阶梯数量"
    },
    model: {
      value: _vm.formData.levelFifthAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "levelFifthAmount", $$v);
      },
      expression: "formData.levelFifthAmount"
    }
  })], 1)], 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "第三阶梯价",
      "label-width": _vm.formLabelWidth,
      prop: "levelThirdCost"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "第三阶梯价格"
    },
    model: {
      value: _vm.formData.levelThirdCost,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "levelThirdCost", $$v);
      },
      expression: "formData.levelThirdCost"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "",
      prop: "levelThirdAmount"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "第三阶梯数量"
    },
    model: {
      value: _vm.formData.levelThirdAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "levelThirdAmount", $$v);
      },
      expression: "formData.levelThirdAmount"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  })], 1)], 2), _c("el-collapse-item", {
    attrs: {
      name: "org_info"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    staticClass: "header-icon el-icon-s-shop",
    staticStyle: {
      "margin-right": "10px"
    }
  }), _vm._v(" 机构信息 ")]), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "机构名称",
      "label-width": _vm.formLabelWidth,
      prop: "orgName"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "机构名称"
    },
    model: {
      value: _vm.formData.orgName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "orgName", $$v);
      },
      expression: "formData.orgName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "机构Logo",
      "label-width": _vm.formLabelWidth,
      prop: "orgLogo"
    }
  }, [!_vm.formData.orgLogo ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadOrgLogo
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.orgLogo ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.orgLogo ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.orgLogo
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeOrgLogo();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.formData.orgLogo);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-row", [_c("el-form-item", {
    attrs: {
      label: "机构简介",
      "label-width": _vm.formLabelWidth,
      prop: "orgRemark"
    }
  }, [_c("d2-ueditor", {
    staticStyle: {
      width: "98%"
    },
    model: {
      value: _vm.formData.orgRemark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "orgRemark", $$v);
      },
      expression: "formData.orgRemark"
    }
  })], 1)], 1)], 1)], 2)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确 定")])], 1)], 1), _vm.companySelData.isShow ? _c("groupbuyCompanySel", {
    attrs: {
      isShow: _vm.companySelData.isShow
    },
    on: {
      "child-company-sel": _vm.companySel
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };