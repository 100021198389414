var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.websiteTab === "navigation" ? _c("navigationTable", {
    on: {
      "child-go-article": _vm.goArticle
    }
  }) : _vm._e(), _vm.websiteTab === "article" ? _c("articleTable", {
    attrs: {
      currentNavigation: _vm.currentNavigation
    },
    on: {
      "child-go-navigation": _vm.goNavigation
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };