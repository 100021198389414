import dayjs from 'dayjs';
export default {
  name: 'edu_liyou_coupon',
  props: {
    currentCoupon: {
      type: Object
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '使用数据',
        queryFunName: 'eduLiyouCouponsUserQueryJoinUserAndCourse'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time',
        couponState: 'Use',
        couponsId: null,
        dateType: 'Create',
        starDate: null,
        endDate: null
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0
    };
  },
  mounted: function mounted() {
    this.searchForm.couponsId = this.currentCoupon.id;
    this.query();
  },
  methods: {
    back: function back() {
      this.$emit('child-go-coupon');
    },
    query: function query() {
      var _this = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this.tableData = res.data || [];
        _this.totalCount = res.count;
        _this.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};