export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeSizeModelAdd: function logisticHongdeSizeModelAdd(data) {
      return request({
        url: '/logistics_hongde/sizeModel',
        method: 'post',
        data: data
      });
    },
    logisticHongdeSizeModelModify: function logisticHongdeSizeModelModify(data) {
      return request({
        url: "/logistics_hongde/sizeModel/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    logisticHongdeSizeModelDel: function logisticHongdeSizeModelDel(id) {
      return request({
        url: "/logistics_hongde/sizeModel/".concat(id),
        method: 'delete'
      });
    },
    logisticHongdeSizeModelQuery: function logisticHongdeSizeModelQuery(data) {
      return request({
        url: '/logistics_hongde/sizeModel/query',
        method: 'get',
        params: data
      });
    },
    logisticHongdeSizeModelDetail: function logisticHongdeSizeModelDetail(id) {
      return request({
        url: "/logistics_hongde/sizeModel/".concat(id),
        method: 'get'
      });
    }
  };
});