import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d2-layout-header-aside-group",
    class: {
      grayMode: _vm.grayActive
    },
    style: _vm.styleLayoutMainGroup
  }, [_c("div", {
    staticClass: "d2-layout-header-aside-mask"
  }), _c("div", {
    staticClass: "d2-layout-header-aside-content",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("div", {
    staticClass: "d2-theme-header",
    style: {
      opacity: 1
    },
    attrs: {
      "flex-box": "0",
      flex: ""
    }
  }, [_c("router-link", {
    class: {
      "logo-group": true,
      "logo-transition": _vm.asideTransition
    },
    style: {
      width: _vm.asideCollapse ? _vm.asideWidthCollapse : _vm.asideWidth
    },
    attrs: {
      to: "/index",
      "flex-box": "0"
    }
  }, [_vm.asideCollapse ? _c("img", {
    attrs: {
      src: _vm.sysIndexLeftFoldLogo || "".concat(_vm.$baseUrl, "image/theme/").concat(_vm.themeActiveSetting.name, "/logo/icon-only.png")
    }
  }) : _c("img", {
    attrs: {
      src: _vm.sysIndexLeftLogo || "".concat(_vm.$baseUrl, "image/theme/").concat(_vm.themeActiveSetting.name, "/logo/all.png")
    }
  })]), _c("div", {
    staticClass: "toggle-aside-btn",
    attrs: {
      "flex-box": "0"
    },
    on: {
      click: _vm.handleToggleAside
    }
  }, [_c("d2-icon", {
    attrs: {
      name: "bars"
    }
  })], 1), _c("d2-menu-header", {
    attrs: {
      "flex-box": "1"
    }
  }), _c("div", {
    staticClass: "d2-header-right",
    attrs: {
      "flex-box": "0"
    }
  }, [_vm.halo6AdminUrl && _vm.currentUser === "Wechat:guan409932398" ? _c("div", {
    staticClass: "url",
    on: {
      click: _vm.outlinkClick
    }
  }, [_c("i", {
    staticClass: "iconproject icon-project-fuwuqijigui",
    staticStyle: {
      "font-size": "22px"
    }
  })]) : _vm._e(), _vm.ShowRemind ? _c("div", {
    staticClass: "notice",
    on: {
      click: function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_c("d2-icon", {
    attrs: {
      name: "bell-o"
    }
  }), _vm.noticeCount ? _c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.noticeCount))]) : _vm._e(), _c("audio", {
    ref: "initAudio",
    attrs: {
      controls: "",
      height: "10",
      width: "10"
    }
  }, [_c("source", {
    attrs: {
      src: require("../../assets/audio/notice.mp3"),
      type: "audio/mpeg"
    }
  })])], 1) : _vm._e(), _c("d2-header-fullscreen"), _c("d2-header-theme"), _c("d2-header-size"), _c("d2-header-color"), _c("d2-header-user")], 1)], 1), _c("div", {
    staticClass: "d2-theme-container",
    attrs: {
      "flex-box": "1",
      flex: ""
    }
  }, [_c("div", {
    ref: "aside",
    class: {
      "d2-theme-container-aside": true,
      "d2-theme-container-transition": _vm.asideTransition
    },
    style: {
      width: _vm.asideCollapse ? _vm.asideWidthCollapse : _vm.asideWidth,
      opacity: 1
    },
    attrs: {
      "flex-box": "0"
    }
  }, [_c("d2-menu-side")], 1), _c("div", {
    staticClass: "d2-theme-container-main",
    attrs: {
      "flex-box": "1",
      flex: ""
    }
  }, [_c("transition", {
    attrs: {
      name: "fade-scale"
    }
  }, [_c("div", {
    staticClass: "d2-theme-container-main-layer",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("div", {
    staticClass: "d2-theme-container-main-header",
    attrs: {
      "flex-box": "0"
    }
  }, [_c("d2-tabs")], 1), _c("div", {
    staticClass: "d2-theme-container-main-body",
    attrs: {
      "flex-box": "1"
    }
  }, [_c("transition", {
    attrs: {
      name: _vm.transitionActive ? "fade-transverse" : ""
    }
  }, [_c("keep-alive", {
    attrs: {
      include: _vm.keepAlive
    }
  }, [_c("router-view", {
    key: _vm.routerViewKey
  })], 1)], 1)], 1)])])], 1)])]), _vm.show ? _c("notice", {
    on: {
      close: function close($event) {
        _vm.show = false;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };