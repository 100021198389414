export default (function (_ref) {
  var request = _ref.request;
  return {
    groupBuyCompanyAdd: function groupBuyCompanyAdd(data) {
      return request({
        url: '/group_buy/company',
        method: 'post',
        data: data
      });
    },
    groupBuyCompanyModify: function groupBuyCompanyModify(data) {
      return request({
        url: "/group_buy/company/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    groupBuyCompanyDel: function groupBuyCompanyDel(id) {
      return request({
        url: "/group_buy/company/".concat(id),
        method: 'delete'
      });
    },
    groupBuyCompanyQueryJoinUser: function groupBuyCompanyQueryJoinUser(data) {
      return request({
        url: '/group_buy/company/queryJoinUser',
        method: 'get',
        params: data
      });
    },
    groupBuyCompanyDetail: function groupBuyCompanyDetail(id) {
      return request({
        url: "/group_buy/company/".concat(id),
        method: 'get'
      });
    },
    groupBuyCompanyAddRefundPower: function groupBuyCompanyAddRefundPower(id) {
      return request({
        url: "/group_buy/company/".concat(id, "/refundPower"),
        method: 'post'
      });
    },
    groupBuyCompanyRefundRefundPower: function groupBuyCompanyRefundRefundPower(id) {
      return request({
        url: "/group_buy/company/".concat(id, "/refundPower"),
        method: 'delete'
      });
    }
  };
});