import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'groupbuy_company_mng',
  components: {
    company: function company() {
      return import('./groupbuy_company_page');
    },
    companyStores: function companyStores() {
      return import('./groupbuy_company_store_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'company',
      currentCompany: null
    };
  },
  methods: {
    goCompany: function goCompany() {
      this.tableShow = 'company';
    },
    goChild: function goChild(data, tableName) {
      this.currentCompany = data;
      this.tableShow = tableName;
    }
  }
};