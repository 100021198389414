import { render, staticRenderFns } from "./ship_company_page.vue?vue&type=template&id=658a04cc&scoped=true"
import script from "./ship_company_page.vue?vue&type=script&lang=js"
export * from "./ship_company_page.vue?vue&type=script&lang=js"
import style0 from "./ship_company_page.vue?vue&type=style&index=0&id=658a04cc&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "658a04cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/riverlei/project/java/bgyy-project/front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('658a04cc')) {
      api.createRecord('658a04cc', component.options)
    } else {
      api.reload('658a04cc', component.options)
    }
    module.hot.accept("./ship_company_page.vue?vue&type=template&id=658a04cc&scoped=true", function () {
      api.rerender('658a04cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/logistics_hongde/logs/ship_company_page.vue"
export default component.exports