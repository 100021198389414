import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticHongdeDriverQuery: function logisticHongdeDriverQuery(data) {
      return request({
        url: '/logistics_hongde/driver/queryJoinUserAndStats',
        method: 'get',
        params: data
      });
    },
    logisticHongdeDriverModify: function logisticHongdeDriverModify(data) {
      return request({
        url: "/logistics_hongde/driver/".concat(data.id, "/auth"),
        method: 'post',
        data: data
      });
    },
    logisticHongdeDriverDel: function logisticHongdeDriverDel(id) {
      return request({
        url: "/logistics_hongde/driver/".concat(id),
        method: 'delete'
      });
    },
    logisticHongdeDriverDetail: function logisticHongdeDriverDetail(id) {
      return request({
        url: "/logistics_hongde/driver/detailForAdmin/".concat(id),
        method: 'get'
      });
    },
    logisticHongdeDriverEvaSwitch: function logisticHongdeDriverEvaSwitch(id, value) {
      return request({
        url: "/logistics_hongde/driver/".concat(id, "/evalSwitch"),
        method: 'post',
        params: {
          evalSwitch: value
        }
      });
    },
    logisticHongdeDriverAddQuota: function logisticHongdeDriverAddQuota(id, value) {
      return request({
        url: "/logistics_hongde/driver/".concat(id, "/addQuotaUnused"),
        method: 'post',
        params: {
          quota: value
        }
      });
    },
    logisticHongdeDriverSuccess: function logisticHongdeDriverSuccess(id, carTeam) {
      return request({
        url: "/logistics_hongde/driver/".concat(id, "/success/").concat(carTeam),
        method: 'get'
      });
    },
    logisticHongdeDriverFail: function logisticHongdeDriverFail(id, value) {
      return request({
        url: "/logistics_hongde/driver/".concat(id, "/fail"),
        method: 'get',
        params: {
          authMsg: value
        }
      });
    }
  };
});