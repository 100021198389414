export default (function (_ref) {
  var request = _ref.request;
  return {
    logisticsHongDeShipCompanyCarTeamAdd: function logisticsHongDeShipCompanyCarTeamAdd(data) {
      return request({
        url: '/logistics_hongde/shipCompanyCarTeam',
        method: 'post',
        data: data
      });
    },
    logisticsHongDeShipCompanyCarTeamDel: function logisticsHongDeShipCompanyCarTeamDel(id) {
      return request({
        url: "/logistics_hongde/shipCompanyCarTeam/".concat(id),
        method: 'delete'
      });
    },
    logisticsHongDeShipCompanyCarTeamQueryByShipCompany: function logisticsHongDeShipCompanyCarTeamQueryByShipCompany(shipCompanyId, data) {
      return request({
        url: "/logistics_hongde/shipCompanyCarTeam/".concat(shipCompanyId, "/queryByShipCompany"),
        method: 'get',
        params: data
      });
    }
  };
});