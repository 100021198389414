import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    staticClass: "search-form",
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "活动名称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "活动名称"
    },
    model: {
      value: _vm.searchForm.activityName,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "activityName", $$v);
      },
      expression: "searchForm.activityName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "报名信息"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "报名信息"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "团长昵称"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "团长昵称"
    },
    model: {
      value: _vm.searchForm.groupCaptainNickname,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "groupCaptainNickname", $$v);
      },
      expression: "searchForm.groupCaptainNickname"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "订单类型"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.virtualOrder,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "virtualOrder", $$v);
      },
      expression: "searchForm.virtualOrder"
    }
  }, [_c("el-option", {
    attrs: {
      label: "业务订单",
      value: "false"
    }
  }), _c("el-option", {
    attrs: {
      label: "虚拟订单",
      value: "true"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "团内身份"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.groupCaptainState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "groupCaptainState", $$v);
      },
      expression: "searchForm.groupCaptainState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "团长",
      value: "true"
    }
  }), _c("el-option", {
    attrs: {
      label: "团员",
      value: "false"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "核销状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.useState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "useState", $$v);
      },
      expression: "searchForm.useState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "待核销",
      value: "false"
    }
  }), _c("el-option", {
    attrs: {
      label: "已核销",
      value: "true"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "退款状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.refundState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "refundState", $$v);
      },
      expression: "searchForm.refundState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "已申请",
      value: "apply"
    }
  }), _c("el-option", {
    attrs: {
      label: "已退款",
      value: "success"
    }
  }), _c("el-option", {
    attrs: {
      label: "退款失败",
      value: "fail"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "结算状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.settlementState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "settlementState", $$v);
      },
      expression: "searchForm.settlementState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "待结算",
      value: "false"
    }
  }), _c("el-option", {
    attrs: {
      label: "已结算",
      value: "true"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "下单开始"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: "选择下单开始日期",
      "value-format": "yyyyMMdd"
    },
    model: {
      value: _vm.searchForm.starDate,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "starDate", $$v);
      },
      expression: "searchForm.starDate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "下单结束"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: "选择下单结束日期",
      "value-format": "yyyyMMdd"
    },
    model: {
      value: _vm.searchForm.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "endDate", $$v);
      },
      expression: "searchForm.endDate"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  }), _vm._v(" 添加")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      fixed: "",
      label: "操作",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.detail(scope.row);
            }
          }
        }, [_vm._v("明细")]), _c("el-dropdown", {
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            size: "mini",
            "split-button": "",
            type: "primary"
          },
          on: {
            command: _vm.moreButton
          }
        }, [_vm._v(" 更多 "), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [!scope.row.virtualOrder ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-edit-outline",
            command: _vm.beforeCommand(scope.$index, scope.row, "edit")
          }
        }, [_vm._v("维护报名")]) : _vm._e(), scope.row.groupPersonAmount > 1 ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-s-flag",
            command: _vm.beforeCommand(scope.$index, scope.row, "alone")
          }
        }, [_vm._v("独立成团")]) : _vm._e(), !scope.row.groupCaptainState ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-caret-top",
            command: _vm.beforeCommand(scope.$index, scope.row, "upgrade")
          }
        }, [_vm._v("升为团长")]) : _vm._e(), scope.row.groupCaptainState && scope.row.groupPersonAmount > 1 ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-caret-bottom",
            command: _vm.beforeCommand(scope.$index, scope.row, "demotion")
          }
        }, [_vm._v("降为团员")]) : _vm._e(), scope.row.state === "end" || scope.row.state === "refund" && scope.row.refundUseState ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-rank",
            command: _vm.beforeCommand(scope.$index, scope.row, "move")
          }
        }, [_vm._v("更换团队")]) : _vm._e(), !scope.row.virtualOrder && (scope.row.state === "end" || scope.row.state === "refund" && scope.row.refundUseState) ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-document-checked",
            command: _vm.beforeCommand(scope.$index, scope.row, "use")
          }
        }, [_vm._v("报名核销")]) : _vm._e(), !scope.row.virtualOrder && scope.row.state === "end" && scope.row.refundState !== "apply" && !scope.row.useState ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-back",
            command: _vm.beforeCommand(scope.$index, scope.row, "refund")
          }
        }, [_vm._v("报名退款")]) : _vm._e(), scope.row.virtualOrder ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-delete",
            command: _vm.beforeCommand(scope.$index, scope.row, "del")
          }
        }, [_vm._v("删除报名")]) : _vm._e()], 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      fixed: "",
      prop: "virtualOrder",
      label: "类型",
      sortable: "custom",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row.virtualOrder ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("业务")]) : _vm._e(), scope.row.virtualOrder ? _c("span", {
          staticClass: "text-pause"
        }, [_vm._v("虚拟")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      fixed: "",
      prop: "activityName",
      label: "活动名称",
      sortable: "custom",
      "min-width": "200",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      fixed: "",
      prop: "userImage",
      label: "用户头像",
      width: "101"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-image", {
          staticStyle: {
            width: "80px",
            height: "80px"
          },
          attrs: {
            src: scope.row.userImage,
            fit: "fill"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      fixed: "",
      prop: "userNickname",
      label: "用户昵称",
      sortable: "custom",
      "min-width": "150",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "团队信息",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "groupCaptainState",
      label: "身份",
      sortable: "custom",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.groupCaptainState ? _c("span", {
          staticClass: "text-interrupt"
        }, [_vm._v("团长")]) : _vm._e(), !scope.row.groupCaptainState ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("团员")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "groupCaptainNickname",
      label: "团长",
      sortable: "custom",
      "min-width": "150",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "groupPersonAmount",
      label: "人数",
      sortable: "custom",
      width: "80"
    }
  })], 1), _c("el-table-column", {
    attrs: {
      label: "报名信息",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "state",
      label: "状态",
      sortable: "custom",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.state === "wait_pay" ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("待支付")]) : _vm._e(), scope.row.state === "end" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("完成")]) : _vm._e(), scope.row.state === "refund" ? _c("span", {
          staticClass: "text-interrupt"
        }, [_vm._v("退款")]) : _vm._e(), scope.row.state === "cancel" ? _c("span", {
          staticClass: "text-pause"
        }, [_vm._v("取消")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "姓名",
      sortable: "custom",
      width: "110"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "phone",
      label: "手机",
      sortable: "custom",
      width: "110"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "age",
      label: "年龄",
      sortable: "custom",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "orderFinalCost",
      label: "金额",
      sortable: "custom",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row.virtualOrder ? _c("span", {
          staticClass: "text-style"
        }, [_vm._v(_vm._s((scope.row.orderFinalCost || 0) / 100))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "courseFirst",
      label: "项目一",
      sortable: "custom",
      width: "110",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "courseSecond",
      label: "项目二",
      sortable: "custom",
      width: "110",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "courseThird",
      label: "项目三",
      sortable: "custom",
      width: "110",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "courseFourth",
      label: "项目四",
      sortable: "custom",
      width: "110",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "courseFifth",
      label: "项目五",
      sortable: "custom",
      width: "110",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "payTime",
      label: "报名时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.payTime ? _vm.dayjs(scope.row.payTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "推荐人",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "inviterUserImage",
      label: "头像",
      width: "101"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-image", {
          staticStyle: {
            width: "80px",
            height: "80px"
          },
          attrs: {
            src: scope.row.inviterUserImage,
            fit: "fill"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "inviterUserNickname",
      label: "昵称",
      sortable: "custom",
      "min-width": "150",
      "show-overflow-tooltip": ""
    }
  })], 1), _c("el-table-column", {
    attrs: {
      label: "核销/结算/退款",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "settlementState",
      label: "结算状态",
      sortable: "custom",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.settlementState === null ? _c("span") : _vm._e(), !scope.row.settlementState ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("待结算")]) : _vm._e(), scope.row.settlementState ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("已结算")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "settlementTime",
      label: "结算时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.settlementTime ? _vm.dayjs(scope.row.settlementTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "useState",
      label: "核销状态",
      sortable: "custom",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.useState === null ? _c("span") : _vm._e(), !scope.row.useState ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("待核销")]) : _vm._e(), scope.row.useState ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("已核销")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "useTime",
      label: "核销时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.useTime ? _vm.dayjs(scope.row.useTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "refundState",
      label: "退款状态",
      sortable: "custom",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.refundState === "apply" ? _c("span", {
          staticClass: "text-bug"
        }, [_vm._v("已申请")]) : _vm._e(), scope.row.refundState === "success" ? _c("span", {
          staticClass: "text-suggest"
        }, [_vm._v("已退款")]) : _vm._e(), scope.row.refundState === "fail" ? _c("span", {
          staticClass: "text-collapse"
        }, [_vm._v("退款失败")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "refundTime",
      label: "退款时间",
      sortable: "custom",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.refundTime ? _vm.dayjs(scope.row.refundTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  })], 1)], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.virtualDialogShow,
      title: "虚拟订单新增",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.virtualDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initVirtualData(_vm.emptyVirtualData);
      }
    }
  }, [_c("el-form", {
    ref: "virtualDataVerify",
    attrs: {
      model: _vm.virtualData,
      rules: _vm.virtualRules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "用户昵称",
      "label-width": _vm.formLabelWidth,
      prop: "userNickname"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "用户昵称"
    },
    model: {
      value: _vm.virtualData.userNickname,
      callback: function callback($$v) {
        _vm.$set(_vm.virtualData, "userNickname", $$v);
      },
      expression: "virtualData.userNickname"
    }
  })], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "拼团活动",
      "label-width": _vm.formLabelWidth,
      prop: "activityId"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请选择拼团活动",
      readonly: ""
    },
    model: {
      value: _vm.virtualData.activityName,
      callback: function callback($$v) {
        _vm.$set(_vm.virtualData, "activityName", $$v);
      },
      expression: "virtualData.activityName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-button", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      icon: "el-icon-search",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.activitySelData.isShow = true;
      }
    }
  }, [_vm._v("选择")])], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "归属团队",
      "label-width": _vm.formLabelWidth,
      prop: "groupId"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请选择归属团队(不选为团长)",
      readonly: ""
    },
    model: {
      value: _vm.virtualData.groupCaptainNickname,
      callback: function callback($$v) {
        _vm.$set(_vm.virtualData, "groupCaptainNickname", $$v);
      },
      expression: "virtualData.groupCaptainNickname"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm.virtualData.activityId ? _c("el-button", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      icon: "el-icon-search",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.activityOrderSelData.isShow = true;
      }
    }
  }, [_vm._v("选择")]) : _vm._e()], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "用户头像",
      "label-width": _vm.formLabelWidth,
      prop: "userImage"
    }
  }, [!_vm.virtualData.userImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.uploadVirtualDataUserImage
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.virtualData.userImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.virtualData.userImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.virtualData.userImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeVirtualDataUserImage();
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.virtualData.userImage);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeVirtualDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitVirtualData
    }
  }, [_vm._v("确 定")])], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.editDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initFormData(_vm.emptyFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDataVerify",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "订单状态",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm.formData.virtualOrder ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("虚拟订单")]) : _vm._e(), !_vm.formData.virtualOrder ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("业务订单")]) : _vm._e(), _vm._v(" 【 "), _vm.formData.state === "wait_pay" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("待支付")]) : _vm._e(), _vm.formData.state === "end" ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("已支付")]) : _vm._e(), _vm.formData.state === "refund" ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("已退款")]) : _vm._e(), _vm.formData.state === "cancel" ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("已取消")]) : _vm._e(), _vm._v(" 】 ")])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "订单编号",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.formData.id))])])])], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "费用 / 用户",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(((_vm.formData.orderFinalCost || 0) / 100).toFixed(2)))]), _vm._v(" 【 "), _c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.formData.userNickname))]), _vm._v(" 】 ")])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "用户ID",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.formData.userId))])])])], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "报名姓名",
      "label-width": _vm.formLabelWidth,
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "报名姓名"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "报名手机",
      "label-width": _vm.formLabelWidth,
      prop: "phone"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "报名手机"
    },
    model: {
      value: _vm.formData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "报名年龄",
      "label-width": _vm.formLabelWidth,
      prop: "age"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "报名年龄"
    },
    model: {
      value: _vm.formData.age,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "age", $$v);
      },
      expression: "formData.age"
    }
  })], 1), _vm.formData.courseFirstArray || _vm.formData.courseFirst ? _c("el-form-item", {
    attrs: {
      label: "报名项目一",
      "label-width": _vm.formLabelWidth,
      prop: "courseFirst"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.formData.courseFirst,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "courseFirst", $$v);
      },
      expression: "formData.courseFirst"
    }
  }, _vm._l(_vm.formData.courseFirstArray, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.courseSecondArray || _vm.formData.courseSecond ? _c("el-form-item", {
    attrs: {
      label: "报名项目二",
      "label-width": _vm.formLabelWidth,
      prop: "courseSecond"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.formData.courseSecond,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "courseSecond", $$v);
      },
      expression: "formData.courseSecond"
    }
  }, _vm._l(_vm.formData.courseSecondArray, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.courseThirdArray || _vm.formData.courseThird ? _c("el-form-item", {
    attrs: {
      label: "报名项目三",
      "label-width": _vm.formLabelWidth,
      prop: "courseThird"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.formData.courseThird,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "courseThird", $$v);
      },
      expression: "formData.courseThird"
    }
  }, _vm._l(_vm.formData.courseThirdArray, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.courseFourthArray || _vm.formData.courseFourth ? _c("el-form-item", {
    attrs: {
      label: "报名项目四",
      "label-width": _vm.formLabelWidth,
      prop: "courseFourth"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.formData.courseFourth,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "courseFourth", $$v);
      },
      expression: "formData.courseFourth"
    }
  }, _vm._l(_vm.formData.courseFourthArray, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e(), _vm.formData.courseFifthArray || _vm.formData.courseFifth ? _c("el-form-item", {
    attrs: {
      label: "报名项目五",
      "label-width": _vm.formLabelWidth,
      prop: "courseFifth"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.formData.courseFifth,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "courseFifth", $$v);
      },
      expression: "formData.courseFifth"
    }
  }, _vm._l(_vm.formData.courseFifthArray, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "用户头像",
      "label-width": _vm.formLabelWidth
    }
  }, [!_vm.formData.userImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.userImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.userImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.userImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.formData.userImage);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确 定")])], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.detailDialogShow,
      title: "报名详情",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.detailDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initDetailData(_vm.emptyDetailData);
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.detailData
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "left"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "报名信息"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "订单状态",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm.detailData.virtualOrder ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("虚拟订单")]) : _vm._e(), !_vm.detailData.virtualOrder ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("业务订单")]) : _vm._e(), _vm._v(" 【 "), _vm.detailData.state === "wait_pay" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("待支付")]) : _vm._e(), _vm.detailData.state === "end" ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("已支付")]) : _vm._e(), _vm.detailData.state === "refund" ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("已退款")]) : _vm._e(), _vm.detailData.state === "cancel" ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("已取消")]) : _vm._e(), _vm._v(" 】 ")])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "订单编号",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.id))])])])], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "费用 / 用户",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(((_vm.detailData.orderFinalCost || 0) / 100).toFixed(2)))]), _vm._v(" 【 "), _c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.userNickname))]), _vm._v(" 】 ")])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [!_vm.detailData.virtualOrder ? _c("el-form-item", {
    attrs: {
      label: "用户ID",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.userId))])])]) : _vm._e()], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm.detailData.virtualOrder ? _c("el-form-item", {
    attrs: {
      label: "",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  })])]) : _vm._e(), _vm.detailData.name ? _c("el-form-item", {
    attrs: {
      label: "报名姓名",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.name))])])]) : _vm._e(), _vm.detailData.phone ? _c("el-form-item", {
    attrs: {
      label: "报名手机",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.phone))])])]) : _vm._e(), _vm.detailData.age ? _c("el-form-item", {
    attrs: {
      label: "报名年龄",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.age))])])]) : _vm._e(), _vm.detailData.courseFirst ? _c("el-form-item", {
    attrs: {
      label: "报名项目一",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.courseFirst))])])]) : _vm._e(), _vm.detailData.courseSecond ? _c("el-form-item", {
    attrs: {
      label: "报名项目二",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.courseSecond))])])]) : _vm._e(), _vm.detailData.courseThird ? _c("el-form-item", {
    attrs: {
      label: "报名项目三",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.courseThird))])])]) : _vm._e(), _vm.detailData.courseFourth ? _c("el-form-item", {
    attrs: {
      label: "报名项目四",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.courseFourth))])])]) : _vm._e(), _vm.detailData.courseFifth ? _c("el-form-item", {
    attrs: {
      label: "报名项目五",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.courseFifth))])])]) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", [_c("el-form-item", {
    attrs: {
      label: "用户头像",
      "label-width": _vm.formLabelWidth
    }
  }, [!_vm.detailData.userImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.detailData.userImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.detailData.userImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.detailData.userImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.detailData.userImage);
      }
    }
  })])])])]) : _vm._e()], 1)], 1), _vm.detailData.refundShow ? _c("el-row", {
    staticStyle: {
      "box-shadow": "0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)",
      border: "5px"
    }
  }, [_c("el-row", {
    staticStyle: {
      "line-height": "40px",
      "text-align": "center"
    }
  }, [_vm._v(" 请选择退款后是否允许核销 ")]), _c("el-row", {
    staticStyle: {
      "line-height": "40px",
      "text-align": "center"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      round: ""
    },
    on: {
      click: function click($event) {
        return _vm.submitRefund(_vm.detailData.id, false);
      }
    }
  }, [_vm._v("不可核销")]), _c("el-button", {
    attrs: {
      type: "danger",
      size: "mini",
      round: ""
    },
    on: {
      click: function click($event) {
        return _vm.submitRefund(_vm.detailData.id, true);
      }
    }
  }, [_vm._v("可核销")])], 1)], 1) : _vm._e()], 1)], 1)], 1), _c("el-tab-pane", {
    attrs: {
      label: "活动 / 机构"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "活动名称",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.activityName))])])]), _c("el-form-item", {
    attrs: {
      label: "预付 / 原价",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-suggest"
  }, [_vm._v(_vm._s(_vm.detailData.activityAdvanceCost / 100))]), _vm._v(" / "), _c("span", {
    staticClass: "text-pause"
  }, [_vm._v(_vm._s(_vm.detailData.activityAdvanceCost / 100))])])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "活动ID",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.activityId))])])]), _c("el-form-item", {
    attrs: {
      label: "列表图片",
      "label-width": _vm.formLabelWidth
    }
  }, [!_vm.detailData.activityListImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.detailData.activityListImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.detailData.activityListImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.detailData.activityListImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.detailData.activityListImage);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "机构名称",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.activityOrgName))])])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "活动方ID",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.activityCompanyId))])])]), _c("el-form-item", {
    attrs: {
      label: "机构LOGO",
      "label-width": _vm.formLabelWidth
    }
  }, [!_vm.detailData.activityOrgLogo ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.detailData.activityOrgLogo ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.detailData.activityOrgLogo ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.detailData.activityOrgLogo
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.detailData.activityOrgLogo);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1)], 1), _c("el-tab-pane", {
    attrs: {
      label: "团队 / 推荐"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "用户/身份",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.userNickname))]), _vm._v(" 【 "), _vm.detailData.groupCaptainState ? _c("span", {
    staticClass: "text-interrupt"
  }, [_vm._v("团长")]) : _vm._e(), !_vm.detailData.groupCaptainState ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("团员")]) : _vm._e(), _vm._v(" 】 ")])]), !_vm.detailData.groupCaptainState ? _c("el-form-item", {
    attrs: {
      label: "团长昵称",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.groupCaptainNickname))])])]) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "团队人数",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.groupPersonAmount))])])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "团队ID",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.groupId))])])]), _c("el-form-item", {
    attrs: {
      label: "团长头像",
      "label-width": _vm.formLabelWidth
    }
  }, [!_vm.detailData.groupCaptainImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.detailData.groupCaptainImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.detailData.groupCaptainImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.detailData.groupCaptainImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.detailData.groupCaptainImage);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _vm.detailData.inviterUserId ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "邀请人昵称",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.inviterUserNickname))])])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "邀请人ID",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.inviterUserId))])])]), _c("el-form-item", {
    attrs: {
      label: "邀请人头像",
      "label-width": _vm.formLabelWidth
    }
  }, [!_vm.detailData.inviterUserImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.detailData.inviterUserImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.detailData.inviterUserImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.detailData.inviterUserImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile(_vm.detailData.inviterUserImage);
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1), !_vm.detailData.virtualOrder ? _c("el-tab-pane", {
    attrs: {
      label: "退款 / 结算"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付状态",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm.detailData.state === "wait_pay" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("待支付")]) : _vm._e(), _vm.detailData.state === "end" ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("已支付")]) : _vm._e(), _vm.detailData.state === "refund" ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("已退款")]) : _vm._e(), _vm.detailData.state === "cancel" ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("已取消")]) : _vm._e()])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "订单编号",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.id))])])])], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付方式",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm.detailData.payCategory === "aliPay" ? _c("span", {
    staticClass: "text-style"
  }, [_vm._v("支付宝")]) : _vm._e(), _vm.detailData.payCategory === "wxPay" ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("微信支付")]) : _vm._e(), _vm.detailData.payCategory === "wxSpPay" ? _c("span", {
    staticClass: "text-interrupt"
  }, [_vm._v("微信服务商支付")]) : _vm._e(), _vm.detailData.payCategory === "balance" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("余额支付")]) : _vm._e(), _vm._v(" 【 "), _vm.detailData.payType === "pos" ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("POS机")]) : _vm._e(), _vm.detailData.payType === "jsapi" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("JS-API")]) : _vm._e(), _vm.detailData.payType === "app" ? _c("span", {
    staticClass: "text-style"
  }, [_vm._v("App")]) : _vm._e(), _vm.detailData.payType === "Native" ? _c("span", {
    staticClass: "text-interrupt"
  }, [_vm._v("Native")]) : _vm._e(), _vm.detailData.payType === "h5" ? _c("span", {
    staticClass: "text-lose"
  }, [_vm._v("H5")]) : _vm._e(), _vm.detailData.payType === "applet" ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("小程序")]) : _vm._e(), _vm.detailData.payType === "face" ? _c("span", {
    staticClass: "text-collapse"
  }, [_vm._v("刷脸")]) : _vm._e(), _vm._v(" 】 ")])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "支付时间",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.payTime ? _vm.dayjs(_vm.detailData.payTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])])])], 1)], 1), _vm.detailData.state === "refund" ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "退款状态",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm.detailData.refundState == "apply" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("已申请")]) : _vm._e(), _vm.detailData.refundState == "success" ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("已退款")]) : _vm._e(), _vm.detailData.refundState == "fail" ? _c("span", {
    staticClass: "text-collapse"
  }, [_vm._v("失败")]) : _vm._e(), _vm.detailData.refundUseState ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v(" 【允许核销】")]) : _vm._e(), !_vm.detailData.refundUseState ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v(" 【不允许核销】")]) : _vm._e()])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "退款时间",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.refundTime ? _vm.dayjs(_vm.detailData.refundTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])])])], 1)], 1) : _vm._e(), _vm.detailData.state === "end" || _vm.detailData.refundUseState ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "核销状态",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm.detailData.useState ? _c("span", {
    staticClass: "text-pause"
  }, [_vm._v("已核销")]) : _vm._e(), !_vm.detailData.useState ? _c("span", {
    staticClass: "text-interrupt"
  }, [_vm._v("未核销")]) : _vm._e()])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "核销时间",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.useTime ? _vm.dayjs(_vm.detailData.useTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])])])], 1)], 1) : _vm._e(), _vm.detailData.state === "end" ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "结算状态",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm.detailData.settlementState ? _c("span", {
    staticClass: "text-suggest"
  }, [_vm._v("已结算")]) : _vm._e(), !_vm.detailData.settlementState ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("待结算")]) : _vm._e()])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "结算时间",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.settlementTime ? _vm.dayjs(_vm.detailData.settlementTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])])])], 1)], 1) : _vm._e(), _vm.detailData.state === "cancel" ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "取消类型",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm.detailData.cancelType === "user" ? _c("span", {
    staticClass: "text-bug"
  }, [_vm._v("用户取消")]) : _vm._e(), _vm.detailData.cancelType === "auto" ? _c("span", {
    staticClass: "text-interrupt"
  }, [_vm._v("系统取消")]) : _vm._e()])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "取消时间",
      "label-width": _vm.formLabelWidth
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.detailData.cancelTime ? _vm.dayjs(_vm.detailData.cancelTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])])])], 1)], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.groupDialogShow,
      title: "更换团队",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.groupDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initGroupData(_vm.emptyGroupData);
      }
    }
  }, [_c("el-form", {
    ref: "groupDataVerify",
    attrs: {
      model: _vm.groupData,
      rules: _vm.groupRules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "活动名称",
      "label-width": _vm.formLabelWidth,
      prop: "activityName"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.groupData.activityName))])])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "活动ID",
      "label-width": _vm.formLabelWidth,
      prop: "activityId"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.groupData.activityId))])])])], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "当前团长",
      "label-width": _vm.formLabelWidth,
      prop: "currentGroupCaptainNickname"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.groupData.currentGroupCaptainNickname))])])])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "当前团ID",
      "label-width": _vm.formLabelWidth,
      prop: "currentGroupId"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.groupData.currentGroupId))])])])], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "归属团队",
      "label-width": _vm.formLabelWidth,
      prop: "groupId"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请选择归属团队(不选为团长)",
      readonly: ""
    },
    model: {
      value: _vm.groupData.newGroupCaptainNickname,
      callback: function callback($$v) {
        _vm.$set(_vm.groupData, "newGroupCaptainNickname", $$v);
      },
      expression: "groupData.newGroupCaptainNickname"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-button", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      icon: "el-icon-search",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.activityOrderChangeSelData.isShow = true;
      }
    }
  }, [_vm._v("选择")])], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "目标团ID",
      "label-width": _vm.formLabelWidth,
      prop: "currentGroupId"
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_c("span", {
    staticClass: "text-style"
  }, [_vm._v(_vm._s(_vm.groupData.newGroupId))])])])], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeGroupDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitGroupData
    }
  }, [_vm._v("移 团")])], 1)], 1), _vm.activitySelData.isShow ? _c("groupbuyActivitySel", {
    attrs: {
      isShow: _vm.activitySelData.isShow
    },
    on: {
      "child-activity-sel": _vm.activitySel
    }
  }) : _vm._e(), _vm.activityOrderSelData.isShow ? _c("groupbuyActivityOrderSel", {
    attrs: {
      isShow: _vm.activityOrderSelData.isShow,
      currentActivity: _vm.virtualData.activityId
    },
    on: {
      "child-activity-order-sel": _vm.activityOrderSel
    }
  }) : _vm._e(), _vm.activityOrderChangeSelData.isShow ? _c("groupbuyActivityMoveOrderSel", {
    attrs: {
      isShow: _vm.activityOrderChangeSelData.isShow,
      currentActivity: _vm.groupData.activityId,
      currentGroup: _vm.groupData.currentGroupId
    },
    on: {
      "child-activity-order-sel": _vm.activityMoveOrderSel
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };