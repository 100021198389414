export default (function (_ref) {
  var request = _ref.request;
  return {
    mngDictionaryAdd: function mngDictionaryAdd(data) {
      return request({
        url: '/mng/dictionary',
        method: 'post',
        data: data
      });
    },
    mngDictionaryModify: function mngDictionaryModify(data) {
      return request({
        url: "/mng/dictionary/".concat(data.id),
        method: 'post',
        data: data
      });
    },
    mngDictionaryDel: function mngDictionaryDel(id) {
      return request({
        url: "/mng/dictionary/".concat(id),
        method: 'delete'
      });
    },
    mngDictionaryQuery: function mngDictionaryQuery(data) {
      return request({
        url: '/mng/dictionary/query',
        method: 'get',
        params: data
      });
    },
    mngDictionaryDetail: function mngDictionaryDetail(id) {
      return request({
        url: "/mng/dictionary/".concat(id),
        method: 'get'
      });
    },
    mngDictionaryDetailByCode: function mngDictionaryDetailByCode(code) {
      return request({
        url: "/mng/dictionary/detailByCode/".concat(code),
        method: 'get'
      });
    },
    mngDictionaryLowerTree: function mngDictionaryLowerTree(code) {
      return request({
        url: "/mng/dictionary/cache/lowerTree/".concat(code),
        method: 'get'
      });
    },
    mngDictionaryClearCache: function mngDictionaryClearCache() {
      return request({
        url: '/mng/dictionary/cache/clear',
        method: 'post'
      });
    }
  };
});