export default (function (_ref) {
  var request = _ref.request;
  return {
    mngUnNoticeCount: function mngUnNoticeCount() {
      return request({
        url: '/mng/remind/unNoticeCount',
        method: 'get'
      });
    },
    mngRemindQuery: function mngRemindQuery(data) {
      return request({
        url: '/mng/remind/query',
        method: 'get',
        params: data
      });
    },
    mngRemindRead: function mngRemindRead(id) {
      return request({
        url: "/mng/remind/".concat(id, "/read"),
        method: 'post'
      });
    },
    mngRemindNotice: function mngRemindNotice(id) {
      return request({
        url: '/mng/remind/notice',
        method: 'post'
      });
    }
  };
});